<div *ngIf="loading == true" style="height: 10vh;"></div>

<div
    *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee;"
>
    <div style="margin: auto;">
        <img
            style="display: block;"
            class="logo-loading"
            src="assets/images/navbar/fLogo.svg"
            alt="Logo loading.."
            loading="lazy"
        />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    >
</div>

<!-- Moments -->
<section id="customers mt-3" *ngIf="loading == false" style="margin-top: 60px;">
    <div class="container pt-3">
        <div class="row">
            <div class="col mt-3">
                <p class="fw-bold text-center clr-blue-dark mb-3 h2">Moments</p>
            </div>
        </div>
        <div class="row">
            <div *ngFor="let moment of momentscaputured" class="col-md-3">
                <div class="text-center pt-4">
                    <img [src]="moment.image" alt="moments" class="img-fluid mb-5" style="width: 28.125rem;" loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</section>
