<section class="px-md-5 my-5 pt-3 px-2">
    <p class="h2 fw-bold">COSGrid Networks Terms and Conditions </p>
    <p class="h6"><span class="fw-bold">IMPORTANT - </span> PRIOR TO CONTINUING REGISTRATION, PLEASE CAREFULLY READ THIS
        AGREEMENT FOR COSGrid Networks Terms and Conditions,"BY CLICKING THE "I ACCEPT" BUTTON OR OTHERWISE INDICATING
        YOUR ACCEPTANCE THROUGH AN ORDERING DOCUMENT THAT INCORPORATES THIS AGREEMENT (REFERRED TO AS "ORDERING
        DOCUMENT"), YOU AGREE TO ADHERE TO AND BE LEGALLY BOUND BY THE TERMS AND CONDITIONS SET FORTH HEREIN. IF YOU ARE
        REGISTERING ON BEHALF OF A COMPANY OR ANY OTHER LEGAL ENTITY, YOU WARRANT THAT YOU HAVE THE AUTHORITY TO LEGALLY
        BIND SUCH ENTITY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT. IN SUCH A SCENARIO, THE TERMS "YOU" AND "YOUR"
        REFER TO SAID ENTITY. IF YOU LACK SUCH AUTHORITY OR DISAGREE WITH ANY OF THE TERMS AND CONDITIONS STATED IN THIS
        AGREEMENT, PLEASE SELECT THE "I DECLINE" BUTTON AND REFRAIN FROM USING THE SERVICES</p>
    <p class="h2 fw-bold">Agreement </p>
    <div *ngFor="let term of termsArray1to3">
        <p class="h4 fw-bold">{{term.title}}</p>
        <p class="fs-6">{{term.text}}</p>
    </div>
    <div *ngFor='let term of termsArray4'>
        <p class="h4 fw-bold">{{term.title}}</p>
        <!-- <br> -->
        <p class="fs-6">{{term.text}}</p>
        <div class="pointsList">
            <ul>
                <li *ngFor="let points of term.points" class="fs-6">{{points}}</li>
            </ul>
        </div>
        <!-- <br> -->
        <p class="fs-6">{{term.points_text}}</p>
        <ul>
            <li *ngFor="let points of term.points" class="fs-6">{{points}}</li>
        </ul>
        <p class="fs-6">{{term.endText}}</p>
        <!-- <br> -->
    </div>
    <p class="h4 fw-bold">E.Warranty: </p>
    <!-- <br> -->
    <p class="fs-6">COSGrid Networks warrants that the services/products will be free from material defects in
        workmanship and materials for a period of 365 days from the date of original purchase. If any defect
        is found during the warranty period, COSGrid Networks will repair or replace the defective hardware
        or software at no charge to the customer.
        COSGrid Networks' obligation under this warranty is limited to repair or replacement of defective
        hardware or software. COSGrid Networks is not liable for any incidental or consequential damages,
        including but not limited to lost profits, data loss, or damage to other equipment.
        This warranty does not cover damage caused by accident, misuse, or abuse. COSGrid Networks also
        disclaims any warranty for products that have been modified or repaired by unauthorized personnel.
    </p>
    <p class="fs-6">To make a warranty claim, please contact COSGrid Networks customer support at <a
            href="mailto:info@cosgrid.com">info@cosgrid.com</a> or <a href="tel:+91 8610144212">+91
            8610144212</a></p>
    <!-- <br> -->
    <p class="fs-6">Here are some additional details about the warranty:</p>
    <ul>
        <li class="fs-6">The warranty is only valid for products that are properly registered with COSGrid Networks</li>
        <li class="fs-6">The warranty does not cover products that are used for commercial or industrial purposes</li>
        <li class="fs-6">The warranty is void if the product is damaged by accident, misuse, or abuse.</li>
        <li class="fs-6">If you have any questions about the warranty, please contact COSGrid Networks customer support.
        </li>
    </ul>
    <p class="h4 fw-bold">F. Trial Use of the Services:</p>
    <p class="fs-6">You have the option to request certain services for trial purposes, intended for non-production use
        only. These trial services are provided "as is," and no warranties are offered for such services.
    </p>
    <p class="h4 fw-bold">G. Indemnification:</p>
    <p class="fs-6">In the event that a third party asserts a claim against you or COSGrid Networks ("Recipient")
        alleging infringement of their intellectual property rights based on any material provided by you or
        COSGrid Networks ("Provider"), the Provider will bear the sole cost and responsibility of defending
        the Recipient against such claims and indemnify the Recipient against any damages, liabilities,
        costs, and expenses awarded by a court or agreed upon in a settlement. To qualify for
        indemnification, the Recipient must fulfill the following conditions : </p>
    <ul>
        <li class="fs-6">Promptly notify the Provider in writing, within 30 days of receiving notice of the claim (or
            sooner if required by law).</li>
        <li class="fs-6">Grant the Provider exclusive control over the defense and any settlement negotiations.
        </li>
        <li class="fs-6">Provide the Provider with all necessary information, authority, and assistance to defend
            against
            or settle the claim.</li>
    </ul>
    <p class="fs-6">If the Provider determines that any material may have infringed upon a third party's intellectual
        property rights, the Provider may choose to take appropriate actions, such as modifying the material
        to be non-infringing, obtaining a license for continued use, or, if commercially reasonable options
        are not available, terminating the license for the material, requiring its return, and refunding any
        unused prepaid fees. Should the return of the material significantly impact COSGrid Networks'
        ability to fulfill its obligations, COSGrid Networks may, at its discretion and with 30 days'
        written notice, terminate the corresponding order. The Provider will not indemnify the Recipient if
        the Recipient modifies the material beyond the defined scope in the Provider's user documentation or
        services policies, uses a superseded version of the material, or utilizes any material not provided
        by the Provider. Additionally, COSGrid Networks will not indemnify you for any infringement claim
        arising from the combination of the material with non-COSGrid Networks products or services. The
        indemnification does not cover claims based on patents made known to you before the effective date
        of this agreement or actions taken by you prior to the effective date. This section serves as the
        exclusive remedy for any infringement claims or damages.</p>

    <p class="h4 fw-bold">H. End of Agreement:</p>
    <p class="fs-6">The services provided under this agreement will be available for the duration specified in the
        ordering document, unless terminated earlier in accordance with the terms of the agreement. The
        period of the services and any subsequent renewal years are collectively referred to as the
        "services term." Upon the conclusion of the services term, all rights to use the services, including
        any COSGrid Networks products listed in the ordering document, will terminate. If either party
        breaches a significant provision of the agreement and fails to remedy the breach within 30 days of
        receiving written notice, the non-breaching party may terminate the applicable ordering document in
        which the breach occurred.
    </p>
    <!-- <br> -->
    <p class="fs-6">If COSGrid Networks ended an ordering document as stated in the previous paragraph, you must make
        payment within 30 days for all accrued amounts up to the termination date, as well as any remaining
        unpaid fees for the services ordered under the agreement, along with applicable taxes and expenses.
        If COSGrid Networks terminates the services under the Indemnification section, you must pay within
        30 days for all outstanding amounts due for the services, including related taxes and expenses. The
        non-breaching party may, at its sole discretion, extend the 30-day cure period if the breaching
        party is making reasonable efforts to rectify the breach. You acknowledge that in the event of a
        default on your part, you will not be allowed to use the services that were ordered. Furthermore, if
        you are in default under an InstaSafe Financing Division contract used to pay for the fees under an
        order, you may not use the services covered by that contract.
    </p>
    <!-- <br> -->
    <p class="fs-6">Additionally, if you fail to make the required payments to InstaSafe in accordance with the
        agreement
        and do not remedy the situation within ten days of the 30-day cure period, or if you violate any
        provision of this agreement, InstaSafe may suspend your password, account, and access to the
        services immediately. InstaSafe may also terminate the services if the aforementioned issues are not
        resolved within 30 days after the initial notice. However, any suspension of services by InstaSafe
        under this circumstance does not release you from the obligation to make the required payments under
        the agreement.</p>
    <!-- <br> -->
    <p class="fs-6">Upon your request and for a period of up to 60 days after the termination of the applicable ordering
        document, InstaSafe may allow you limited access to the services solely for the purpose of
        retrieving your data stored in the services environment</p>
    <!-- <br> -->
    <p class="fs-6">You acknowledge and agree that InstaSafe is not obligated to retain your data, and after 60 days
        following the termination of the ordering document, your data may be permanently deleted and
        irretrievable.</p>
    <!-- <br> -->
    <p class="fs-6">Certain provisions of the agreement will survive termination or expiration, including those related
        to limitation of liability, infringement indemnity, payment, and others that are intended to endure
        beyond the termination of the agreement due to their nature.</p>

    <p class="h4 fw-bold">I. Pricing</p>

    <p class="fs-6">
        COSGrid Networks offers various Subscription Plans for the provision of their Services on a monthly
        or yearly basis (the "Subscription Plan").
    </p>

    <p class="fs-6">The Price for each Subscription Plan is determined based on the duration, number of users and
        features included in the plan. Please note that the Subscription Plans and their respective Prices
        are subject to change by COSGrid Networks without prior notice or liability. Additional or future
        services may not be included in the Price at the discretion of COSGrid Networks.
    </p>

    <p class="fs-6">The Subscription Plan Term begins upon successful payment and ends at the conclusion of the Billing
        Cycle associated with the Subscription Plan. In the event of a payment reversal, the Subscription
        Plan Term will be immediately terminated, and any credits, discounts, or commitments provided by
        COSGrid Networks will be voided.
    </p>

    <p class="fs-6">Your Subscription Plan will automatically renew for subsequent terms based on the applicable Billing
        Cycle (monthly or annual) unless you initiate the Subscription Cancellation Process within your
        Tenant prior to the renewal date. For further clarity, Refer to our <a
            routerLink="/cancellation-refund-policy/.">Cancellation and Refund Policies.</a></p>

    <p class="fs-6">COSGrid Networks reserves the right to adjust fees or discontinue offered Subscription Plans, which
        will take effect in the subsequent billing cycle. In such cases, COSGrid Networks will provide you
        with at least 30 days' written notice regarding any price increases and offer you the option to
        cancel the Subscription Plan before the next billing cycle.
    </p>

    <p class="fs-6">Billing Cycles start at the beginning of the month and end on the last day of the last month of your
        Subscription Plan. If you purchase a Subscription Plan in the middle of the month, you will be
        charged a prorated amount for the first cycle on the day of purchase.
    </p>

    <p class="fs-6">If you choose to purchase additional features or add more users to an existing Subscription Plan
        ("Additional Order") during the term of the plan, the initial term of the Additional Order will be
        adjusted to align with the existing Subscription Plan. The fees for the Additional Order will be
        prorated based on the remaining term of the existing Subscription Plan.
    </p>
    <p class="h4 fw-bold">J. Taxes:</p>
    <p class="fs-6">You are responsible for determining any applicable taxes on the payments you make and for
        collecting,
        reporting, and remitting the correct tax amount to the appropriate tax authority. COSGrid Networks
        is not responsible for assessing the tax applicability or collecting, reporting, or remitting any
        taxes arising from your transactions. Sales tax, Value Added Tax, or other applicable taxes may be
        added to your Subscription Plan payments as determined by COSGrid Networks.</p>
    <p class="fs-6">If you are required to deduct or withhold taxes from your Subscription Plan payments, the withheld
        amount will be solely your responsibility. The payment made by you to COSGrid Networks for your
        Subscription Plan will be adjusted to account for the grossed-up amount, ensuring that COSGrid
        Networks receives the intended payment free of any tax deduction or withholding.</p>
    <p class="h4 fw-bold">K. Confidentiality:
    </p>
    <p class="fs-6">As part of the agreement, both parties may have access to confidential information belonging to each
        other ("confidential information"). We agree to disclose only the information that is necessary for
        fulfilling our obligations under the agreement. Confidential information includes, but is not
        limited to, the terms and pricing specified in the agreement, your data stored in the services
        environment, and any information explicitly identified as confidential at the time of disclosure.
    </p>
    <p class="fs-6">Confidential information does not include information that: (a) becomes publicly available without
        any action or omission by the receiving party; (b) was already lawfully in the possession of the
        receiving party prior to the disclosure and was not obtained directly or indirectly from the
        disclosing party; (c) is lawfully disclosed to the receiving party by a third party without any
        restrictions; or (d) is independently developed by the receiving party.
    </p>
    <p class="fs-6">Both parties agree to treat each other's confidential information as confidential and maintain its
        confidentiality for a period of three years from the date of disclosure. We also agree to disclose
        confidential information only to employees or agents who need to know it and who are bound by
        confidentiality obligations that are at least as protective as those in the agreement. InstaSafe
        will ensure the confidentiality of your data stored in the services environment by following the
        security practices outlined in the services policies mentioned in the ordering document. Nothing in
        this agreement prevents either party from disclosing the terms or pricing specified in the agreement
        in any legal proceedings related to the agreement or from disclosing confidential information to a
        government entity as required by law.
    </p>
    <p class="h4 fw-bold">L. Entire Agreement:</p>
    <p class="fs-6">Both parties agree that the agreement, along with any referenced information, constitutes the
        complete agreement regarding the ordered services. It supersedes any prior agreements or
        representations, whether written or oral. If any term of the agreement is found to be invalid or
        unenforceable, the remaining provisions will remain in effect, and the invalid term will be replaced
        with a term that aligns with the agreement's purpose. The terms of the agreement take precedence
        over any terms in purchase orders or non-COSGrid Networks documents. Any modifications or waivers
        require written agreement from authorized representatives of both parties.
    </p>
    <p class="h4 fw-bold">M. Limitation of Liability:</p>
    <p class="fs-6">Neither party is liable to the other for indirect, incidental, special, punitive, or consequential
        damages, or any loss of revenue, profits, data, or data use, excluding fees under the agreement.
        COSGrid Networks' maximum liability for damages arising from the agreement or the order, whether in
        contract or tort, is limited to the total amounts actually paid to COSGrid Networks for the services
        under the relevant order in the twelve (12) month period preceding the claim. Any damages awarded to
        you will be reduced by any refunds or credits received under the agreement. </p>


    <p class="h4 fw-bold">N. Other:</p>
    <ul>
        <li class="fs-6">COSGrid Networks is an independent contractor, and no partnership, joint venture, or agency
            relationship exists between the parties. Each party is responsible for paying its own employees,
            including taxes and insurance.</li>
        <li class="fs-6">You are responsible for obtaining any necessary rights and consents from third parties to
            perform the services.</li>
        <li class="fs-6">The agreement is governed by the laws of India, and both parties submit to the exclusive
            jurisdiction of the courts in Bangalore, India, for any disputes.</li>
        <li class="fs-6">You cannot assign the agreement or transfer the services to another party.
        </li>
        <li class="fs-6">Except for actions related to nonpayment or breach of COSGrid Networks' proprietary rights, no
            action arising from the agreement may be brought more than two years after the cause of action
            accrues.</li>
        <li class="fs-6">COSGrid Networks may audit your use of the services, and you agree to cooperate and provide
            reasonable assistance. You are responsible for paying any applicable fees resulting from
            excessive use. COSGrid Networks is not liable for any costs you incur during the audit.</li>
        <li class="fs-6">COSGrid Networks' business partners are independent, and COSGrid Networks is not responsible
            for
            their acts unless they act as subcontractors under this agreement.</li>
    </ul>
    <p class="h4 fw-bold">O. Force Majeure:</p>
    <p class="fs-6">Neither party is responsible for failure or delay in performance caused by events beyond their
        reasonable control. Both parties will make reasonable efforts to mitigate the effect of such events.
        If a force majeure event continues for more than 30 days, either party may cancel unperformed
        services by providing written notice. This section does not excuse either party's obligation to
        follow their normal disaster recovery procedures or your obligation to pay for the services.
    </p>

    <p class="h4 fw-bold">P. Your Data:</p>
    <p class="fs-6">You are responsible for providing notices and obtaining consents related to the collection, use,
        processing, transfer, and disclosure of personal information in connection with the services. You
        are solely responsible for the accuracy, quality, integrity, legality, reliability, appropriateness,
        and ownership of all your data.</p>
    <p class="h4 fw-bold">Q. Restrictions on Use of the Services:</p>
    <p class="fs-6">You agree not to use the services, including uploading, emailing, posting, or transmitting any
        material, for purposes that may (a) harm or harass any person or property, (b) publish false,
        defamatory, harassing, or obscene material, (c) violate privacy rights or promote bigotry, racism,
        hatred, or harm, (d) involve unsolicited bulk email, spam, or chain letters, (e) infringe upon
        intellectual property or proprietary rights, or (f) violate applicable laws, ordinances, or
        regulations. COSGrid Networks reserves the right to remove or disable any material violating these
        restrictions and is not liable for such actions. You agree to defend and indemnify COSGrid Networks
        against any claims arising from a violation of your obligations under this section.</p>
    <p class="h4 fw-bold">R. Services Tools:</p>
    <p class="fs-6">COSGrid Networks may use tools to monitor and administer the services and assist in resolving
        service
        requests. These tools will not collect, report, or store your data residing in the service
        production environment, except as necessary for troubleshooting or other service-related purposes.
        The data collected by the tools (excluding production data) may be used for managing COSGrid
        Networks' product and service portfolio and license management. Your use of the tools is governed by
        the license terms specified by COSGrid Networks, and technical support or warranties may not be
        provided. Your right to use the tools will terminate upon notice, the end of the services term, or
        the expiration of the license terms.</p>
    <p class="h4 fw-bold">S. Statistical Information:</p>
    <p class="fs-6">COSGrid Networks may compile statistical information related to the performance of the services and
        may make such information publicly available, provided it does not incorporate your data, identify
        your confidential information, or include your company's name. COSGrid Networks retains all
        intellectual property rights in such information.
    </p>
    <p class="h4 fw-bold">T. Privacy:
    </p>
    <p class="fs-6">COSGrid Networks respects your privacy and is committed to protecting your personally-identifying
        information in accordance with our Privacy Policy. We do not share this information with third
        parties unless permitted by our Privacy Policy. For more details on how we handle your personal
        information, please refer to our <a routerLink="/privacy-policy/.">Privacy Policy</a>.
    </p>
    <p class="fs-6">In order to maintain and improve our services and technology, we may process aggregated and
        anonymized data, which does not identify individuals.
    </p>
    <p class="fs-6">COSGrid Networks takes reasonable measures to safeguard the information you provide in connection
        with our services. However, you acknowledge and agree that the submission of such information is
        done at your own risk, and COSGrid Networks disclaims any liability for any disclosure, loss, or
        liability related to such information.</p>
    <p class="h4 fw-bold">U. Customer Reference:</p>
    <p class="fs-6">COSGrid Networks may identify you as a recipient of services and use your logo in sales
        presentations, marketing materials, and press releases. COSGrid Networks may also develop a brief
        customer profile for promotional purposes on its website.</p>
</section>