import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import META_QUERY from './meta.service';

@Injectable({
    providedIn: 'root' // Register the service at the root level to make it available throughout the application
})

export class FetchMetaService implements OnDestroy {

    metatags: any = {

    }
    flag: boolean = false;
    private queryBlog: Subscription;

    constructor(private apollo: Apollo) {
        window.addEventListener('beforeunload', this.clearMetaTags.bind(this))
    }


    fetchData() {
        // if(this.flag == false)
        // {
        //     this.queryBlog = this.apollo
        //     .watchQuery({
        //         query: META_QUERY,
        //     })
        //     .valueChanges.subscribe((result) => {
        //         let meta = [];
        //         meta = (result.data as any).metaTags.data.map((metaTag) => (
        //             {
        //                 ...metaTag,
        //             }
        //         ));
        //         for(var i=0; i<meta.length; i++)
        //         {
        //             this.metatags[meta[i].attributes.URL_ID] = meta[i];
        //         }
        //         this.flag = true;
        //         return this.metatags;
        //         // console.log("Meta Tags : ", this.metatags);
        //     });
        // }
        // else
        // {
        //     return this.metatags;
        // }

    }

    public getData(): any {
        if (this.flag == false) {
            // console.log("Data is fetching First Time");
            this.flag = true;
            return false;
        }
        else {
            // console.log("Data Already Fetched");
            return this.metatags;
        }
    }
    public storeData(data: any) {
        this.metatags = data;
        return this.metatags;
    }

    private clearMetaTags() {
        this.flag = false
        this.metatags = {}
    }

    ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.clearMetaTags.bind(this))
    }
}
