// import { RouteConstants } from './config/route.constants';
// import { SitemapComponent } from './pages/sitemap/sitemap.component';
// import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
// import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
// import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// import { ProductsComponentsComponent } from './pages/productsComponents/productsComponents.component';
// import { ContactsComponent } from './pages/contacts/contacts.component';
// import { CosgridblogsComponent } from './pages/cosgridblogs/cosgridblogs.component';
// import { AboutusComponent } from './pages/aboutus/aboutus.component';
// import { HomeComponent } from './pages/home/home.component';
// import { PartnersComponent } from './pages/partners/partners.component';
// import { FaqsComponent } from './pages/faqs/faqs.component';
// import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
// import { UsecasesComponent } from './pages/solutions/usecases/usecases.component';
// import { BlogsComponent } from './pages/news/blogs/blogs.component';
// import { AwardsComponent } from './pages/news/awards/awards.component';
// import { EventsComponent } from './pages/news/events/events.component';
// import { ContactpageComponent } from './pages/contactpage/contactpage.component';
// import { CancelationpageComponent } from './pages/cancelationpage/cancelationpage.component';
// import { SaseComponent } from './pages/sase/sase.component';
// // import { ProductZTNAComponent } from './pages/products/productztna/productztna.component';
// import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
// import { PressReleaseComponent } from './pages/press-release/press-release.component';
// import { ProductsComponent } from './pages/products/products.component';
// const routes: Routes = [
//     // BASIC URLS
//     { path: '', redirectTo: `${RouteConstants.HOME}/.`, pathMatch: 'full' },
//     { path: `${RouteConstants.HOME}/.`, component: HomeComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.PRODUCTS}/:id/.`, component: ProductsComponent},
//     {
//         path: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
//         component: SaseComponent,
//         pathMatch: 'full',
//     },
//     {
//         path: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}/.`,
//         component: SaseComponent,
//         pathMatch: 'full',
//     },

//     { path: `${RouteConstants.USECASES}`, component: UsecasesComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.USECASES}/.`, component: UsecasesComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.ABOUTUS}/.`, component: AboutusComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.CONTACTS}/.`, component: ContactsComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.PRESS_RELEASE}/.`, component: PressReleaseComponent, pathMatch: 'full'},
//     { path: `${RouteConstants.Support}/.`, component: ContactpageComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.CANCELATION}/.`, component: CancelationpageComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.PARTNERS}`, component: PartnersComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.PRIVACY_POLICY}/.`, component: PrivacyPolicyComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.TERMS_OF_USE}/.`, component: TermsOfUseComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.SITEMAP}/.`, component: SitemapComponent, pathMatch: 'full' },
//     { path: `${RouteConstants.FAQS}/.`, component: FaqsComponent, pathMatch: 'full' },
//     {
//         path: `${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
//         loadChildren: () => import('./core/modules/sdwan.module').then((m) => m.SdwanModule),
//     },
//     //MZA-DOCUMNETATION
//     {
//         path: `${RouteConstants.MZA_DOC}`,
//         loadChildren: () => import('./core/modules/mzadoc.module').then((m) => m.MzadocModule),
//     },
//     // NEWS
//     // {
//     //     path: `${RouteConstants.NEWS}`,
//     //     loadChildren: () => import('./core/modules/news.module').then((m) => m.NewsModule),
//     // },
//     {
//         path: `${RouteConstants.BLOGS}/.`,
//         component: BlogsComponent,
//     },
//     {
//         path: `${RouteConstants.AWARDS}/.`,
//         component: AwardsComponent,
//     },
//     {
//         path: `${RouteConstants.EVENTS}/.`,
//         component: EventsComponent,
//     },
//     //BLOGS
//     {
//         path: `${RouteConstants.BLOGS}/:id/.`,
//         component: CosgridblogsComponent,
//     },
//     {
//         path: `${RouteConstants.BLOGS}/.`,
//         loadChildren: () => import('./core/modules/blogs.module').then((m) => m.BlogModule),
//     },
//     // SOLUTIONS
//     {
//         path: `${RouteConstants.INDUSTRIES}`,
//         loadChildren: () => import('./core/modules/solutions.module').then((m) => m.SolutionsModule),
//     },
//     // PRODUCTS/SDWAN
//     // {
//     //     path: `${RouteConstants.PRODUCTS}/sdwan`,
//     //     loadChildren: () => import('./core/modules/products.module').then((m) => m.ProductsModule),
//     // },
//     //PRODUCTS/VPN
//     // {
//     //     path: `${RouteConstants.PRODUCTS}/vpn`,
//     //     loadChildren: () => import('./core/modules/productvpn.module').then((m) => m.ProductVPNModule),
//     // },
//     //PRODUCTS/RPN
//     // {
//     //     path: `${RouteConstants.PRODUCTS}/ndr`,
//     //     loadChildren: () => import('./core/modules/productndr.module').then((m) => m.ProductNDRModule),
//     // },
//     //PRODUCTS/FIREWALL
//     {
//         path: `${RouteConstants.PRODUCTS}/firewall`,
//         loadChildren: () => import('./core/modules/firewall.module').then((m) => m.FirewallModule),
//     },
//     //RESOURCES/ZTNA
//     {
//         path: `${RouteConstants.RESOURCES}/${RouteConstants.ZTNA}`,
//         loadChildren: () => import('./core/modules/ztna.module').then((m) => m.ZtnaModule)
//     },

//     // COMPONENTS
//     {
//         path: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}`,
//         loadChildren: () => import('./core/modules/product-components.module').then((m) => m.ProductComponentsModule),
//     },
//     {
//         path: `${RouteConstants.RESOURCES}/whitepaper-datasheets`,
//         loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
//     },
//     {
//         path: `${RouteConstants.RESOURCES}/case-studies`,
//         loadChildren: () => import('./pages/case-studies/case-studies.module').then((m) => m.CaseStudiesModule),
//     },
//     { path: `${RouteConstants.PRODUCTS}/.`, component: ProductsComponentsComponent },
//     // {
//     //     path: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
//     //     loadChildren: () => import('./core/modules/product-sase.module').then((m) => m.ProductSaseModule),
//     // },

//     // CAREERS
//     {
//         path: `${RouteConstants.CAREERS}`,
//         loadChildren: () => import('./core/modules/careers.module').then((m) => m.CareersModule),
//     },
//     {
//         path: `${RouteConstants.RESOURCES}`,
//         loadChildren: () => import('./pages/gallery/gallery.module').then((m) => m.GalleryModule),
//     },
//     {
//         path: `${RouteConstants.TESTIMONIAL}/.`,
//         component: TestimonialsComponent,
//     },

//     //{ path: '', redirectTo: RouteConstants.HOME, pathMatch: 'full' },

//     // ERRORS
//     { path: `${RouteConstants.ERROR}/.`, component: PageNotFoundComponent },
//     // { path: 'posts', loadChildren: () => import('./posts/posts.module').then(m => m.PostsModule) },

//     { path: '**', component: PageNotFoundComponent },
// ];

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RouteConstants } from "./config/route.constants";
import { HomeComponent } from "./pages/home/home.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { IndustriesComponent } from "./pages/industries/industries.component";
import { SitemapComponent } from "./pages/others/sitemap/sitemap.component";
import { PrivacyPolicyComponent } from "./pages/others/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./pages/others/terms-of-use/terms-of-use.component";
import { ContactpageComponent } from "./pages/others/contactpage/contactpage.component";
import { ProductVPNComponent } from "./pages/others/productvpn/productvpn.component";
import { DebugRouteGuard } from "./debug-route.guard";
// import { UsecasesComponent } from "./pages/others/usecases/usecases.component";

const routes: Routes = [
    { path: '', redirectTo: RouteConstants.HOME, pathMatch: 'full' },
    { path: RouteConstants.HOME, component: HomeComponent, canActivate: [DebugRouteGuard] },
    { path: RouteConstants.SITEMAP, component: SitemapComponent, canActivate: [DebugRouteGuard] },
    { path: RouteConstants.PRIVACY_POLICY, component: PrivacyPolicyComponent, canActivate: [DebugRouteGuard] },
    { path: RouteConstants.TERMS_OF_USE, component: TermsOfUseComponent, canActivate: [DebugRouteGuard] },
    // { path: RouteConstants.Support, component: ContactpageComponent},
    // { path: RouteConstants.USECASE, component: UsecasesComponent},
    // { path: `${RouteConstants.PRODUCTS}/${RouteConstants.VPN}`, component: ProductVPNComponent},
    { path: RouteConstants.PRODUCTS, loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
    { path: RouteConstants.PARTNERS, loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule) },
    { path: RouteConstants.COMPANY, loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
    { path: RouteConstants.SUPPORT, loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule) },
    { path: `${RouteConstants.INDUSTRIES}`, redirectTo: `${RouteConstants.INDUSTRIES}/financial_services`, pathMatch: 'full' },
    { path: `${RouteConstants.INDUSTRIES}/:id`, component: IndustriesComponent, canActivate: [DebugRouteGuard] },
    { path: RouteConstants.RESOURCES, loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
    { path: '**', component: PageNotFoundComponent, canActivate: [DebugRouteGuard] },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            anchorScrolling: 'enabled',
            initialNavigation: 'enabled',
            relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }


// { useHash: false, initialNavigation: 'enabled', relativeLinkResolution: 'legacy',
