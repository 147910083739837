<div class="row m-lg-5">
    <div class="p-3" [ngClass]="{'col-12 col-md-6 col-lg-4': colsForRows == 3}" *ngFor="let item of data; index as i">
        <div class="shadow d-flex flex-column h-100 p-4 pb-5 hover-popup m-4 position-relative"
            style="border-top-right-radius: 20px;">
            <div class="d-flex align-items-center">
                <img src="assets/images/PDF-ICON.png" [alt]="title" width="15%" loading="lazy">
                <!-- <img *ngIf="title == 'case-studies'" src="https://cdn.cosgrid.com/website/assets3/Resources/report-icon.png" [alt]="title" class="w-20"> -->
                <p class="fw-bold h5 ms-2">{{item.attributes.Title}}</p>
            </div>
            <p *ngIf="item.attributes.Description != ''" class="clr-grey mt-3 px-3 fs-6">{{item.attributes.Description}}</p>
            <a target="_blank" download [href]="item.pdf" class="fw-bold clr-blue-dark position-absolute text-decoration-none">Download ></a>
        </div>
    </div>
</div>