import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { MyEmitterService } from 'src/app/app-emitter.service';
import BLOGS_QUERY from 'src/app/queries/blogs/blogs.service';
import { environment } from 'src/environments/environment';
import { BlogItem } from 'src/app/core/models/route.model';
import { KlentyService } from 'src/app/klenty.service';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../../queries/Meta/meta.service';
import BLOG_QUERY2 from 'src/app/queries/blogs/redirectBlogs.service';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-blogs',
    templateUrl: './blogs.component.html',
    styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    backgroundBanner: string = "assets/images/blogs/blog-banner.jpg"
    blogs: any[] = [];
    redirectBlogs: any[] = []
    // blogs: BlogItem[] = [
    //     {
    //         link:'https://cdn.cosgrid.com/website/blogs/Zero-Trust-Security.html',
    //         name:'Zero Trust Network Access',
    //         image: 'https://cdn.cosgrid.com/website/assets/blog_images/zerotrust.png',
    //         title:'Never Trust, Always Verify - What Matters In Zero Trust',
    //         dateofpublish:'January 23, 2023',
    //         description:'We have come up an integrated view into Zero Trust Architecture approach & deployement by combining leading ZT frameworks. In addition, have outlined ZT Archiecture & Deployment model selection guide for your ZT journey.'
    //     },
    //     {
    //         link:'https://cdn.cosgrid.com/website/blogs/Load-Balance-vs-Multipath-WAN-Aggregation.html',
    //         name:'Load Balance vs Multipath WAN Aggregation',
    //         image:'https://cdn.cosgrid.com/website/assets/blog_images/Load_Balancing_and_Multipath_WAN_Aggregation.gif',
    //         title: 'Load balance vs Multi path aggregation - SD-WAN',
    //         dateofpublish:'Dec 7, 2022',
    //         description:'A Quick read about the key features of SD-WAN in a short and crisp format'


    //     },
    //     {
    //         link:'https://cdn.cosgrid.com/website/blogs/Work-From-Home.html',
    //         name:'Work From Home',
    //         image:'https://cdn.cosgrid.com/website/assets/blog_images/wfh.jfif',
    //         title:'Work from Home: Convenience or Culture Dilution to fixing employee experience',
    //         dateofpublish:'April 4, 2021',
    //         description:'It all started with remote access as a convenience for working outside the office, a few decades back'
    //     },
    //     {
    //         link:'https://cdn.cosgrid.com/website/blogs/Outages-And-Downtime.html',
    //         name:'Outage And Downtime',
    //         image:'https://cdn.cosgrid.com/website/assets/blog_images/outages_and_downtime.png',
    //         title:'Outages & downtime happens! but with quick service recovery',
    //         dateofpublish:'March 23, 2022',
    //         description:'When a massive 7 hours downtime happened for Facebook(FB) considering its scale,  learning from the episode would help.'
    //     },
    //     {
    //         link:'https://cdn.cosgrid.com/website/blogs/Static-IP-Rush.html',
    //         name:'Static IP Rush',
    //         image:'https://cdn.cosgrid.com/website/assets/blog_images/static_ip_rush.gif',
    //         title:'Static IP rush in Indian Retail: Convenience vs Resiliency & Security',
    //         dateofpublish:'March 31, 2022',
    //         description:'How the static IP effects the retail industry business efficiency ? What are the key challenges in using static plain IP? What role in which SD- WAN going to play in these challenges?'
    //     },
    // ]
    loading = false;
    errors: any;

    constructor(private apollo: Apollo, private fetchMetaService: FetchMetaService, private router: Router, private emitter: MyEmitterService, private klentyService: KlentyService, private titleService: Title, private metaService: Meta) { }

    ngOnInit(): void {
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag) => (
                            {
                                ...metaTag,
                            }
                        ));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData['blogs'].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                metaTagsData['blogs'].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['blogs'].attributes.keywords
                        });
                    });
            }
            else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['blogs'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content:
                        data['blogs'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['blogs'].attributes.keywords
                });

            }
        }
        catch {
            // this.titleService.setTitle('Insightful Blogs | COSGrid Networks');
            // this.metaService.updateTag({ name: 'description', content: 'Explore our collection of insightful and informative blogs covering various topics in the cybersecurity industry. ' });
            // this.metaService.updateTag({ name: 'keywords', content: 'blogs, cybersecurity, industry insights, trends, best practices, expert opinions' });
        }
        // this.klentyService.load();
        this.loading = true;
        this.apollo
            .watchQuery({
                query: BLOG_QUERY2,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.redirectBlogs = (result.data as any).blog2S.data.map((blog) => ({
                    ...blog,
                    // image: environment.apiUrl + blog.title_image.url,
                    image: blog?.attributes.Title_image?.data[0]?.attributes?.url,
                }));
            })

        this.apollo
            .watchQuery({
                query: BLOGS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.blogs = (result.data as any).blogs.data.map((blog) => ({
                    ...blog,
                    // image: environment.apiUrl + blog.title_image.url,
                    image: blog?.attributes.Title_image?.data?.attributes?.url,
                }));
                // for(let blog of this.blogs) {
                //     console.log(blog.id);
                //     console.log(blog.title);
                //     console.log(blog.content);
                //     // content also gets displayed
                // }
                this.loading = false;
                this.errors = result.errors;
                // console.log(this.blogs);
                // console.log(this.redirectBlogs);

            });

    }
    routeToblog(blog) {
        this.router.navigateByUrl(`/resources/blogs/${blog.attributes.idUrl}`, {
            state: {
                blog,
            },
        });
    }
    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
