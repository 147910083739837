<div *ngIf="(screenwidth<=1100 && !descOnly) || singleCol">
    <ngu-carousel #myCarousel [inputs]="inputs" [dataSource]="data">
        <ngu-tile *nguCarouselDef="let item; let i = index" class="rounded">
            <div
                style="height: 220px"
                class="d-flex justify-content-center align-items-center carouselBox"
                [ngClass]="{
                'flex-row':screenwidth>500,
                'flex-column':screenwidth<=500
            }"
            style="background-color: #fff;"

            >
                <div
                    class="pt-3 pb-5 d-flex px-3 rounded"
                    [ngClass]="{
                        'justify-content-end':i==0,
                        'justify-content-start':i==1,
                        'flex-row':screenwidth>500,
                        'flex-column':screenwidth<=500
                    }"
                    [ngStyle]="{'height: 220px': screenwidth>768}"
                >
                    <div class="d-flex align-items-center">
                        <img
                            [src]="baseUrl + item.imgURL + '.' + (imageType != '' ? imageType : 'png')"
                            [attr.width]="90"
                            class="img-fluid"
                            loading="lazy"
                            alt="features of ZTNA"
                        />
                    </div>
                    <div class="mr-4 mt-2 ml-3">
                        <p class="text-center fw-bold w-100 fs-5" [ngClass]="{'clr-white' : whiteColor}" [innerHTML]="item.title"></p>
                            <div class="d-flex m-0" *ngFor="let point of item.points">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246 502" width="18px" height="18px" class="mt-1"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#004d65" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
                                <p class="fs-6">{{ point }}</p>
                            </div>
                    </div>
                </div>
            </div>
        </ngu-tile>

        <button
            NguCarouselPrev
            class="nav-btn left-nav"
            [style.opacity]="myCarousel.isFirst ? 0.2 : 0.7"
            style="z-index: 1"
        >
            <i class="fa fa-angle-left" [ngClass]="{'clr-white' : whiteColor}"></i>
        </button>

        <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast ? 0.2 : 0.7"  >
            <i class="fa fa-angle-right" [ngClass]="{'clr-white' : whiteColor}" style="user-select: auto"></i>
        </button>

        <ul class="myPoint" NguCarouselPoint>
            <li
                *ngFor="let number of myCarousel.pointNumbers; let i = index"
                [class.active]="number == myCarousel.activePoint"
                (click)="myCarousel.moveTo(number)"
            ></li>
        </ul>
    </ngu-carousel>
</div>
<div *ngIf="screenwidth>1100 && !descOnly && !singleCol">
    <ngu-carousel #myCarousel [inputs]="inputs" [dataSource]="datahalflength">
        <ngu-tile *nguCarouselDef="let item; let i = index">
            <div
                class="d-flex flex-row justify-content-center align-items-center pt-2 carouselBox"
                style="height: 220px"
            >
                <div
                    class="pt-3 pb-5 d-flex flex-row px-3"
                    *ngFor="let item of [data[item],data[item+1]];index as i"
                    [ngClass]="{
                        'justify-content-end':i==0,
                        'justify-content-start':i==1
                    }"
                >
                    <div class="d-flex align-items-center">
                        <img
                            [src]="baseUrl + item.imgURL + '.png'"
                            [attr.width]="100"
                            class="img-fluid"
                            loading="lazy"
                            alt="features of ZTNA"
                        />
                    </div>
                    <div class="mr-4 mt-2 ml-3">
                        <p class="text-center font-semibold fw-bold fs-5" [innerHTML]="item.title"></p>
                            <div class="d-flex m-0" *ngFor="let point of item.points">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246 502" width="18px" height="18px" class="mt-1"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#004d65" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>
                                <p class="fs-6">{{ point }}</p>
                            </div>
                    </div>
                </div>
            </div>
        </ngu-tile>

        <button
            NguCarouselPrev
            class="nav-btn left-nav"
            [style.opacity]="myCarousel.isFirst ? 0.2 : 0.7"
            style="z-index: 1"
        >
            <i class="fa fa-angle-left"></i>
        </button>

        <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast ? 0.2 : 0.7">
            <i class="fa fa-angle-right" style="user-select: auto"></i>
        </button>

        <ul class="myPoint" NguCarouselPoint>
            <li
                *ngFor="let number of myCarousel.pointNumbers; let i = index"
                [class.active]="number == myCarousel.activePoint"
                (click)="myCarousel.moveTo(number)"
            ></li>
        </ul>
    </ngu-carousel>
</div>
<div *ngIf="descOnly">
    <ngu-carousel #myCarousel [inputs]="inputs" [dataSource]="data">
        <ngu-tile *nguCarouselDef="let item; let i = index" class="rounded">
            <div
                style="height: 220px"
                class="d-flex justify-content-center align-items-center carouselBox rounded"
                [ngClass]="{
                'flex-row':screenwidth>500,
                'flex-column':screenwidth<=500
            }"
            style="background-color: #fff;"

            >
                <div
                    class="pt-3 pb-5 px-4 rounded content-box d-flex align-items-center"
                >
                    <div class="d-flex align-items-center">
                        <img
                            [src]="baseUrl + item.imgURL + '.png'"
                            [attr.width]="imageWidth"
                            class="img-fluid"
                            loading="lazy"
                            alt="features of ZTNA"
                        />
                    </div>
                    <div class="mt-4 px-lg-4">
                        <p [innerHTML]="item.desc" [ngClass]="{'clr-white': whiteColor}" class="fs-6"></p>
                    </div>
                </div>
            </div>
        </ngu-tile>

        <button
            NguCarouselPrev
            class="nav-btn left-nav"
            [style.opacity]="myCarousel.isFirst ? 0.2 : 0.7"
            style="z-index: 1"
        >
            <i class="fa fa-angle-left" [ngClass]="{'clr-white' : whiteColor}"></i>
        </button>

        <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast ? 0.2 : 0.7"  >
            <i class="fa fa-angle-right"  style="user-select: auto"></i>
        </button>

        <ul class="myPoint" NguCarouselPoint>
            <li
                [ngStyle]="{'border: 2px solid rgba(255, 255, 255, 0.5) !important': whiteColor}"
                *ngFor="let number of myCarousel.pointNumbers; let i = index"
                [class.active]="number == myCarousel.activePoint"
                (click)="myCarousel.moveTo(number)"
            ></li>
        </ul>
    </ngu-carousel>
</div>
