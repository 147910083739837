<div
    class="nav-top z-4 position-fixed w-100 d-flex justify-content-center align-items-center navbar-expand-lg fixed-top navbar-transparent nav-scrolled">
    <div class="w-70 position-relative">
        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselSliderList">
            <ngu-tile *nguCarouselDef="let item; let i = index" style="box-shadow: none !important;">
                <p [innerHTML]="item" class="fs-6 clr-white text-center m-0 p-0"></p>
            </ngu-tile>
            <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="myCarousel.isFirst ? 0.2 : 0.7"
                style="z-index: 1">
                <i class="fa fa-angle-left"></i>
            </button>

            <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast ? 0.2 : 0.7">
                <i class="fa fa-angle-right" style="user-select: auto"></i>
            </button>
        </ngu-carousel>
    </div>
</div>

<nav class="position-fixed z-4 w-100 navbar-expand-lg fixed-top navbar-transparent nav-scrolled">
    <div class="wrapper position-relative w-100 px-4 d-flex justify-content-between align-items-center">
        <div class="logo">
            <a routerLink="/"><img src="https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg" alt="COSGrid"
                    height="50rem" loading="lazy"></a>
        </div>
        <ul class="nav-links d-flex">
            <!-- products -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Products<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-11.375rem));width: 70%;">
                        <div class="content p-3">
                            <div class="row">
                                <div class="col-6">
                                    <p class="fw-bold h4">Products</p>
                                    <ng-container *ngFor="let item of products.products">
                                        <p class="fw-bold h5 mt-3 clr-blue-dark">{{item.title}}</p>
                                        <p *ngFor="let item of item.links" class="lh-lg">
                                            <a *ngIf="item.fragment" [routerLink]="[item.link]"
                                                [fragment]="item.fragment"
                                                class="myanimation w-90 d-flex justify-content-between align-items-center"
                                                style="margin: 0;padding: 0;"><span>{{item.name}} </span><i
                                                    class="fa-solid fa-angle-right"
                                                    style="font-size: 15px;color: black !important;"></i></a>

                                            <a *ngIf="!item.fragment" [routerLink]="[item.link]"
                                                class="myanimation w-90 d-flex justify-content-between align-items-center"
                                                style="margin: 0;padding: 0;"><span>{{item.name}} </span><i
                                                    class="fa-solid fa-angle-right"
                                                    style="font-size: 15px;color: black !important;"></i></a>
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-6">
                                    <p class="fw-bold h4">Components</p>
                                    <p *ngFor="let item of products.components" class="lh-lg">
                                        <a [routerLink]='item.link'
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                                <!-- <div class="col-2">
                                    <p class="fw-bold h4">Platform</p>
                                    <p *ngFor="let item of products.platform" class="lh-lg">
                                        <a [href]='item.link' class="myanimation"
                                            style="margin: 0;padding: 0;">{{item.name}} <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- solutions -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Solutions<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-31.375rem));width: 85%;">
                        <div class="content p-3">
                            <div class="row">
                                <div class="col" *ngFor="let item of solutions">
                                    <p class="fw-bold h4">{{item.title}}</p>
                                    <p *ngFor="let item of item.links" class="lh-lg">
                                        <a [routerLink]='item.link'
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- industries -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Industries<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-7.375rem));width: 38%;">
                        <div class="content p-3">
                            <div class="row">
                                <div class="col" *ngFor="let item of industries">
                                    <p *ngFor="let item of item.links" class="lh-lg">
                                        <a [routerLink]='item.link'
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- partners -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Partners<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-6.375rem));width: 26%;">
                        <div class="content p-3">
                            <div class="row">
                                <div class="col" *ngFor="let item of partners">
                                    <p *ngFor="let item of item.links" class="lh-lg">
                                        <a *ngIf="item.fragment" [routerLink]='item.link' [fragment]="item.fragment"
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                        <a *ngIf="!item.fragment" [routerLink]='item.link'
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- resources -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Resources<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-24.375rem));width: 55%;">
                        <div class="content p-3">
                            <div class="row">
                                <div [class]="item.class" *ngFor="let item of resources">
                                    <p class="fw-bold h4">{{item.title}}</p>
                                    <p *ngFor="let item of item.links" class="lh-lg">
                                        <a [href]='item.link' target="_blank" *ngIf="item.external"
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                        <a [routerLink]='item.link' *ngIf="!item.external"
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- company -->
            <li>
                <a [ngStyle]="{ color: 'black', cursor: 'pointer' }" class="desktop-item header fs-6">
                    Company<i [ngClass]="isHovered1 == 1 ? 'fas  fa-chevron-up fs-6' : 'fas fa-chevron-down fs-6'"
                        class="ms-2"></i>
                    <div class="mega-box-new" style="transform: translateX(calc(-8.375rem));width: 26%;">
                        <div class="content p-3">
                            <div class="row">
                                <div class="col" *ngFor="let item of company">
                                    <p *ngFor="let item of item.links" class="lh-lg">
                                        <a [routerLink]='item.link'
                                            class="myanimation w-90 d-flex justify-content-between align-items-center"
                                            style="margin: 0;padding: 0;"><span>{{item.name}}</span> <i
                                                class="fa-solid fa-angle-right"
                                                style="font-size: 15px;color: black !important;"></i></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>

            <!-- support -->
            <li style="margin-right: 15px; font-size: 14px;">
                <a routerLink="/support" [ngStyle]="{ color: 'black', cursor: 'pointer' }"
                    class="desktop-item header fs-6">
                    Support
                </a>
            </li>

            <!-- login -->
            <li class="d-flex align-items-center">
                <a id="trynow" href="https://cosgrid.net" (click)="togglefn('')"
                    [ngStyle]="{ background: '#0078D7', color: '#fff' }"
                    style="border: none; border-radius: 5px; font-weight: bold;display: flex; align-items: center;height: 33px; padding: 5px 10px; margin: none;padding-top: 8px;">LOGIN</a>
            </li>
        </ul>
    </div>
</nav>