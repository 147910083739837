import { MyEmitterService } from './../../../app-emitter.service';
import { RouteConstants } from '../../../config/route.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IComponentDetails } from 'src/app/core/models/components.model';
import { KlentyService } from 'src/app/klenty.service';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-ndrcomponent',
    templateUrl: './productndr.component.html',
    styleUrls: ['./productndr.component.scss'],
})
export class ProductNDRComponent implements OnInit, OnDestroy {
    CONTACTUS_LINK = `/${RouteConstants.CONTACTS}/.`;
    SOLUTIONS_LINK = `${RouteConstants.USECASES}/.`;
    ERROR_LINK = RouteConstants.ERROR;
    private destroy$ = new Subject<void>();
    id = [
        // RouteConstants.C_EDGE,
        // RouteConstants.GUIDER,
        // RouteConstants.CLOUD_NF_HUB,
        // RouteConstants.SECURECONNECT,
        RouteConstants.NETSHIELD,
    ];

    metaContent: any = {
        netshield: {
            title: 'COSGrid NetShield, COSGrid Network: Network Detect and Response (NDR) Solution for IoT Edge Device Security',
            desc: 'COSGrid Network. Protect against IoT edge device threats with COSGrid NetShield NDR solution. Cosgrid Network. Gain real-time visibility, threat detection, and automated response. Monitor, segment, and control your network with advanced analytics and ML-based techniques.',
            key: 'Cosgrid Network, Network Detect and Response, NDR solution, IoT edge device security, real-time visibility, threat detection, automated response, network segmentation, traffic analysis, ML-based techniques',
        },
    };
    input = 'data';

    main: IComponentDetails = {
        container: {
            backimg: 'string',
        },
        intro: {
            imgURL: 'string',
            name: 'string',
            product_line: 'string',
            text: 'string',
            points: [],
        },
        advantages: [
            {
                imgURL: 'string',
                name: 'string',
                text: 'string',
                height: 'string',
                width: 'string',
            },
        ],
        titlef: 'string',
        featuresnew: [
            {
                imgURL: 'string',
                text: 'string',
            },
        ],

        features_imgURL: 'string',
        learnmore: `/${RouteConstants.CONTACTS}/.`,
    };

    ndrcomponents: IComponentDetails[] = [
        {
            container: {
                backimg: 'https://cdn.cosgrid.com/website/assets/images/products/banner-new.png',
            },
            learnmore: `/${RouteConstants.CONTACTS}/.`,
            intro: {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/netsh.png',
                name: 'COSGrid NetShield.',
                product_line: 'Securing against IoT edge device threats through programmable Network elements.',
                text: 'COSGrid NetShield is a big data & ML based Network Detect and Response (NDR) solution that provides real-time and historical visibility, baselining, correlation, anomaly & threat detection and threat mitigation.',
                points: [],
            },
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/realtimetrafficanalytics.svg',
                    name: 'Real time Traffic Analysis',
                    text: 'Continously analyzes raw network traffic and flow records to generate baseline of normal network behavior',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/threat-detection.svg',
                    name: 'Threat Detection',
                    text: 'Applying ML & other analytical techniques (non-signature) to detect suspicious traffic.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/automated-response.svg',
                    name: 'Automated Response',
                    text: 'Analyses east/west traffic to detect lateral movements and performs automated response.',
                    height: '',
                    width: '',
                },
            ],
            titlef: 'Features',
            featuresnew: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g12.png',
                    text: 'Discover & Visualize',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g11.png',
                    text: 'Monitor & Detect',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g10.png',
                    text: 'Network Segment & Access Control',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g9.png',
                    text: 'Orchestrate & Deliver',
                },
            ],
            features_imgURL: '',
        },
    ];
    main1: any = {
        intro: {
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/1.webp',
            logoURL: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/NS.svg',
            name: 'COSGrid NetShield.',
            product_line: 'Agile approach for threat detection & protection',
            text: 'NetShield NDR, is a comprehensive security solution designed to detect and contain post-breach activities within a network. It plays a crucial role in enhancing the effectiveness of cybersecurity measures by actively monitoring real-time traffic flows and comparing them against historical network connection metadata with NetShield NDR, organizations gain enhanced visibility into their network, enabling proactive response to threats and minimizing the impact of breaches.',
        },
        overview: [
            {
                imgURL: 'vision (2)',
                title: 'Comprehensive Visibility ',
                points: [
                    'Deep network visibility and analytics',
                    'Holistic view of network traffic and behaviors',
                    'Enabling informed security decisions.'
                ],

            },
            {
                imgURL: 'detection (3)',
                title: 'Advanced Threat Detection ',
                points: [
                    'Protocol Analysis & Deep Inspection',
                    'AI/Big data ML Classification',
                    'Anomaly Detection'
                ],
            },
            {
                imgURL: 'aerobic-granular-reactor',
                title: 'Streamlined Incident Response',
                points: [
                    'Facilitates efficient incident response',
                    'Comprehensive investigation tools',
                    'Automated response workflows'
                ],
                desc: 'Facilitates efficient incident response with comprehensive investigation tools, visualization features, and automated response workflows.',
            },
            {
                imgURL: '5',
                title: 'Proactive Threat Hunting',
                points: [
                    'Based on the MITRE ATT&CK framework',
                    'Uncover hidden threats ',
                    'Respond swiftly to security incidents'
                ],
                desc: 'Empowers proactive threat hunting based on the MITRE ATT&CK framework to uncover hidden threats and respond swiftly to security incidents.',
            },
        ],
        products: {
            data: [
                {
                    image: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/2.webp',
                    title: 'Comprehensive Visibility ',
                    desc: 'Provides deep network visibility and analytics for a holistic view of network traffic and behaviors, enabling informed security decisions.',
                },
                {
                    image: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/3.webp',
                    title: 'Advanced Threat Detection ',
                    desc: 'Utilizes advanced techniques to proactively detect sophisticated threats that may bypass traditional security measures.',
                },
                {
                    image: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/4.webp',
                    title: 'Streamlined Incident Response',
                    desc: 'Facilitates efficient incident response with comprehensive investigation tools, visualization features, and automated response workflows.',
                },
                {
                    image: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/5.webp',
                    title: 'Proactive Threat Hunting',
                    desc: 'Empowers proactive threat hunting based on the MITRE ATT&CK framework to uncover hidden threats and respond swiftly to security incidents.',
                },
            ],
        },
        ndrOverview: [
            {
                title: '4D Traffic Analysis & Visualization ',
                points: [
                    'Auto-discovers and classifies device',
                    'Multi-pronged traffic meta-data capture',
                    'Adaptive Deep packet inspection, signature'
                ]
            },
            {
                title: 'Network Segmentation & Access Contro',
                points: [
                    'Micro-segment and zero trust access using SDN',
                    'Encrypted tunnels for channel protection'
                ]
            },
            {
                title: 'Monitor & Detect',
                points: [
                    'Learns behavior and detect anomalies in real-time',
                    'Threat hunting through intelligence and visualization with contexts'
                ]
            },
            {
                title: 'Adaptive Orchestration & Defend',
                points: [
                    'Edge/Distributed intelligence for quick remediation',
                    'Security Orchestration and 3rd party integrations'
                ]
            }
        ],
        diff: [
            {
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Network Traffic Analysis (NTA)</span> to analyze network traffic patterns and identify anomalies or suspicious activities.',
            },
            {
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Intrusion Detection System (IDS)</span> functionality helps detect known threats and signatures.',
            },
            {
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Endpoint Threat Analytics (ETA)</span> focuses on analyzing endpoint behavior to identify potential threats.',
            },
            {
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">User and Entity Behavior Analytics (UEBA)</span> adds behavioral analytics to monitor user and entity activities for detecting insider threats or abnormal behaviors. ',
            },
            {
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Threat Hunting Platform (TIP)</span> to leverage threat hunting feeds and enhance threat detection.',
            },
        ],
        diff_img: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/6.webp',
        points: [
            'Higher Mean time to Detect and Respond: Attacks defected after long intrusion and attack.',
            'Responses are not effective',
            'Lots of fiction in Deployment: Limited features, a lot of tuning required, and expensive',
        ],
        ndr_helps: [
            {
                title: 'Need Solution that can',
                points: [
                    'Baseline & Detect Anomalies',
                    'Deep Network visibilty & Analysis',
                    'TLS Fingerprinting',
                    'Encrypted Traffic Analysis',
                    'Micro-segmentation',
                    'Allow only well-known endpoints',
                ],
            },
            {
                title: 'Critical Need is a platform that can be',
                points: [
                    'Adaptive',
                    'Context based',
                    'Converged / Integrated / Correlating',
                    'Programmable',
                    'Scalable',
                ],
            },
        ],
        use_cases: [
            {
                image: 'detection (3)',
                title: 'Advanced threat detection',
                // desc: 'The use of advanced algorithms and machine learning techniques can help to identify and isolate suspicious activity, allowing security teams to take quick action to prevent any potential damage.',
            },
            {
                image: 'vision (2)',
                title: 'Deep network visibility & Analytics',
                // desc: 'By analyzing network traffic patterns and identifying anomalies, organizations can gain a deeper understanding of their network environment and identify areas that may be susceptible to attacks.',
            },
            {
                image: 'detection (2)',
                title: 'Threat Hunting',
                // desc: 'involves actively seeking out potential security threats before they can cause damage. This is accomplished by performing deep dives into network traffic and analyzing logs and other data sources to identify signs of suspicious activity.',
            },
            {
                image: 'aerobic-granular-reactor',
                title: 'Dependency mapping & micro-segmentation',
                // desc: 'By understanding the dependencies between different systems and applications, organizations can create micro-segmented network environments that are more resilient to potential threats. ',
            },
            {
                image: 'agile',
                title: 'Forensic Investigation',
                // desc: 'In the event of a security breach or other cyber incident, forensic analysis can help to identify the root cause of the problem and provide valuable insights into how to prevent similar incidents from occurring in the future.',
            },
            {
                image: 'compliant',
                title: 'Compliance & Audit',
                // desc: 'By implementing NDR solutions that provide detailed logging and reporting capabilities, organizations can demonstrate compliance with industry standards and help to mitigate potential risks associated with non-compliance.',
            },
        ],
    };
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private emitter: MyEmitterService,
        private klentyService: KlentyService,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
    ) { }

    ngOnInit(): void {
        // this.klentyService.load(); //loads the klenty service

        this.input = 'netshield';

        if (!this.id.includes(this.input)) {
            this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
        }
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => ({
                        ...metaTag,
                    }));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log('Meta Tags ', metaTagsData);
                    this.titleService.setTitle(metaTagsData[this.input].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData[this.input].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData[this.input].attributes.keywords,
                    });
                });
        } else {
            // console.log('Data Alreday Fetched');
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data[this.input].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data[this.input].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data[this.input].attributes.keywords,
            });
        }

        // this.titleService.setTitle(this.metaContent[this.input].title);
        // this.metaService.updateTag({ name: 'description', content: `${this.metaContent[this.input].desc}` });
        // this.metaService.updateTag({ name: 'keywords', content: `${this.metaContent[this.input].key}` });

        let a = this.id.indexOf(this.input);
        this.main = this.ndrcomponents[a];

        this.emitter.getcomponentEvent().pipe(takeUntil(this.destroy$)).subscribe((data) => {
            //console.log(data);
            // this.titleService.setTitle(this.metaContent[data.split('/')[2]].title);
            // this.metaService.updateTag({ name: 'description', content: `${this.metaContent[data.split('/')[2]].des}` });
            // this.metaService.updateTag({ name: 'keywords', content: `${this.metaContent[data.split('/')[2]].key}` });

            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag) => ({
                            ...metaTag,
                        }));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData[data.split('/')[2]].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content: metaTagsData[data.split('/')[2]].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData[data.split('/')[2]].attributes.keywords,
                        });
                    });
            } else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data[data.split('/')[2]].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content: data[data.split('/')[2]].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data[data.split('/')[2]].attributes.keywords,
                });
            }

            let a = this.id.indexOf(data.split('/')[2]);
            this.main = this.ndrcomponents[a];
        });
    }

    netshieldBenefits = [
        {
            title: '<span class="clr-blue-dark fw-bold">3X higher accuracy</span> in detection  through multi-pronged approach',
        },
        {
            title: 'Industry leading TAT with <span class="clr-blue-dark fw-bold">response  time within 30 secs to 1 hour</span> – Local &  Edge intelligence',
        },
        {
            title: 'Protection with <span class="clr-blue-dark fw-bold">multiple levels of  SLAs & criticality</span> - both Adaptive and  policy based for optimal analysis',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Ease and effective management</span> through Centralized vs Distributed vs  Hybrid anomaly detection',
        },
        {
            title: '<span class="clr-blue-dark fw-bold">Superior TCO converged product</span> -  probes collection to threat detection  to threat remediation',
        },
    ];

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
