import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

enum CONSENT_STATE {
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    DEFAULT = 'default',
}
@Component({
    selector: 'app-cookie-consent',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
    showNecessary = false;
    showPerformance = false;
    showFunctional = false;
    showTargetting = false;
    cookie: boolean = false;
    visibility: any;

    cookieStorage = {
        getItem: (key) => {
            const cookies = document.cookie
                .split(';')
                .map((cookie) => cookie.split('='))
                .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
            //console.log(cookies);
            return cookies[key];
        },
        setItem: (key, value) => {
            document.cookie = `${key}=${value};expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        },
    };

    cookieKey = 'cosgrid-cookie-consent';
    consentState = 'null';
    showConsentPopup = true;
    isBrowser = false;
    constructor(@Inject(PLATFORM_ID) private platformId) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        this.visibility = localStorage.getItem('cosgrid-cookie-consent');
        window.onload = () => {
            if (this.visibility === null) {
                this.cookie = true;
            } else {
                this.cookie = false;
            }
        };
        if (this.isBrowser) {
            this.consentState = this.cookieStorage.getItem(this.cookieKey) || CONSENT_STATE.DEFAULT;
            this.showConsentPopup = localStorage.getItem(this.cookieKey) === null;
        }
        //console.log(this.showConsentPopup);
        //console.log("init:"+localStorage.getItem(this.cookieKey));
    }

    onAccept() {
        this.showConsentPopup = false;
        //this.cookieStorage.setItem(this.cookieKey, CONSENT_STATE.ACCEPTED);
        localStorage.setItem(this.cookieKey, CONSENT_STATE.ACCEPTED);
        this.consentState = CONSENT_STATE.ACCEPTED;
        //console.log("on accept, consent state is:"+localStorage.getItem(this.cookieKey));
    }

    onCancel() {
        this.showConsentPopup = false;
        //this.cookieStorage.setItem(this.cookieKey, CONSENT_STATE.REJECTED);
        localStorage.setItem(this.cookieKey, CONSENT_STATE.REJECTED);
        this.consentState = CONSENT_STATE.REJECTED;
        //console.log("on reject, consent state is:"+localStorage.getItem(this.cookieKey));
    }
}
