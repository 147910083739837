import { MyEmitterService } from '../../../app-emitter.service';
import { RouteConstants } from '../../../config/route.constants';
import { Component, OnDestroy, OnInit, Sanitizer, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IComponentDetails } from 'src/app/core/models/components.model';
import { KlentyService } from 'src/app/klenty.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-component',
    templateUrl: './productsComponent.component.html',
    styleUrls: ['./productsComponent.component.scss'],
    // encapsulation: ViewEncapsulation.None, - for allowing style in innerHTML
})
export class ProductsComponentComponent implements OnInit, OnDestroy {
    CONTACTUS_LINK = `/${RouteConstants.CONTACTS}/.`;
    SOLUTIONS_LINK = `/${RouteConstants.USECASES}/.`;
    ERROR_LINK = RouteConstants.ERROR;
    private destroy$ = new Subject<void>();
    id = [
        RouteConstants.C_EDGE,
        RouteConstants.GUIDER,
        RouteConstants.CLOUD_NF_HUB,
        RouteConstants.SECURECONNECT,
        // RouteConstants.NETSHIELD,
        RouteConstants.SECURITY_ANALYSER_RESPONDER,
    ];

    input = 'data';
    metaContent: any = {
        cloudnf_hub: {
            title: 'COSGrid CloudNF Hub | Secure and Scalable Cloud Access and Business SaaS Optimization',
            desc: 'Experience optimized access to cloud and Business SaaS applications with COSGrid CloudNF Hub. Benefit from higher bandwidth, rapid service provisioning, and optimized routing for cloud access.',
            key: 'COSGrid CloudNF Hub, cloud access, Business SaaS, optimized routing, link aggregation, rapid provisioning, higher bandwidth, seamless user experience, accelerated performance, network services, secure integration, MPLS WAN',
        },
        c_edge: {
            title: 'COSGrid RefleX-Edge: Intelligent SD-WAN Gateway for Higher Application Performance and Secure Connectivity',
            desc: 'Experience higher application performance and robust edge security with COSGrid RefleX-Edge SD-WAN gateway. Benefit from dynamic traffic steering, comprehensive routing, stateful firewall, and flexible deployment options.',
            key: 'SD-WAN gateway, application performance, edge security, traffic steering, comprehensive routing, stateful firewall, WAN service, intelligent path selection, multi-path aggregation, zero-touch install, high performance',
        },
        guider: {
            title: 'COSGrid Guider | Centralized Network Operations Management and Orchestration',
            desc: 'Gain comprehensive visibility and control over network operations with COSGrid Guider. ',
            key: ' COSGrid Guider, network operations management, network orchestration, real-time visibility, simplified management, automation, network analytics, multi-tenancy, one-click service creation, REST APIs',
        },
        secure_connect: {
            title: 'COSGrid Secure Connect | Secure Client Access for Mobile Workforce',
            desc: 'COSGrid Secure Connect provides secure client access for your mobile workforce. Safeguard data , gain traffic visibility with centralized management and VPN options.',
            key: 'secure client access, mobile workforce, encrypted connections, network traffic visibility, centralized management, VPN options',
        },
        security_analyser_responder: {
            title: 'COSGrid Security Analyser and Responder (SAR): Cloud and On-Prem Security Analytics Platform',
            desc: 'COSGrid Security Analyser and Responder (SAR) is a powerful security analytics platform hosted in the Cloud and on-prem. Detect zero-day attacks, anomalies, and visualize real-time traffic. ',
            key: 'security analyser, security responder, SDS, big data, ML, zero-day attacks, anomaly detection, real-time traffic visualization, threat hunting, adaptive orchestration, policy enforcement points',
        },
    };
    main = {
        container: {
            backimg: 'string',
        },
        intro: {
            imgURL: 'string',
            name: 'string',
            product_line: 'string',
            text: 'string',
            points: [],
        },
        functions: [],
        advantages: [
            {
                imgURL: 'string',
                name: 'string',
                text: 'string',
                height: 'string',
                width: 'string',
            },
        ],
        titlef: 'string',
        featuresnew: [
            {
                imgURL: 'string',
                text: 'string',
            },
        ],
        productReference: [

        ],
        features_imgURL: 'string',
        learnmore: '',
    };

    components = [
        {
            container: {
                backimg: '',
            },
            learnmore: `/${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
            intro: {
                imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20RefleX-Edge/COSGrid%20RefleX-Edge.png',
                name: 'COSGrid RefleX-Edge',
                product_line:
                    'Higher application performance, pervasive and secure connectivity for all your digital journeys.',
                // 'Zero-touch deployment (ZTD) gateway',
                text:
                    // 'COSGrid RefleX-Edge is a virtual gateway deployed at the perimeter of a site/branch or on the cloud to act as an entrance for your WAN. COSGrid RefleX-Edge Gateway is a zero-touch deployment (ZTD) capable of self-configurable features that can do routing, forwarding, security, encryption, QoS, policy, and management.',
                    `COSGrid RefleX Edge is an intelligent SD-WAN gateway Deployed at Branch, cloud and data center that delivers superior application performance and Edge security like`,
                points: [
                    'Dynamic application policy and WAN link quality-based traffic steering',
                    'Delivers Comprehensive routing, Stateful/NGFW Firewall, Encrypted overlay tunnels, NAT, and Application QoS',
                    'Supports a variety of underlay transport including broadband, 4G/5G, Wi-Fi, MPLS, and DIA.',
                    'Flexible deployment models with form factors across on-prem, Data Center, Hypervisor and Cloud',
                ],
                // points: [
                //     {
                //         imgURL: '',
                //         desc: 'Dynamic application policy and WAN link quality-based traffic steering'
                //     },
                //     {
                //         imgURL: '',
                //         desc: 'Delivers Comprehensive routing, Stateful/NGFW Firewall, Encrypted overlay tunnels, NAT, and Application QoS'
                //     },
                //      {
                //         imgURL: '',
                //         desc: 'Supports a variety of underlay transport including broadband, 4G/5G, Wi-Fi, MPLS, and DIA.'
                //      },
                //       {
                //         imgURL: '',
                //         desc: 'Flexible deployment models with form factors across on-prem, Data Center, Hypervisor and Cloud'
                //       }
                // ]
            },
            functions: [{
                images: [
                    {
                        imgUrl: "assets/images/product-components/mza-app-login.jpg"
                    },
                ],
                content: [
                    {
                        icon: '',
                        title: "Comprehensive Routing",
                        description: `Delivers Comprehensive routing, Stateful/NGFW Firewall, Encrypted
                                        overlay tunnels, NAT, and Application QoS
                                        `
                    },
                    {
                        icon: '',
                        title: "Multi-Radio 4G/5G",
                        description: `Supports a variety of underlay transport including broadband, 4G/5G, Wi-Fi,
                                        MPLS, and DIA.
                                        `
                    },
                    {
                        icon: '',
                        title: "High Performance",
                        description: `Provides faster and uninterrupted application sessions through its intelligent path
                                        selection, multi-path aggregation and acceleration.

                                    `
                    }
                ]
            }, {
                images: [
                    {
                        imgUrl: "assets/images/product-components/mza-app-login.jpg"
                    },
                ],
                content: [
                    {
                        icon: '',
                        title: "Ease of Deployment",
                        description: `Flexible deployment models with form factors across on-prem, Data Center,
                                            Hypervisor and Cloud with zero touch operations
                                        `
                    },
                    {
                        icon: '',
                        title: "Intelligent Branch Gateway",
                        description: `Real-time configuration and programmability of vCPE along with traffic intelligence &
                                        other network services, makes RefleX Edge Gateway the ideal network platform for
                                        Cloud and IoT centric world.
.
                                        `
                    },
                    {
                        icon: '',
                        title: "Resilient WAN",
                        description: `Empowers the remote Branch Offices with higher bandwidth & real-time connectivity
                                        with Corporate Offices and Cloud Offices.

                                    `
                    }
                ]
            }],
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20RefleX-Edge/Intelligent%20Branch%20Gateway.png',
                    name: 'Intelligent Branch Gateway',
                    text: 'Real-time configuration and programmability of vCPE along with traffic intelligence & other network services, makes RefleX Edge Gateway the ideal network platform for Cloud and IoT centric world.',
                    height: '',
                    width: '',
                },

                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20RefleX-Edge/Pervasive%20WAN%20Service.png',
                    name: 'Pervasive WAN Service',
                    text: 'RefleX Edge Gateway empowers the remote Branch Offices with higher bandwidth & real-time connectivity with Corporate Offices and Cloud Offices.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20RefleX-Edge/Superior%20Application%20Performance.png',
                    name: 'Superior Application Performance',
                    text: 'RefleX Edge Gateway provides faster and uninterrupted application sessions through its intelligent path selection, multi-path aggregation and acceleration.',
                    height: '',
                    width: '',
                },
            ],
            productReference: [
                {
                    link: '/products/sd-wan',
                    name: 'SD-WAN',
                    width: '70%',
                    imgURL: 'assets/images/product-components/sd-wan-logo.webp'
                },
                {
                    link: '/products/sase',
                    name: 'SASE',
                    width: '100%',
                    imgURL: 'assets/images/product-components/sase-logo.webp'
                },
            ],

            titlef: null,
            featuresnew: [],

            features_imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/components-c_edge-features.png',
        },
        {
            container: {
                backimg: '',
            },
            learnmore: `/${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
            intro: {
                imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Guider/COSGrid%20Guider.png',
                name: 'COSGrid Guider',
                product_line: 'Intuitive Guide to your Network Ops that brings\ncomprehensive visibility and control',
                text:
                    // 'COSGrid Guider provides centralized organization wide installation, configuration and real-time monitoring in addition to orchestrating the data flow through the cloud network. RefleX COSGrid Guider enables one-click provisioning of virtual services in the branch, the cloud, or the enterprise data center or Cloud AZs. Intuitively intelligent COSGrid Guider App helps CXOs to enable their business users secure access to WANs or Corporate network for their respective roles and applications',
                    `COSGrid Guider provides centralized management, monitoring and orchestration capabilities needed to deliver software-defined network and security services from a single console. The services include
                       
                    `,
                points: [
                    'Full lifecycle management including secure on-boarding of devices and users, zero-touch deployment and network automation',
                    'Flexible Multi-tenant, multi-organizational management, and SLA reporting',
                    'Centralized application, security policy management, and custom event handling APIs',
                ],
                // points: [
                //     {
                //         imgURL: '',
                //         desc: 'Full lifecycle management including secure on-boarding of devices and users, zero-touch deployment and network automation'
                //     },
                //      {
                //         imgURL: '',
                //         desc: 'Flexible Multi-tenant, multi-organizational management, and SLA reporting'
                //      },
                //      {
                //         imgURL: '',
                //         desc: 'Centralized application, security policy management, and custom event handling APIs'
                //      }
                // ]
            },
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Guider/Real-time%20visibility%20&%20Control.png',
                    name: 'Real-time visibility & Control',
                    text: 'Operations at fingertips anywhere, anytime using a cloud-based, software-defined management platform.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Guider/Simplified%20Management%20&%20Automation.png',
                    name: 'Simplified Management & Automation',
                    text: 'Eliminates data center backhaul penalties with a cloud-ready network to provide an optimized direct path to public and private enterprise clouds. Reduce the branch office footprint with a single click',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Guider/Flexible%20and%20Xtensible%20Platform.png',
                    name: 'Flexible and Xtensible Platform',
                    text: 'Easy to integrate with other management and monitoring platforms through well-defined REST APIs',
                    height: '',
                    width: '',
                },
            ],
            functions: [{}, {}],
            productReference: [

            ],
            titlef: null,
            featuresnew: [],
            features_imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/components-guider-features.png',
        },
        {
            container: {
                backimg: '',
            },
            learnmore: `/${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
            intro: {
                imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20CloudNF%20Hub/COSGrid%20CloudNF%20Hub.png',
                name: 'COSGrid CloudNF Hub',
                product_line: 'The Hub that brings close the Cloud power simply and securely.',
                text:
                    // 'RefleX COSGrid CloudNF Hub is deployed at tier 1 network at Central location or on the cloud offering required scalability, redundancy, and on-demand flexibility. RefleX COSGrid CloudNF Hub delivers the proven architecture for optimized access to cloud and Business SaaS applications.',
                    `COSGrid CloudNF Hub is deployed at tier 1 network at Central location or on the cloud offering required scalability, redundancy, and on-demand flexibility. COSGrid CloudNF Hub delivers the proven architecture for optimized access to cloud and Business SaaS applications with`,
                points: [
                    'Higher bandwidth through link aggregation',
                    'Rapid provisioning of Services',
                    'Optimised routing for cloud access',
                ],
                // points: [
                //     {
                //         imgURL: '',
                //         desc: 'Higher bandwidth through link aggregation'
                //     },
                //      {
                //         imgURL: '',
                //         desc: 'Rapid provisioning of Services'
                //      },
                //      {
                //         imgURL: '',
                //         desc: 'Optimised routing for cloud access'
                //      }
                // ]
            },
            functions: [{}, {}],
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20CloudNF%20Hub/Application%20Session%20continuity.png',
                    name: 'Application Session continuity',
                    text: 'Delivers seamless user experience for distributed and thin applications such as Citrix, VoIP and other business critical applications by solving Same IP fail-over problem typical in WAN',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20CloudNF%20Hub/Accelerated%20Cloud%20Apps%20Performance.png',
                    name: 'Accelerated Cloud Apps Performance',
                    text: 'Delivers faster downloads / uploads for heavy enterprise applications and for Cloud Apps such as Office365, Salesforce, AWS and Google',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20CloudNF%20Hub/Network%20Services%20as%20Subscription.png',
                    name: 'Network Services as Subscription',
                    text: 'Flexibly run network services like IPSec VPNs, SSL VPNs, Multi-Path Aggregation, WAN Optimization, UTM services on a multi-tenant subscription model',
                    height: '',
                    width: '',
                },
            ],
            productReference: [

            ],

            titlef: null,
            featuresnew: [],
            features_imgURL:
                'https://cdn.cosgrid.com/website/assets/images/components/components-cloudNF_hub-features.png',
        },
        {
            container: {
                backimg: '',
            },
            learnmore: `/${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
            intro: {
                imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/COSGrid Secure Connect.png',
                name: 'COSGrid Secure Connect',
                product_line: 'Splendid Secure client access',
                text: "COSGrid Secure Connect is a desktop application (Windows, macOS, and Linux devices) that runs on and extends COSGrid Secure Connect to client devices (i.e., Windows devices, MacOS devices, iOS devices, Linux devices, and smartphones). COSGrid's Secure Access Client creates a secure and encrypted connection from remote devices to the distributed system of COSGrid CloudNF Hubs with application segmentation and SD-WAN.",
                points: [],
            },
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Security.png',
                    name: 'Security',
                    text: 'COSGrid Secure Connect safeguards your mobile workforce by using the capabilities of your Next-Generation Firewall to inspect all traffic—incoming and outgoing.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Visibility.png',
                    name: 'Visibility',
                    text: 'Enable always-on IPsec/SSL VPN connection between a variety of endpoints and operating systems to deliver transparent access to sensitive data without risk. Quarantine compromised devices with immutable characteristics on internal and external networks.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Control.png',
                    name: 'Control',
                    text: 'Eliminate blind spots in your mobile workforce traffic with full visibility across all network traffic, applications, ports, and protocols.',
                    height: '',
                    width: '',
                },
            ],
            functions: [{}, {}],
            productReference: [

            ],

            titlef: '',
            featuresnew: [],
            features_imgURL:
                'https://cdn.cosgrid.com/website/assets/images/components/components-secure_connect-features.png',
        },
        // {
        //     container: {
        //         backimg: '',
        //     },
        //     learnmore: `/${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
        //     intro: {
        //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Security%20Analyser%20and%20Responder/COSGrid Security Analyser and Responder.png',
        //         name: 'COSGrid Security Analyser and Responder',
        //         product_line: 'Adaptive Orchestration and Defend analytics platform hosted in Cloud and on-prem',
        //         text: `COSGrid SAR provides Software Defined Security (SDS) through a big-data & ML security analytics platform hosted in the Cloud and on-prem that provides real-time detection of zero-day attacks and anomaly detection through baselined behaviors of endpoints, users and IoT devices. It enables real-time traffic visualization, threat hunting and provides response through integrations with policy enforcement points.
        //             `,
        //         points: [],
        //     },
        //     advantages: [
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Security%20Analyser%20and%20Responder/On-Prem SAR.png',
        //             name: 'On-Prem SAR',
        //             // text: this.sanitizer.bypassSecurityTrustHtml(`<ul style="margin-left:30px">
        //             //     <li>Vmware: vmdk & ova image</li>
        //             //     <li>KVM: qcow2 image</li>
        //             //     <li>Container: docker compose (PoCs)</li>
        //             //     <li>Kubernetes: Helm</li></ul>
        //             //     `) as string,
        //             text: '',
        //             points: [
        //                 'Vmware: vmdk & ova image',
        //                 'KVM: qcow2 image',
        //                 'Container: docker compose (PoCs)',
        //                 'KVM: qcow2 image'
        //             ],
        //             height: '',
        //             width: '85%',
        //         },
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Security%20Analyser%20and%20Responder/Adaptive Orchestration & Defend.png',
        //             name: 'Adaptive Orchestration & Defend',
        //             // text: this.sanitizer.bypassSecurityTrustHtml(
        //             //     `<ul style="margin-left:30px">
        //             //     <li>Edge/Distributed intelligence for quick remediation</li>
        //             //     <li>Security Orchestration and 3rd party integrations</li>`,
        //             // ) as string,
        //             text: '',
        //             points: [
        //                 'Edge/Distributed intelligence for quick remediation',
        //                 'Security Orchestration and 3rd party integrations'
        //             ],
        //             height: '',
        //             width: '',
        //         },
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Security%20Analyser%20and%20Responder/Monitor & Detect.png',
        //             name: 'Monitor & Detect',
        //             // text: this.sanitizer.bypassSecurityTrustHtml(
        //             //     `<ul style="margin-left:30px">
        //             //     <li>Learns behavior and detect anomalies in real-time</li>
        //             //     <li>Threat hunting through intelligence and visualization  with contexts</li>`,
        //             // ) as string,
        //             text: '',
        //             points: [
        //                 'Learns behavior and detect anomalies in real-time',
        //                 'Threat hunting through intelligence and visualization  with contexts'
        //             ],
        //             height: '',
        //             width: '95%',
        //         },
        //     ],
        //     // productReference: [
        //     //     {
        //     //         link: '',
        //     //         name: '',
        //     //         width: '',
        //     //         imgURL: ''
        //     //     }
        //     // ],
        //     titlef: '',
        //     featuresnew: [],
        //     features_imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/adv%20sar.png',
        // },
        {
            container: {
                backimg: '',
            },
            learnmore: `/${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
            intro: {
                imgURL: 'assets/images/product-components/mza-app-component.png',
                name: 'MZA App client',
                product_line: `A critical component of the Zero Trust Network Access
                                (ZTNA)/ SASE solution, ensuring secure and controlled access
                                to organization resources for end-users.`,
                text: `COSGrid MZA App Client is a software application installed on end-user devices (laptops, desktops) to provide secure
                            and controlled access to corporate resources and applications. It acts as a bridge between the user's device and the
                            COSGrid SASE/ MicroZAcces ZTNA platform.
                            `,
                points: [],
            },
            functions: [
                {
                    images: [
                        {
                            imgUrl: "assets/images/product-components/mza-app-login.jpg"
                        },
                    ],
                    content: [
                        {
                            icon: '',
                            title: "User Authentication",
                            description: `MZA App client authenticates the user's identity using various methods
                                            like username/ password, multi-factor authentication (MFA), or single signon (SSO).
                                            `
                        },
                        {
                            icon: '',
                            title: "Device Security Posture",
                            description: `MZA App client evaluates the security posture of the user's device to ensure it
                                    meets compliance requirements before granting access to resources`
                        },
                        {
                            icon: '',
                            title: "Secure Communication",
                            description: `MZA App client establishes secure communication channels with the COSGrid
                                        MicroZAccess ZTNA / SASE platform using encryption and other security
                                        protocols.
                                        `
                        }
                    ]
                },
                {
                    images: [
                        {
                            imgUrl: "assets/images/product-components/mza-app-login.jpg"
                        }
                    ],
                    content: [
                        {
                            icon: '',
                            title: "Application Access",
                            description: `MZA App client facilitates secure access (micro tunnels) to authorized applications
                                                and resources by establishing secure connections and enforcing access policies.

                                            `
                        },
                        {
                            icon: '',
                            title: "User Experience",
                            description: `MZA App client provides a user-friendly interface for users to manage their access
                                            and applications.
                                            `
                        },
                        {
                            icon: '',
                            title: "End-Point Protection",
                            description: `MZA App client may integrate with endpoint protection platforms to enhance
                                            device security.

                                        `
                        }
                    ]
                },
            ],
            advantages: [
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Security.png',
                    name: 'Security',
                    text: 'COSGrid Secure Connect safeguards your mobile workforce by using the capabilities of your Next-Generation Firewall to inspect all traffic—incoming and outgoing.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Visibility.png',
                    name: 'Visibility',
                    text: 'Enable always-on IPsec/SSL VPN connection between a variety of endpoints and operating systems to deliver transparent access to sensitive data without risk. Quarantine compromised devices with immutable characteristics on internal and external networks.',
                    height: '',
                    width: '',
                },
                {
                    imgURL: 'https://cdn.cosgrid.com/website/assets3/Products/Components/COSGrid%20Secure%20Connect/Control.png',
                    name: 'Control',
                    text: 'Eliminate blind spots in your mobile workforce traffic with full visibility across all network traffic, applications, ports, and protocols.',
                    height: '',
                    width: '',
                },
            ],
            productReference: [

            ],

            titlef: '',
            featuresnew: [],
            features_imgURL:
                'https://cdn.cosgrid.com/website/assets/images/components/components-secure_connect-features.png',
        }


        // {
        //     container:{
        //         backimg:'https://cdn.cosgrid.com/website/assets/images/products/banner-new.png',

        //     },
        //     learnmore: RouteConstants.CONTACTS,
        //     intro: {
        //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/netsh.png',
        //         name: 'COSGrid NetShield.',
        //         product_line: 'Securing against IoT edge device threats through programmable Network elements.',
        //         text:
        //             'COSGrid NetShield is a big data & ML based Network Detect and Response (NDR) solution that provides real-time and historical visibility, baselining, correlation, anomaly & threat detection and threat mitigation.',
        //     },
        //     advantages: [
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/realtimetrafficanalytics.svg',
        //             name: 'Real time Traffic Analysis',
        //             text:
        //                 'Continously analyzes raw network traffic and flow records to generate baseline of normal network behavior',
        //         },
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/threat-detection.svg',
        //             name: 'Threat Detection',
        //             text: 'Applying ML & other analytical techniques (non-signature) to detect suspicious traffic.',
        //         },
        //         {
        //             imgURL: 'https://cdn.cosgrid.com/website/assets/images/components/automated-response.svg',
        //             name: 'Automated Response',
        //             text: 'Analyses east/west traffic to detect lateral movements and performs automated response.',
        //         },
        //     ],
        //     titlef: 'Features',
        //     featuresnew: [

        //             {
        //               imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g12.png',
        //               text: 'Discover & Visualize',
        //           },
        //           {
        //               imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g11.png',
        //               text: 'Monitor & Detect',
        //           },
        //           {
        //               imgURL: 'https://cdn.cosgrid.com/website/assets/images/products/g10.png',
        //               text: 'Network Segment & Access Control',
        //           },
        //           {
        //             imgURL:'https://cdn.cosgrid.com/website/assets/images/products/g9.png',
        //             text:'Orchestrate & Deliver'
        //           }

        //       ],
        //     features_imgURL:'',

        // },
    ];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private emitter: MyEmitterService,
        private klentyService: KlentyService,
        private sanitizer: DomSanitizer,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
    ) { }

    loadComponents() {
        if (!this.id.includes(this.input)) {
            this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
        }
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => ({
                        ...metaTag,
                    }));
                    for (var i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData[this.input].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData[this.input].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData[this.input].attributes.keywords,
                    });
                });
        } else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data[this.input].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data[this.input].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data[this.input].attributes.keywords,
            });
        }

        // this.titleService.setTitle(this.metaContent[this.input].title);
        // this.metaService.updateTag({ name: 'description', content: `${this.metaContent[this.input].desc}` });
        // this.metaService.updateTag({ name: 'keywords', content: `${this.metaContent[this.input].key}` });
        let a = this.id.indexOf(this.input);
        // console.log("init idx "+a);
        this.main = this.components[a];

        this.emitter.getcomponentEvent().pipe(takeUntil(this.destroy$)).subscribe((data) => {
            // console.log('emit '+data);
            let a = this.id.indexOf(data.split('/')[2]);
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag) => ({
                            ...metaTag,
                        }));
                        for (var i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData[data.split('/')[2]].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content: metaTagsData[data.split('/')[2]].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData[data.split('/')[2]].attributes.keywords,
                        });
                    });
            } else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data[data.split('/')[2]].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content: data[data.split('/')[2]].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data[data.split('/')[2]].attributes.keywords,
                });
            }

            // this.titleService.setTitle(this.metaContent[data.split('/')[2]].title);
            // this.metaService.updateTag({ name: 'description', content: `${this.metaContent[data.split('/')[2]].desc}` });
            // this.metaService.updateTag({ name: 'keywords', content: `${this.metaContent[data.split('/')[2]].key}` });
            // console.log('emit idx '+a);
            this.main = this.components[a];
            // console.log(this.main);
        });
    }

    ngOnInit(): void {
        // Subscribe to route params to handle the changes
        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.input = params['id'];
            this.loadComponents();
        });
        // console.log('init '+this.input);
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
