<!-- <div class="nav-top">
    <div class="w-100 position-relative">
        <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselSliderList">
            <ngu-tile *nguCarouselDef="let item; let i = index" style="box-shadow: none !important;">
                <p [innerHTML]="item" style="color: #fff;text-align: center;font-size: 12px;" class="tile-content"></p>
            </ngu-tile>
            <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="myCarousel.isFirst ? 0.2 : 0.7"
                style="z-index: 1">
                <i class="fa fa-angle-left"></i>
            </button>

            <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast ? 0.2 : 0.7">
                <i class="fa fa-angle-right" style="user-select: auto"></i>
            </button>
        </ngu-carousel>
    </div>

</div> -->
<nav class="navbar navbar-expand-lg fixed-top navbar-transparent" [ngClass]="{ 'nav-scolled': isScrolled }">
    <div class="wrapper">
        <div class="logo">
            <a *ngFor="let item of homeNavs" [routerLink]="item.link"><img [src]="image" alt="" srcset="" loading="lazy"
                    width="60rem" /></a>
        </div>
        <input type="radio" name="slider" id="menu-btn" value="menu" [(ngModel)]="menutoggle" />
        <input type="radio" name="slider" id="close-btn" value="close" [(ngModel)]="menutoggle" />
        <ul class="nav-links">
            <label for="close-btn" class="btn close-btn"><i class="fas fa-times" (click)="openLinks('')"></i></label>
            <!-- products -->
            <li>
                <a class="fs-3" (click)="openLinks('product')">Products&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <div class="box" *ngIf="openedTab == 'product'">
                    <div class="ps-4">
                        <!-- PRODUCTS -->
                        <header class="h2 fw-bold mb-3">Products</header>
                        <div class="mb-4" *ngFor="let item of products.products">
                            <header class="h4 fw-bold mb-2">{{item.title}}</header>
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="mb-4">
                            <header class="h2 fw-bold">Components</header>
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let item of products.components">
                                    <a class="fs-6" [routerLink]="item.link"
                                        (click)="componentClick(item.link)">{{item.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!-- solutions -->
            <li>
                <a (click)="openLinks('solutions')" class="fs-3">Solutions&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <div class="box" *ngIf="openedTab == 'solutions'">
                    <div class="ps-4">
                        <!-- PRODUCTS -->
                        <div class="mb-1" *ngFor="let item of solutions">
                            <header class="mb-3">{{item.title}}</header>
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!-- industries -->
            <li>
                <a (click)="openLinks('industries')" class="fs-3">Industries&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <input type="checkbox" id="showMega1" />
                <div class="box" *ngIf="openedTab == 'industries'">
                    <div class="ps-4">
                        <div class="mb-3" *ngFor="let item of industries">
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!-- partners -->
            <li>
                <a (click)="openLinks('partners')" class="fs-3">Partners&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <div class="box" *ngIf="openedTab == 'partners'">
                    <div class="ps-4">
                        <div class="mb-3" *ngFor="let item of partners">
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
            <!-- resources -->
            <li>
                <a (click)="openLinks('resources')" class="fs-3">Resources&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <div class="box" *ngIf="openedTab == 'resources'">
                    <div class="ps-4">
                        <div class="mb-3" *ngFor="let item of resources">
                            <header>{{item.title}}</header>
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!-- company -->
            <li>
                <a (click)="openLinks('company')" class="fs-3">Company&nbsp;&nbsp;<i
                        class="fas fa-chevron-down fs-6"></i></a>
                <div class="box" *ngIf="openedTab == 'company'">
                    <div class="ps-4">
                        <div class="mb-3" *ngFor="let item of company">
                            <ul class="ms-1">
                                <li class="fs-6 lh-base mb-1" *ngFor="let link of item.links">
                                    <a class="fs-6" [routerLink]="link.link"
                                        (click)="componentClick(link.link)">{{link.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </li>

            <!-- support -->
            <li>
                <a routerLink="/support" [ngStyle]="{ background: '#2834e2', color: '#fff' }" (click)="togglefn('')"
                    style="border: none; border-radius: 5px; font-weight: bold; height:30px">
                    Support
                </a>
            </li>


            <li>
                <a id="trynow" href="https://cosgrid.net/auth/login" (click)="togglefn('')"
                    [ngStyle]="{ background: '#2834e2', color: '#fff' }"
                    style="border: none; border-radius: 5px; font-weight: bold; height:30px">LOGIN</a>
            </li>
        </ul>
        <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
    </div>
</nav>