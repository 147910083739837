import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-resource-list',
    templateUrl: './resource-list.component.html',
    styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent  {
    @Input() data: any;
    @Input() colsForRows: number = 3;
    @Input() title: string;

    constructor() {}


}
