<!-- <div style="min-height: 8vh"></div> -->
<!-- <head>
<meta property="og:site_name" content="https://www.cosgrid.com" />
<meta property=“og:type” content=“article” />
<meta property="og:title" content={{ blog.title }} />
<meta property="og:image" content=blog.image }} />
<meta property="og:url" content=`https://www.cosgrid.com/blogs/${blog.idUrl}` /> 
</head> -->
<div
    *ngIf="loading == true;else showblog"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee;"
>
    <div style="margin: auto;">
        <img style="display: block;" class="logo-loading" src="assets/images/navbar/fLogo.svg" alt="Logo loading.." loading="lazy"/>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid">
    <div class="row pt-5">
        <div class="col">
            <h1>COSGrid Blogsxxx</h1>
        </div>
    </div>
</div>
<div class="container-fluid" *ngFor="let blog of blogs">
    <div class="row" *ngIf="router.url.charAt(router.url.length - 1) == blog.id">
        <div class="col pt-4 pb-4" *ngIf="router.url.charAt(router.url.length - 1) == blog.id">
            <h3>{{ blog.title }}</h3>
        </div>
    </div>
</div>
<div class="container-fluid pb-md-4" *ngFor="let blog of blogs">
    <div class="row" *ngIf="router.url.charAt(router.url.length - 1) == blog.id">
        <div class="col-xl-5" *ngIf="router.url.charAt(router.url.length - 1) == blog.id">
            <img src="{{ blog.image }}" alt="" style="width: 550px" class="img img-fluid" />
        </div>
        <div class="col-xl-7" *ngIf="router.url.charAt(router.url.length - 1) == blog.id">
            <p *ngIf="router.url.charAt(router.url.length - 1) == blog.id">{{ blog.description }}</p>
            <p *ngIf="router.url.charAt(router.url.length - 1) == blog.id">{{ blog.content }}</p>
            <small *ngIf="router.url.charAt(router.url.length - 1) == blog.id" class="fs-6 mt-3 text-muted">{{
                blog.dateofpublish
            }}</small>
        </div>
    </div>
</div> -->
<ng-template #showblog>
    <div class="container mt-5">
        <br /><br />
        <a routerLink="/resources/blogs">
            <img src="https://cdn.cosgrid.com/website/assets/images/blogs/goback.svg" alt="Go back" loading="lazy"/>
        </a>
        <div class="title pt-2 pb-2">
            <div>
                <div>
                    <h1 class="fs-9 text-blue text-bolder">{{ blog.attributes.Title }}</h1>
                </div>
            </div>
        </div>
        <div class="date pt-1 pb-1">
            <small class="fs-5 mt-3 text-muted"
                >Murugavel Muthu | {{ blog.attributes.Dateofpublish }}</small
            >
        </div>
        <div class="social">
            <span class="fs-4 mt-3 text-muted">Share</span>
            <span class="fs-5 mt-3 text-muted pl-2"
                ><a href="https://www.linkedin.com/company/cosgrid-networks/"
                    ><img
                        src="https://cdn.cosgrid.com/website/assets/images/blogs/linkedin.png"
                        height="30px"
                        width="30px"
                        alt="" loading="lazy"/></a
            ></span>
            <span class="fs-5 mt-3 text-muted pl-2"
                ><a href="https://twitter.com/CosgridNetworks"
                    ><img
                        src="https://cdn.cosgrid.com/website/assets/images/blogs/twitter.png"
                        height="30px"
                        width="30px"
                        alt="" loading="lazy"/></a
            ></span>
            <span class="fs-5 mt-3 text-muted pl-2"
                ><a href="https://m.facebook.com/people/COSGrid-Networks/100078776480639/"
                    ><img
                        src="https://cdn.cosgrid.com/website/assets/images/blogs/facebook.png"
                        height="30px"
                        width="30px"
                        alt="" loading="lazy"/></a
            ></span>
        </div>
        <br>
        <div class="imageContainer pt-2 pb-2">
            <div class="pb-2 pt-2">
                <img [src]="blog.image" alt="" style="width: 700px;" class="img img-fluid" loading="lazy"/>
            </div>
        </div>
        <br /><br />
        <div class="description">
            <div>
                <!-- <div
                    class="pb-2 pt-2"              
                    [innerHTML]="blog.description"
                ></div> -->
                <div class="fs-6" [innerHTML]="safeHtml"></div>
            </div>
        </div>
    </div>
    <br><Br>
    <h4 class="text-center">Our Blogs</h4>
    <br>
    <div class="sidenav">
        <div class="cardContainer">
            <div class="card" *ngFor="let blog of redirectBlogs">
                <div class="imageContainer">
                    <img class="imageawards" width="290px" height="208px" [src]="blog.image" alt="" loading="lazy"/>
                </div>
                <div class="cardtitle">
                    <a [href]="blog.attributes.Blogurl" target="_blank">
                        <p class="text-bolder text-blue">{{ blog.attributes.Title }}</p>
                    </a>
                </div>
            </div>
            <div class="card" *ngFor="let blog of blogs">
                <div class="imageContainer">
                    <img class="imageawards" width="290px" height="208px" [src]="blog.image" alt="" loading="lazy"/>
                </div>
                <div class="cardtitle">
                    <a (click)="routeToblog(blog)">
                        <p class="text-bolder text-blue">{{ blog.attributes.Title }}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>

    
</ng-template>
