// BASE_URLS

// new website
const HOME = 'home';
const PRODUCT = 'product';
const COMPANY = 'company'

// CAREERS
const CAREERS = 'careers';
const OPENINGS = 'openings';
const WHITE_PAPERS = 'whitepapers-datasheets'
const CASE_STUDIES = 'case-studies'
const MOMENTS = 'moments'
const FIREWALL = 'firewall'
const BECOME_PARTNER = 'become-partner'
const LOGISTICS = 'Logistics'
const ITITES = 'itites'
const VPN = 'vpn'







const ABOUTUS = 'aboutus';
const PRESS_RELEASE = 'press-release';
const TESTIMONIAL = 'testimonial';
const CONTACTS = 'contacts';
const PARTNERS = 'partners';
const SD_WAN = 'sd-wan';
const ZTNA = 'ztna'
const NEW = 'new';
const GALLERY = 'gallery';
const PRIVACY_POLICY = 'privacy-policy';
const TERMS_OF_USE = 'terms-of-use';
const SITEMAP = 'sitemap';
const Support = 'support';
const CANCELATION = 'cancellation-refund-policy'
const IDENTITY_PROVIDERS = 'identity-providers'

// const NEWS = 'news';
// const BLOGS = `${NEWS}/blogs`;
// const AWARDS = `${NEWS}/awards`;
// const EVENTS = `${NEWS}/events`;
const BLOGS = 'blogs';
const AWARDS = 'awards';
const EVENTS = 'events';
const COSGRIDBLOGS = `cosgridblogs`;
const FAQS = 'faqs';
const COMPANYPROFILE = 'companyprofile';
const COSGRIDFLYER = 'companyflyer';
const REPORTS = 'reports';

// SOLUTIONS
const RESOURCES = 'resources';
const USECASES = `${RESOURCES}/sd-wan/usecases`;
const USECASE = `usecase`;
const SUSECASES = 'usecases';
const SUSECASE = 'usecase';
// const AppPerformance = `${SOLUTIONS}/#eap`;
// const SecureBranchInternetAccess = `${SOLUTIONS}/`;
// const SaaSAcceleration = `${SOLUTIONS}/#wa`;
// const CentralisedManagement = `${SOLUTIONS}/#cm`;
// const SecureRemoteAccess = `${SOLUTIONS}/#sra`;
// const ZeroglitchVoiceandVideo = `${SOLUTIONS}/#vavob`;
// const MPLSmigrationtoSDWAN = `${SOLUTIONS}/#mmsw`;

// const SOLUTIONS = 'solutions';
// const INDUSTRIES = `${SOLUTIONS}/industries`;
// const INDUSTRY = `${SOLUTIONS}/industry`;
const INDUSTRIES = 'industries';
const INDUSTRY = 'industry';
const FINANCIAL_SERVICES = 'financial_services';
const HEALTHCARE = 'healthcare';
const MANUFACTURING = 'manufacturing';
const RESTAURANTS_HOSPITALITY = 'restaurants_hospitality';
const RETAIL = 'retail';
const SUPPORT = 'support'

// PRODUCTS
const PRODUCTS = 'products';

const SECURESDWAN = 'securesdwan';
const SECURESDWAN_REFLEX_HYBWAN = `reflex_hybrid`;
const SECURESDWAN_REFLEX_INWAN = `reflex_in`;
const FIVEG_SDWAN = `z3-5gsdwan`;
const NETSHIELD = 'netshield';
const SDINTERNETEDGE = 'sdinternetedge';
const SECUREACCESS = 'secureaccess';
const MICROZACCESS = 'microzaccess';
const SECURITY_ANALYSER_RESPONDER = 'security_analyser_responder';
const REFLEX_SDWAN = 'reflex-sdwan';
const SASE = 'sase';

// COMPONENTS
const COMPONENTS = 'components';
const COMPONENT = 'component';
const C_EDGE = 'c_edge';
const CLOUD_NF_HUB = 'cloudnf_hub';
const GUIDER = 'guider';
const SECURECONNECT = 'secure_connect';



//MZA-DOCUMENTATION
const MZA_DOC = 'mza-docs';

const ERROR = 'error/404';



//Identity Providers
const AZURE_AD = 'azure-ad';
const OKTA = 'okta';
const GOOGLE_SUITE = 'google-suite';
const ONE_LOGIN = 'one-login';

export const RouteConstants = {

    // new website
    HOME,
    COMPANY,
    PRODUCT,
    ABOUTUS,
    CAREERS,
    OPENINGS,
    RESOURCES,
    WHITE_PAPERS,
    CASE_STUDIES,
    MOMENTS,
    FIREWALL,
    BECOME_PARTNER,
    LOGISTICS,
    VPN,
    SUPPORT,






    NEW,
    
    
    TESTIMONIAL,
    CONTACTS,
    PRESS_RELEASE,
    Support,
    //NEWS,
    BLOGS,
    AWARDS,
    EVENTS,
    PARTNERS,
    SD_WAN,
    ZTNA,
    
    GALLERY,
    PRIVACY_POLICY,
    TERMS_OF_USE,
    SITEMAP,
    MZA_DOC,
    //SOLUTIONS,
    //usecases
    USECASES,
    SUSECASES,
    USECASE,
    SUSECASE,
    MICROZACCESS,
    SECURITY_ANALYSER_RESPONDER,
    // AppPerformance,
    // SecureBranchInternetAccess,
    // SaaSAcceleration,
    // CentralisedManagement,
    // SecureRemoteAccess,
    // ZeroglitchVoiceandVideo,
    // MPLSmigrationtoSDWAN,
    //Industries
    INDUSTRIES,
    // SINDUSTRIES,
    // INDUSTRY,
    // SINDUSTRY,
    FINANCIAL_SERVICES,
    HEALTHCARE,
    MANUFACTURING,
    RESTAURANTS_HOSPITALITY,
    RETAIL,
    INDUSTRY,
    ITITES,
    //BLOGS
    COSGRIDBLOGS,
    FAQS,
    COMPANYPROFILE,
    COSGRIDFLYER,
    REPORTS,
    PRODUCTS,
    
    SECURESDWAN,
    SECURESDWAN_REFLEX_HYBWAN,
    SECURESDWAN_REFLEX_INWAN,
    FIVEG_SDWAN,
    SDINTERNETEDGE,
    SECUREACCESS,
    COMPONENTS,
    COMPONENT,
    C_EDGE,
    CLOUD_NF_HUB,
    GUIDER,
    SECURECONNECT,
    NETSHIELD,

    ERROR,
    CANCELATION,
    REFLEX_SDWAN,
    SASE,
    IDENTITY_PROVIDERS,
    OKTA,
    ONE_LOGIN,
    AZURE_AD,
    GOOGLE_SUITE
};
