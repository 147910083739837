import { Component } from '@angular/core';

@Component({
  selector: 'app-mobilefooter',
  templateUrl: './mobilefooter.component.html',
  styleUrls: ['./mobilefooter.component.scss']
})
export class MobilefooterComponent {

  constructor() { }


}
