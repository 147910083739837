<div class="" style="width: 100%;">
    <mat-tab-group>
        <mat-tab *ngFor="let item of data.data" [label]="item.sliderTitle" class="x1 text-dark mb-3">
            <div class="pt-0 flex-change" style="align-items: center">
                <div class="text-center pt-0 d-flex justify-content-center mr-3 mr-lg-5">
                    <img [src]="data.imgBaseUrl + item.imgURL + '.png'" [attr.width]="150" loading="lazy"
                        class="img-fluid" />
                </div>
                <div class="pt-0">
                    <p class=" text-center mr-3 ml-3 mr-md-0 ml-md-0 fw-bold fs-4" [innerHTML]="item.title"></p>
                    <p class="fs-6 d-flex text-justify" *ngFor="let point of item.points">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px">
                                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path fill="#006d8f"
                                    d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                            </svg>
                        </span>

                        <span [innerHTML]="point" style="line-height: 1.3rem;"></span>
                    </p>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>