import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-problem-list',
  templateUrl: './problem-list.component.html',
  styleUrls: ['./problem-list.component.scss']
})
export class ProblemListComponent  {
  @Input() data: any
  @Input()  title: string
  @Input() desc: string
  constructor() { }



}
