<div *ngIf="cookie">
    <div id="cookie-disclaimer" *ngIf="showConsentPopup" class="consent-popup rounded d-none">
        <div class="text-white d-flex justify-content-center">
            <div class="d-flex gap-1 w-100">
                <div class="fs-6">

                </div>
                <div class="justify-self-end">
                    <button type="button"
                        class="d-flex justify-content-center align-items-center close-btn fw-bold text-center btn clr-white"
                        aria-label="close" (click)="showConsentPopup = false">
                        &times;
                    </button>
                </div>
            </div>
            <!-- This site uses cookies to enhance user experience. see -->
        </div>
        <br /><br />
        <div class="action-buttons" class="d-sm-flex flex-sm-row">
            <button type="button" class="m-2 btn btn-primary" data-toggle="modal" data-target="#cookiesettings">
                <span class="text-white fs-6">Manage Cookies</span>
            </button>
            <button (click)="onAccept()" class="m-2 btn btn-primary"><span class="text-white fs-6">Allow
                    cookies</span></button>
            <!-- <button (click)="onCancel()" class="m-2 btn btn-secondary-blue">Cancel</button> -->
        </div>
    </div>
    <div *ngIf="showConsentPopup" class="position-fixed w-100 z-3  d-flex justify-content-center align-items-center d-none" style="bottom: 2%;">
        <div class="h-100 w-80 bg-clr-black-dark px-5 py-2 rounded-2 position-relative">
            <p class="fs-6 clr-white px-md-5 px-2"> This site uses cookies to enhance user experience. We also share information about your use of
                our
                site with our social media, advertising and analytics partners. For more information, see
                <a routerLink="/privacy-policy" class="clr-blue-light fw-bold"> Privacy Policy &nbsp; </a>
            </p>
            <div class="d-flex justify-content-center">
                <button type="button" class="m-2 btn btn-primary" data-toggle="modal" data-target="#cookiesettings">
                    <span class="text-white fs-6">Manage Cookies</span>
                </button>
                <button (click)="onAccept()" class="m-2 btn btn-primary"><span class="text-white fs-6">Allow
                        cookies</span></button>
                <!-- <button (click)="onCancel()" class="m-2 btn btn-secondary-blue">Cancel</button> -->
            </div>
            <button type="button"
            class="close-btn"
            aria-label="close" (click)="showConsentPopup = false">
            &times;
        </button>
        </div>

    </div>




    <div class="modal fade" id="cookiesettings" tabindex="-1" role="dialog" aria-labelledby="cookiesettings"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h6 class="modal-title h2 fw-bold"><b>Cookie Preference Center</b></h6>
                    <button type="button" class="close" aria-label="close" data-dismiss="modal">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container">
                        <div class="row">
                            <div class="fw-bold col-6">
                                <p>Necessary Cookies</p>
                            </div>
                            <div class="col-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    (click)="showNecessary = !showNecessary" style="cursor: pointer;"
                                    class="m-2 bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                        </div>
                        <div class="row">
                            <p class="col-12" class="fs-6 lh-base" *ngIf="showNecessary">
                                Cookies are necessary for the website to function and cannot be switched off in our
                                systems. They are usually only set in response to actions made by you which amount to a
                                request for services, such as setting your privacy preferences, logging in or filling in
                                forms. These cookies do not store any personally identifiable information.
                            </p>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="fw-bold col-6">
                                <p>Performance Cookies</p>
                            </div>
                            <div class="col-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                    (click)="showPerformance = !showPerformance" style="cursor: pointer;"
                                    fill="currentColor" class="m-2 bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div class="col-3">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches1" />
                                    <label class="custom-control-label" for="customSwitches1"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="col-12" class="fs-6 lh-base" *ngIf="showPerformance">
                                Allow us to count visits and traffic sources so we can measure and improve the
                                performance of our site. They help us to know which pages are the most and least popular
                                and see how visitors move around the site. All information these cookies collect is
                                aggregated and therefore anonymous. If you do not allow these cookies we will not know
                                when you have visited our site, and will not be able to monitor its performance.
                            </p>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="fw-bold col-6">
                                <p>Targetting Cookies</p>
                            </div>
                            <div class="col-2">
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="showTargetting = !showTargetting"
                                    style="cursor: pointer;" width="16" height="16" fill="currentColor"
                                    class="m-2 bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div class="col-3">
                                <div class="custom-control custom-switch w-10 h-10">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches2" />
                                    <label class="custom-control-label" for="customSwitches2"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="col-12" class="fs-6 lh-base" *ngIf="showTargetting">
                                Cookies may be set through our site by our advertising partners. They may be used by
                                those companies to build a profile of your interests and show you relevant adverts on
                                other sites. They do not store directly personal information, but are based on uniquely
                                identifying your browser and internet device. If you do not allow these cookies, you
                                will experience less targeted advertising.
                            </p>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="fw-bold col-6">
                                <p>Functional Cookies</p>
                            </div>
                            <div class="col-2">
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="showFunctional = !showFunctional"
                                    style="cursor: pointer;" width="16" height="16" fill="currentColor"
                                    class="m-2 bi bi-caret-down-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                            </div>
                            <div class="col-3">
                                <div class="custom-control custom-switch w-10 h-10">
                                    <input type="checkbox" class="custom-control-input" id="customSwitches3" />
                                    <label class="custom-control-label" for="customSwitches3"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <p class="col-12" class="fs-6 lh-base" *ngIf="showFunctional">
                                Cookies enable the website to provide enhanced functionality and personalisation. They
                                may be set by us or by third party providers whose services we have added to our pages.
                                If you do not allow these cookies then some or all of these services may not function
                                properly.
                            </p>
                        </div>
                    </div>
                    <br /><br />
                    <div class="d-flex justify-content-end">
                        <button (click)="onAccept()" class="m-2 btn btn-primary" data-dismiss="modal">
                            Confirm my choices
                        </button>
                        <button (click)="onAccept()" class="m-2 btn btn-success" data-dismiss="modal">
                            Allow all
                        </button>
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary-blue" data-bs-dismiss="modal">Close</button> -->
            </div>
        </div>
    </div>
</div>