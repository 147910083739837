import gql from 'graphql-tag';

const BLOG_QUERY2 = gql`
    # query {
    #     blogs(sort: "published_at:DESC") {
    #         title
    #         id
    #         description
    #         published_at
    #         views
    #         content
    #         blogurl
    #         idUrl
    #         dateofpublish
    #         title_image {
    #             url
    #         }
    #     }
    # }

    # V4 STRAPI QUERY
    query {
      blog2S(sort: "publishedAt:DESC") {
        data {
          id
          attributes {
                Title
                Description
                publishedAt
                Blogurl
                idUrl
                Dateofpublish
                Title_image {
                    data {
                        attributes {
                            url
                        }
                    }
                }
          }
        }
  	}
	}
`;

export default BLOG_QUERY2;
