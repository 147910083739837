import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class KlentyService {
  private loaded = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  load() {
    if (isPlatformBrowser(this.platformId) && !this.loaded) {
      const script = document.createElement('script');
      script.innerHTML = `
        window["__kl__tr__Id"]='5ff83e2e4dbf38003e0370de',
        function()
        {
            var t=document.createElement('script');
            t.type='text/javascript',t.async=!0,
            t.src='https://s3-us-west-2.amazonaws.com/kl-website-tracking/klenty_track.js';
            var e=document.getElementsByTagName('script')[0];
            e.parentNode.insertBefore(t,e);
        }();
      `;
      document.body.appendChild(script);
      this.loaded = true;
    }
  }
}