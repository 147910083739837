import { Component, OnDestroy, OnInit } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import MOMENTS_QUERY from 'src/app/queries/moments/moment.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-moments',
    templateUrl: './moments.component.html',
    styleUrls: ['./moments.component.scss']
})
export class MomentsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(private apollo: Apollo, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService,) { }
    momentscaputured: any[] = [];

    loading: boolean = true;
    errors: any;
    carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1000,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 2000,
        },
        loop: true,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    momentsSliderList = [
        {
            id: 1,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/1.png',
        },
        {
            id: 2,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/2.png',
        },
        {
            id: 3,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/3.png',
        },
        {
            id: 4,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/4.png',
        },
        {
            id: 5,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/5.png',
        },
        {
            id: 6,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/6.png',
        },
        {
            id: 7,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/7.png',
        },
        {
            id: 8,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/8.png',
        },
        {
            id: 9,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/9.png',
        },
        {
            id: 10,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/10.png',
        },
        {
            id: 11,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/1.png',
        },
        {
            id: 12,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/2.png',
        },
        {
            id: 13,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/3.png',
        },
        {
            id: 14,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/4.png',
        },
        {
            id: 15,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/5.png',
        },
        {
            id: 16,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/6.png',
        },
        {
            id: 17,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/7.png',
        },
        {
            id: 18,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/8.png',
        },
        {
            id: 19,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/9.png',
        },
        {
            id: 20,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images/moments/10.png',
        },
    ];

    ngOnInit(): void {
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    this.titleService.setTitle(metaTagsData['gallery'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['gallery'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['gallery'].attributes.keywords
                    });
                });
        }
        else {
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['gallery'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['gallery'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['gallery'].attributes.keywords
            });

        }

        // this.titleService.setTitle(' Event Gallery | COSGrid Networks');
        // this.metaService.updateTag({ name: 'description', content: 'Explore a collection of pictures showcasing important events and special occasions from our journey.' });
        // this.metaService.updateTag({ name: 'keywords', content: 'event gallery, memorable moments, special occasions, gallery of events, highlights, cherished memories' });

        this.loading = true;
        this.apollo
            .watchQuery({
                query: MOMENTS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.momentscaputured = (result.data as any).moments.data.map((mom) => ({
                    ...mom,
                    image: mom.attributes.momentimages.data.attributes.url,
                }));
                // console.log(this.momentscaputured);
                this.errors = result.errors;
                this.loading = false;
            });

        //console.log(`Moments: ${this.momentscaputured}`);
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
