import { Injectable, EventEmitter, OnDestroy } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MyEmitterService implements OnDestroy {
    private productEvent = new EventEmitter<any>();
    private componentEvent = new EventEmitter<any>();
    private industriesEvent = new EventEmitter<any>();
    private blogsEvent = new EventEmitter<any>();
    private footerEvent = new EventEmitter<any>();

    emitproductEvent(data: any) {
        this.productEvent.emit(data);
    }
    emitcomponentEvent(data: any) {
        this.componentEvent.emit(data);
    }

    emitIndustriesEvent(data: any) {
        this.industriesEvent.emit(data);
    }

    emitBlogsEvent(data: any) {
        this.blogsEvent.emit(data);
    }

    emitFooterEvent(data: any) {
        this.footerEvent.emit(data);
    }

    getFooterEvent() {
        return this.footerEvent;
    }

    getproductEvent() {
        return this.productEvent;
    }

    getcomponentEvent() {
        return this.componentEvent;
    }

    getindustriesEvent() {
        return this.industriesEvent;
    }

    getBlogsEvent() {
        return this.blogsEvent;
    }

    ngOnDestroy(): void {
        this.productEvent = null
        this.componentEvent = null
        this.industriesEvent = null
        this.blogsEvent = null
        this.footerEvent = null
    }
}
