<section>
    <div>
        <div
            class="row d-flex justify-content-between align-items-center"
            [ngClass]="{
                'flex-row-reverse': imagePosition == '2'
            }"
        >
            <div
                class="col-12"
                [ngClass]="{
                    'col-md-7': leftContentXLCol == '7',
                    'col-md-5': leftContentXLCol == '5',
                    'col-md-6': leftContentXLCol == '6'
                }"
            >
                <div class="h-100">
                    <p class="fw-bold clr-blue-dark h2" [innerHTML]="title">
                    </p>
                    <div style="height: 10px"></div>
                    <p class="fw-bold fs-5" [innerHTML]="subtitle">
                    </p>
                    <div style="height: 0px"></div>
                    <p
                        [ngStyle]="
                            colorStyle == '1'
                                ? {
                                      color: '#3051e9'
                                  }
                                : {
                                      color: 'black'
                                  }
                        "
                        class="d-flex fs-6"
                        *ngFor="let item of data"
                    >
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="23px" height="18px">
                            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                            <path
                                fill="#006d8f"
                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                            />
                        </svg>
                    </span>

                        <span
                            [ngStyle]="
                                colorStyle == '1'
                                    ? {
                                          color: '#3051e9'
                                      }
                                    : {
                                          color: 'black'
                                      }
                            "
                            [innerHTML]="item?.title"
                            ></span
                        >
                        <span
                            [ngStyle]="
                                colorStyle == '1'
                                    ? {
                                          color: 'black'
                                      }
                                    : {
                                          color: '#3051e9'
                                      }
                            "
                            [innerHTML]="(item.desc && item.title  ? ': ' : '') + (item.desc ? item.desc : '')"
                        ></span>
                    </p>
                </div>
            </div>
            <!-- <div class="col" *ngIf="imagePosition == '2'"></div> -->
            <div
                class="col-md-4 col-12 d-flex justify-content-center align-items-center"
                [ngClass]="{
                    'col-md-5': leftContentXLCol == '7' || imagePosition == '2',
                    'col-md-7': leftContentXLCol == '5' && imagePosition != '2'
                }"
            >
                <img [src]="image" alt="benefits" class="w-100 text-center" loading="lazy" alt="benefits Image" />
            </div>
        </div>
    </div>
</section>
