<router-outlet></router-outlet>
<div *ngIf="loading == true; else showblogs"
    style="display: flex; height: 100vh; width: 100vw; margin: 0; padding: 0 0; background-color: #eeeeee;">
    <div style="margin: auto;">
        <img style="display: block;" class="logo-loading" src="assets/images/navbar/fLogo.svg" alt="Logo loading.." />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>

<ng-template #showblogs>
    <section>
        <div class="vw-100 d-flex flex-column justify-content-center"
            [ngStyle]="{'background': 'url(' + backgroundBanner + ') no-repeat center 20% /cover'}"
            style=" height: 65vh; padding-left: 5%;">
            <p class="display-5 fw-bold text-white">COSGrid Blogs</p>
            <p class="fw-bold h3 text-white">Cyber Resiliency Knowledge Hub</p>
        </div>

        <div class="px-lg-5">
            <div class="row mx-auto">
                <div *ngFor="let blog of redirectBlogs"
                    class="col-12 col-md-4 d-flex justify-content-center align-items-center">
                    <div class="cardBlogs m-5 p-3 align-items-center flex-column text-break rounded-lg"
                        style="background-color: rgb(221, 224, 235);">
                        <div class="h-50 d-flex justify-content-center align-items-center mt-3">
                            <img class="imageawards" [src]="blog.image" [alt]="blog?.attributes?.Title"
                                loading="lazy" />
                        </div>
                        <div class="h-40 w-100 pt-5 cursor-pointer">
                            <a [href]="blog?.attributes?.Blogurl" target="_blank" class="text-decoration-none">
                                <p class="text-black fw-bold h5 cursor-pointer">{{ blog.attributes.Title }}</p>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between align-items center">
                            <a [href]="blog?.attributes?.Blogurl" target="_blank" style="cursor: pointer;"
                                class="clr-blue-dark cursor-pointer">
                                <p class="fw-bold">Read More &gt;</p>
                            </a>
                            <small class=" mb-4">{{ blog.attributes.Dateofpublish }}</small>
                        </div>
                    </div>
                </div>
                <div *ngFor="let blog of blogs"
                    class="col-12 col-md-4 d-flex align-items-center justify-content-center">
                    <div class="cardBlogs m-5 p-3 align-items-center flex-column text-break rounded-lg"
                        style="background-color: rgb(221, 224, 235);">
                        <div class="h-50 d-flex justify-content-center align-items-center mt-3">
                            <img class="imageawards" [src]="blog.image" [alt]="blog.attributes.Title" loading="lazy" />
                        </div>
                        <div class="h-40 w-100 pt-5 cursor-pointer">
                            <a (click)="routeToblog(blog)">
                                <p class="fw-bold h5 cursor-pointer">{{ blog.attributes.Title }}</p>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            <a (click)="routeToblog(blog)" style="cursor: pointer;"
                                class="clr-blue-dark  cursor-pointer">
                                <p class="fw-bold">Read More &gt;</p>
                            </a>
                            <small class="mb-4">{{ blog.attributes.Dateofpublish }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-template>