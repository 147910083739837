import * as sdwan from '../../../../core/models/sd-wan.model';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { RouteConstants } from 'src/app/config/route.constants';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { subscribe } from 'graphql';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-sd-wan',
    templateUrl: './sd-wan.component.html',
    styleUrls: ['./sd-wan.component.scss'],
})
export class SdWanComponent implements OnDestroy {
    private queryBlog: Subscription;
    private destroy$ = new Subject<void>()
    title = '19 Important Concepts of SD-WAN ';

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        if (isPlatformBrowser(this.platformId)) {
            const footerHeight = document.querySelector('.footer')?.clientHeight + 115;
            const currScroll = window.pageYOffset + 303;
            const maxScroll = document.body.scrollHeight;
            // console.log('max offset ' + limit);
            if (maxScroll - currScroll <= footerHeight) {
                (document.querySelector('.sidenav') as HTMLElement).style.position = 'absolute';
            } else {
                (document.querySelector('.sidenav') as HTMLElement).style.position = 'fixed';
            }
        }
    }

    section1: sdwan.ISection1 = {
        id: 'what-is-sd-wan',
        title: 'What is SD-WAN ?',
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/Overview%20SD-WAN.webp',
        text: [
            {
                desc: 'Software Defined Wide-Area Networking is a unified WAN architecture that delivers secure and superior application performance through adaptive traffic steering over a combination of multiple WAN transports like',
                points: ['Broadband', 'LTE', 'MPLS'],
            },
            {
                desc: 'Companies are switching to software defined wide area networks (SD-WAN), and are getting popular among corporates around the world. A study from International data corporation predicts that by 2021 SD-WAN market will reach upto USD $8 Billion.',
                points: [],
            },
            {
                desc: '<span class=" text-bold">Software Defined</span> denotes that WAN is Programmable from a Central controller & Orchestrator and an intelligent routing Software is decoupled for the Hardware unlike traditional routers',
                points: [],
            },
            {
                desc: 'SD-WAN has been widely deployed or consumed by enterprise IT on a subscription model (opex model) and hence it is referred to as <span class=" text-bold">Network as a Service.</span>',
                points: [],
            },
        ],
    };

    section1to7: sdwan.ISection1to7[] = [
        {
            id: 'sectio',
            title: 'What is SD-WAN ?',

            text: [
                {
                    desc: 'Software Defined Wide-Area Networking is a unified WAN architecture that delivers secure and superior application performance through adaptive traffic steering over a combination of multiple WAN transports like',
                    points: ['Broadband', 'LTE', 'MPLS'],
                },
                {
                    desc: 'Companies are switching to software defined wide area networks (SD-WAN), and are getting popular among corporates around the world. A study from International data corporation predicts that by 2021 sd-wan market will reach upto USD $8 Billion.',
                    points: [],
                },
                {
                    desc: '<span class=" text-bold">Software Defined</span> denotes that WAN is Programmable from a Central controller & Orchestrator and an intelligent routing Software is decoupled for the Hardware unlike traditional routers',
                    points: [],
                },
                {
                    desc: 'SD-WAN has been widely deployed or consumed by enterprise IT on a subscription model (opex model) and hence it is referred to as <span class=" text-bold">Network as a Service.</span>',
                    points: [],
                },
            ],
        },
        {
            id: 'section2',
            title: 'SD-WAN Context',
            text: [
                {
                    desc: 'Enterprises have been rapidly adopting Cloud, SaaS, IoT and real-time collaboration tools in a big way. According to Cloud Security Alliance, enterprises use average of 464 apps including critical apps.',
                    points: [],
                },
                {
                    desc: "As more and more enterprise embark on their digitalization or digital transformation journey, we're seeing:",
                    points: [
                        'More users & many thin branches and more endpoints (including BYOD and IoT things)',
                        'Increased number of rich applications consuming more and more bandwidth (30% YOY)',
                        'Complex traffic patterns due to higher adoption of hybrid clouds, SaaS and edge architecture',
                        'Increased Attack surface due variety applications deployed, number of variety of WAN link types',
                    ],
                },
            ],
        },
        {
            id: 'section3',
            title: 'SD-WAN Problems ?',
            text: [
                {
                    desc: 'In the above context, enterprises are encountering various day-to-day as well as long recurring problems as the existing WAN architecture based on MPLS VPN has been unable to cope with the new age demands. Some of the key problems has been listed below:',
                    points: [
                        '<strong> Business Continuity </strong> - Poor Network quality & inefficient routing',
                        '<strong> High cost WAN Bandwidth </strong> - Inefficient and costly (<span class=" text-bold">10x more pricing than Broadband</span>)',
                        "<strong> Poor User Experience </strong> - Limited app awareness & Lower Bandwidth for today's rich applications",
                        '<strong> Deployment & Management Complexity </strong> - Integration of Multiple WAN access types & Branch rollout',
                        '<strong> Fragmented Security </strong> - Key challenge considering complex traffic patterns due to Cloud & IoT',
                    ],
                },
            ],
        },
        {
            id: 'section4',
            title: 'Benefits of SD-WAN',
            text: [
                {
                    desc: '',
                    points: [
                        'Edge-to-edge network security and micro-segmentation',
                        'Improved application performance. Provide better application experience regardless of where the application resides Simplify QoS/QoE',
                        'Assure business-critical with advanced application visibility and control',
                        'More Bandwidth for Lower Cost which results in lower OpEx and CapEx',
                        'It improves business agility and responsiveness and reduces complexity',
                        'Highly adaptable to custom and 3rd party products',
                    ],
                },
            ],
        },
        {
            id: 'section5',
            title: 'Why SD-WAN ?',
            text: [
                {
                    desc: 'The traditional WAN model is more centralized in nature, where all the branch traffic is backhauled to the DataCenter from where Internal applications are accessed or Internet breakout happens for externally hosted apps or for accessing Web.',
                    points: [],
                },
                {
                    desc: 'The above scenario has been working fine. But now with a higher number of applications hosted as SaaS and cloud, the performance degradation happens due to higher delays and packet drop rates. This scenario is called a traffic trombone.',
                    points: [
                        'Able to leverage multiple transports',
                        'Software defined means programmable and also indicates the software decoupled from hardware',
                        'SD-WAN leads to better steering of application traffic for improved SLA (Service Level Agreement)',
                        'SD-WAN is also a consumption model (Network as a Service)',
                    ],
                },
            ],
        },
        {
            id: 'section6',
            title: 'Why and when MPLS VPN deployed?',
            text: [
                {
                    desc: 'It matters when:',
                    points: [
                        'Business applications such as ERP, Citrix, VoIP, Video NEED Predictable and quality network services',
                        'Secure Connectivity among Multiple branch offices - Users, Systems & applications',
                        'Financial impact of poor WAN quality or down time more in Consumer businesses or org. that deal with higher volume of transactions.',
                    ],
                },
                {
                    desc: 'Hence, subscribe to MPLS VPNs / Leased Lines from telcos for Performance & Security with SLA.',
                    points: [],
                },
            ],
        },
        {
            id: 'section7',
            title: 'Industry Standards for SD-WAN',
            text: [
                {
                    desc: 'No industry standard definition exists for an SD-WAN. SD-WAN implementations have incorporated WAN technologies and functions that have been developed over the years such as VPN, WAN Optimization, IPsec tunneling, hybrid WAN, deep packet inspection, policy management, QoS performance monitoring and analytics while incorporating newer SDN, NFV, and Service Orchestration technologies. The latter three technologies provide the integration and service deployment automation that has made SD-WAN Managed Services so compelling. SD-WAN Managed Services are a specific use case for a MEF Third Network service using overlay networking technologies to deliver agile, assured and orchestrated application-driven connectivity services.',
                    points: [],
                },
                {
                    desc: '<span class=" text-bold  text-boldbolder">MEF Definition</span><br>The SD-WAN Service Attributes and Services (MEF 70) describes requirements for an application-aware, over-the-top WAN connectivity service that uses policies to determine how application flows are directed over multiple underlay networks irrespective of the underlay technologies or service providers who deliver them."',
                    points: [],
                },
            ],
        },
    ];
    section2: sdwan.ISection2 = {
        id: 'sd-wan-context',
        title: 'SD-WAN Context',
        text: [
            {
                desc: 'Enterprises have been rapidly adopting Cloud, SaaS, IoT and real-time collaboration tools in a big way. According to Cloud Security Alliance, enterprises use average of 464 apps including critical apps.',
                points: [],
            },
            {
                desc: "As more and more enterprise embark on their digitalization or digital transformation journey, we're seeing:",
                points: [
                    'More users & many thin branches and more endpoints (including BYOD and IoT things)',
                    'Increased number of rich applications consuming more and more bandwidth (30% YOY)',
                    'Complex traffic patterns due to higher adoption of hybrid clouds, SaaS and edge architecture',
                    'Increased Attack surface due variety applications deployed, number of variety of WAN link types',
                ],
            },
        ],
    };
    section3: sdwan.ISection3 = {
        id: 'sd-wan-problems',
        title: 'SD-WAN Problems ?',
        desc: 'In the above context, enterprises are encountering various day-to-day as well as long recurring problems as the existing WAN architecture based on MPLS VPN has been unable to cope with the new age demands. Some of the key problems has been listed below:',
        problems: [
            {
                title: 'Business Continuity ',
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/devops_u7mwq3hk39y8.svg',
                text: 'Poor Network quality & inefficient routing',
            },
            {
                title: 'High cost WAN Bandwidth ',
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/wifi_18o5dwdw25ft.svg',
                text: 'Inefficient and costly (10x more pricing than Broadband)',
            },
            {
                title: ' Poor User Experience ',
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/user_experience_4h7j89g3l3ay.svg',
                text: "Limited app awareness & Lower Bandwidth for today's rich applications",
            },
            {
                title: ' Deployment & Management Complexity ',
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/product_6wr1icr4k9um.svg',
                text: 'Integration of Multiple WAN access types & Branch rollout',
            },
            {
                title: ' Fragmented Security',
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/secured_itk3t2wt9ofo.svg',
                text: 'Key challenge considering complex traffic patterns due to Cloud & IoT',
            },
        ],
        // card1:[{
        //     title1:'<strong> Business Continuity </strong>',
        //     imgURL1:'https://cdn.cosgrid.com/website/assets/images/sd-wan/devops_u7mwq3hk39y8.svg',

        //     points1:'Poor Network quality & inefficient routing',
        // }],
        // card2:[{title2:'<strong> High cost WAN Bandwidth </strong>',
        //     imgURL2:'https://cdn.cosgrid.com/website/assets/images/sd-wan/wifi_18o5dwdw25ft.svg',
        //     points2:'Inefficient and costly (<span class=" text-bold">10x more pricing than Broadband</span>)',

        // }],
        // card3:[{title3:'<strong> Poor User Experience </strong>',
        //     imgURL3:'https://cdn.cosgrid.com/website/assets/images/sd-wan/user_experience_4h7j89g3l3ay.svg',
        //     points3:"Limited app awareness & Lower Bandwidth for today's rich applications",

        // }],
        // card4:[{title4:'<strong> Deployment & Management Complexity </strong>',
        //     imgURL4: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/product_6wr1icr4k9um.svg',
        //     points4:'Integration of Multiple WAN access types & Branch rollout',

        // }],
        // card5:[{title5:'<strong> Fragmented Security </strong>',
        //     imgURL5:'https://cdn.cosgrid.com/website/assets/images/sd-wan/secured_itk3t2wt9ofo.svg',
        //     points5:'Key challenge considering complex traffic patterns due to Cloud & IoT',

        // }],
    };
    section4: sdwan.Isection4 = {
        id: 'benefits-of-sd-wan',
        title: 'Benefits of SD-WAN',
        card: [
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/network_titztip0o5po.svg',
                points: 'Edge-to-edge network security and micro-segmentation',
            },
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/application_na1ocx6wr1k6.svg',
                points: 'Improved application performance. Provide better application experience regardless of where the application resides Simplify QoS/QoE',
            },
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/critical_thinking_15os8908giei.svg',
                points: 'Assure business-critical with advanced application visibility and control',
            },
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/wifi_signal_z05vlg1vxcwd.svg',
                points: 'More Bandwidth for Lower Cost which results in lower OpEx and CapEx',
            },
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/iteration_oxe8a07h1ala.svg',
                points: 'It improves business agility and responsiveness and reduces complexity',
            },
            {
                imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/adaptation_65tiyeb8pv5u.svg',
                points: 'Highly adaptable to custom and 3rd party products',
            },
        ],
        // card2:[{
        //     imgURL2:'https://cdn.cosgrid.com/website/assets/images/sd-wan/application_na1ocx6wr1k6.svg',
        //     points2:'Improved application performance. Provide better application experience regardless of where the application resides Simplify QoS/QoE',

        // }],
        // card3:[{
        //     imgURL3:'https://cdn.cosgrid.com/website/assets/images/sd-wan/critical_thinking_15os8908giei.svg',
        //     points3:'Assure business-critical with advanced application visibility and control',

        // }],
        // card4:[{
        //     imgURL4: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/wifi_signal_z05vlg1vxcwd.svg',
        //     points4:'More Bandwidth for Lower Cost which results in lower OpEx and CapEx',

        // }],
        // card5:[{
        //     imgURL5:'https://cdn.cosgrid.com/website/assets/images/sd-wan/iteration_oxe8a07h1ala.svg',
        //     points5:'It improves business agility and responsiveness and reduces complexity',

        // }],
        // card6:[{
        //     imgURL6: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/adaptation_65tiyeb8pv5u.svg',
        //     points6: 'Highly adaptable to custom and 3rd party products',

        // }],
    };
    section5: sdwan.Isection5 = {
        id: 'why-sd-wan',
        title: 'Why SD-WAN ?',
        text: [
            {
                desc: 'The traditional WAN model is more centralized in nature, where all the branch traffic is backhauled to the DataCenter from where Internal applications are accessed or Internet breakout happens for externally hosted apps or for accessing Web.',
                points: [],
            },
            {
                desc: 'The above scenario has been working fine. But now with a higher number of applications hosted as SaaS and cloud, the performance degradation happens due to higher delays and packet drop rates. This scenario is called a traffic trombone.',
                points: [
                    'Able to leverage multiple transports',
                    'Software defined means programmable and also indicates the software decoupled from hardware',
                    'SD-WAN leads to better steering of application traffic for improved SLA (Service Level Agreement)',
                    'SD-WAN is also a consumption model (Network as a Service)',
                ],
            },
        ],
    };

    section6to7: sdwan.ISection6to7[] = [
        {
            id: 'why-and-when-mpls-vpn-deployed',
            title: 'Why and when MPLS VPN deployed?',
            text: [
                {
                    desc: 'It matters when:',
                    points: [
                        'Business applications such as ERP, Citrix, VoIP, Video NEED Predictable and quality network services',
                        'Secure Connectivity among Multiple branch offices - Users, Systems & applications',
                        'Financial impact of poor WAN quality or down time more in Consumer businesses or org. that deal with higher volume of transactions.',
                    ],
                },
                {
                    desc: 'Hence, subscribe to MPLS VPNs / Leased Lines from telcos for Performance & Security with SLA.',
                    points: [],
                },
            ],
        },
        {
            id: 'industry-standards-for-sd-wan',
            title: 'Industry Standards for SD-WAN',
            text: [
                {
                    desc: 'No industry standard definition exists for an SD-WAN. SD-WAN implementations have incorporated WAN technologies and functions that have been developed over the years such as VPN, WAN Optimization, IPsec tunneling, hybrid WAN, deep packet inspection, policy management, QoS performance monitoring and analytics while incorporating newer SDN, NFV, and Service Orchestration technologies. The latter three technologies provide the integration and service deployment automation that has made SD-WAN Managed Services so compelling. SD-WAN Managed Services are a specific use case for a MEF Third Network service using overlay networking technologies to deliver agile, assured and orchestrated application-driven connectivity services.',
                    points: [],
                },
                {
                    desc: '<span class=" text-bold  text-boldbolder">MEF Definition</span><br>The SD-WAN Service Attributes and Services (MEF 70) describes requirements for an application-aware, over-the-top WAN connectivity service that uses policies to determine how application flows are directed over multiple underlay networks irrespective of the underlay technologies or service providers who deliver them."',
                    points: [],
                },
            ],
        },
    ];
    section_8: sdwan.ISection8 = {
        id: 'sd-wan-architecture-how-it-works',
        title: 'SD-WAN Architecture & How it Works',
        text: 'The core feature of the SD-WAN is the intelligent packet steering of outgoing IPv4/IPv6 packets across one or more WAN links considering real-time WAN congestion status, state of encrypted tunnels, QoS and application based routing policies dictated by the SD-WAN orchestrator.',
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/How%20SD-WAN%20works.png',
        part_one: {
            start: 'Components of SD-WAN architectures as below:',
            points: [
                '<span class=" text-bold">Centralized Orchestrator</span> (typically cloud based & Multi-tenant) Centralized management of SD-WAN edges & gateway',
                '<span class=" text-bold">SD-WAN Edge</span> with zero or low touch provisioning (deployed in branch premises)',
                '<span class=" text-bold">Cloud services Gateway</span> (Optional - Multi-tenant Services Edge)',
            ],
            end: 'SD-WAN Orchestrator has a set of predefined policies for not only packet forwarding but also for many services such as DHCP, Firewall / NAT, Syslog etc which the SD-WAN devices download as configuration and initiate the services. This includes Load Balancing & failover over WAN links for internet traffic to creation of encrypted site to site tunnels to DC or other branches. This optimized packet forwarding with leveraging multiple WAN transport leads increased uptimes, better SLA and higher bandwidth per branch.',
        },
        part_two: [
            {
                heading: 'SD-WAN Device On-Boarding',
                points: [
                    'Each SD-WAN device is assigned a unique device ID (Serial No) and secret key by SD-WAN Orchestrator.',
                    'SD-WAN Device registers with the above Device ID, secret key and Device CPU related fingerprints for stored security reasons.',
                    'After authenticating the devices, a unique RSA certificate is generated for the device based on which it establishes a management tunnel with SD-WAN Orchestrator & Controller.',
                    'All the SD-WAN packet steering, encrypted tunnel & QoS policies are pushed to the SD-WAn device immediately after registration.',
                ],
            },
            {
                heading: 'Key SD-WAN Features',
                points: [
                    'Unified Transport - Multiple Active WAN links with link quality intelligence.',
                    'Smart traffic steering (Application based routing & Multi-path Bonding).',
                    'Integrated Security (Scalable VPN tunnels and simple UTM functionalities)',
                    'Centralized orchestration of networks for simplified network Ops',
                ],
            },
        ],
        part_three: [
            '<span class=" text-bold">SD-WAN Edge -</span> Physical or virtual',
            '<span class=" text-bold">SD-WAN Controller -</span> Centralized management of SD-WAN edges & gateways',
            '<span class=" text-bold">Service Orchestrator -</span> Lifecycle Service Orchestration of SD-WAN and other services',
            '<span class=" text-bold">Subscriber Web Portal -</span> Subscriber service ordering and modification',
        ],
    };

    section_9: sdwan.ISection9 = {
        id: 'sd-wan-or-traditional-wan',
        title: 'SD-WAN or Traditional WAN ?',
        start: 'The SD-WAN market is growing continuously and in a recent study from Forrester reveals that more than 64% of us companies are planning to switch to sd-wan and services, though using traditional wan services is still a reliable medium to receive and deliver network services. In many cases it has been seen a hybrid approach is more effective and beneficial, which uses both of these techniques to deliver maximum value.',
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/SD-WAN%20vs%20Traditional%20WAN.jpg',
        blocks: [
            '<span class=" text-bold">Requirements of services changes from companies</span> to companies and even vary with situations, and hence there is no hard and fast rule to select one, Let’s dig into it and understand both of them and see which is suitable for your company.',
            'Traditional wan technology as the name suggests has been in use from long time, IT, data network infrastructure, video and voice.',
            'Traditional wan uses routers and virtual private network (VPN) to secure a connection between multiple local area networks (LAN), organizations with multiple branches in different locations use this feature.',
            'To get resilient and efficient network traffic flow traditional wan uses multiprotocol label switching (MPLS). This allows a feature in which <span class=" text-bold">companies can prioritize video, voice or data or their network.</span>',
            'SD-WAN is a new technology in wide area networking, since it combines the features of wan technology, like MPLS and broadband to secure the connection between multiple branches.',
            'With this networking capabilities are enhanced like control and flexibility while reducing the networking costs. Now companies can even connect branch offices present in remote locations. With this organisation can connect all their branches to the central network, in the cloud.',
            'Traditional wan works on MPLS which offer great quality of service, <span class=" text-bold">but MPLS bandwidth are more expensive.</span> While in case of sd-wan, which can work on 4G LTE and broadband, which reduces the internet cost significantly.',
            'Traditional wan works on a single carrier (most of the time), avoiding packet loss since it virtually isolates the packets and here you can prioritize your traffic as per importance to ensure reliability. While in case of sd-wan you have multiple options, and can choose your important work to transfer on the best network, to ensure minimal packet loss.',
            'Traditional wan uses branch to branch secure communication using MPLS connections, where packets sent are private and can only be accessed at the destination. While SD-WAN uses end to end encryption using VPN connection. With this feature companies can increase more security layers, like firewalls to reduce any chance of threat.',
            'Both are secure but when it comes to scalability and changes, traditional wan reduces the efficiency with every new branch, since it requires manual connection, which takes a long time. While in case of sd-wan this is not the case, it is highly scalable.',
        ],
        end: 'Hence, the decision to choose any one depends on the organization and its requirements. While choosing a company should focus on short milestones and long term goals.',
    };

    section_10: sdwan.ISection10 = {
        id: 'how-does-sd-wan-provide-you-network-security',
        title: 'How does SD-WAN Provide you Network Security?',
        blocks: [
            {
                text: 'Most SD-WAN deploying offers an approach to encrypt your branch-to-branch corporate traffic-utilizing:',
                points: ['IPsec', 'VPN Tunnels', 'Firewalls', 'Microsegmentation of Application Traffic'],
            },
            {
                text: "Which secures the information within your network. Since nowadays almost every SD-WAN vendor offers IP security (IPsec), it's regular reasoning that SD-WANs are inherently secure. The facts demonstrate that IPsec handles ensuring the information as it moves from the system.",
                points: [],
            },
        ],
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/How%20SD-WAN%20provides%20Network%20Security.png',
        points: [
            'Enhancing agility while reducing Opex cost between your branch offices is one of the features of software defined wans (SDWAN).',
            'But what about security? Organisations can not compromise on security over lower CapEx.',
            'Today organisations need a solution to virtualise the security network function so that to tackle rapidly evolving security threats while keeping a control over Capex of updating and installing new hardware.',
            'SD-WAN uses virtual machines, which helps organisations to <span class=" text-bold">install updates on existing hardwares, rather than doing it manually which incurs CapEx and time.</span>',
        ],
    };

    section_11: sdwan.ISection11 = {
        id: 'what-is-sdn-and-how-it-differs-from-sd-wan',
        title: 'What is SDN and how it differs from SD-WAN ?',
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/SD-WAN%20vs%20SDN.png',
        t_header: {
            attribute: 'Attribute',
            sdn: 'SDN',
            sd_wan: 'SD-WAN',
        },
        t_body: [
            {
                attribute: 'Deployment Objective',
                sdn: [
                    'Agile delivery of dynamic network services in Data centers.',
                    '<span class=" text-bold">Network Optimization is Key</span>',
                ],
                sd_wan: [
                    'Delivery of hugely improved & predictable branch WAN performance & uptime',
                    '<span class=" text-bold">User experience is key</span>',
                ],
            },
            {
                attribute: 'Key Value Drivers',
                sdn: [
                    '<span class=" text-bold">Disaggregation :</span> Leverage Commodity white boxes – Commercial Off The Shelf (COTS) hardware – rather than costly proprietary ones. Hardware decoupled from routing software - Flexible deployment, Better scaling & ROI',
                    'Optimized networks through centralized control & orchestration',
                ],
                sd_wan: [
                    '<span class=" text-bold">Aggregation :</span> Intelligently leverage multiple (low-cost) WAN transport for lower Opex rather than expensive MPLS from service providers and increased bandwidth scaling',
                    'Optimized WAN through centralized control & orchestration',
                ],
            },
            {
                attribute: 'Architecture Impact',
                sdn: ['Primarily a new approach, new industry technology as industry standards'],
                sd_wan: ['SD-WAN is an integrated solution'],
            },
            {
                attribute: 'Supplementary Benefits',
                sdn: ['Reduces dependencies on Proprietary Network systems vendors; No vendor Lock-ins'],
                sd_wan: [
                    'Reduces dependencies on carriers / service providers and gives more control to Enterprises customers',
                ],
            },
            {
                attribute: 'Focus Features',
                sdn: [
                    'Intelligent & programmable flow control',
                    'Works with distributed vSwitches in the Compute node too.',
                ],
                sd_wan: [
                    'Dynamic & Multi-path Traffic forwarding based on Application policy, WAN availability & link quality',
                    'Includes a lot of Network Edge functionalities such as Firewall, NAT, DHCP and other network services',
                ],
            },
            {
                attribute: 'HW Platforms',
                sdn: ['Commodity x86 and specialized switching hardware'],
                sd_wan: ['Commodity x86 and off-the shelf ARM & MIPS whiteboxes'],
            },
        ],
    };

    section_12: sdwan.ISection12 = {
        id: 'sd-wan-vs-mpls',
        title: 'SD-WAN vs MPLS',
        text: 'Software defined wide area network (SD-WAN) is a recent concept which provide companies the benefits of <span class=" text-bold">Software Defined Network (SDN)</span> over hardware based network, but before this we were using Multiprotocol Label Switching (MPLS), which is a protocol to setup a reliable connection between two or more locations.',
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/SD-WAN%20vs%20MPLS.png',
        blocks: [
            "<span class=' text-bold  text-boldbold'>MPLS Advantages and disadvantages<br></span>When it comes to MPLS the most important feature is the reliability in delivery of packets. The quality of service is e xcellent, preventing packet loss and ensuring the business's most important traffic flowing.",
            'MPLS reliability is because of the isolation of packets and providers can even give high priority to any specific network traffic.',
            '<span class=" text-bold">The main drawback of MPLS is the high bandwidth cost.</span> Today the world is going digital and hence the media as well, more and more people are adopting virtual reality (VR), videos, graphics, etc. which demands high network uses, and fulfilling this requirement with mpls is costly. Also, MPLS does not provide built-in data protection, that means the network gets open when implemented incorrectly and hence results in network vulnerability.',
            '<span class=" text-bold  text-boldbold">SD-WAN Advantages and disadvantages</br></span>There are several benefits of SD-WAN over MPLS, involving no boundaries of scalability, visibility, performance and control.',
            'SD-WAN changed the entire enterprise networking system. With SD-WAN there is <span class=" text-bold">no limit</span>, there are <span class=" text-bold">no bandwidth penalties</span> and no need to manually change or optimize the network, now companies can change just by adding new links.',
            'It is <span class=" text-bold">highly cost effective</span>, can even <span class="">mix and match networks</span> as per company priority. MPLS is costly when compared to broadband and 4F LTE, which allows companies to switch on them instead of spending more on MPLS.',
            'SD-WAN helps to establish a unified secure connection covering security, orchestrations and policies. According to the <a href="https://www.businesswire.com/news/home/20170727005919/en/IDC-Forecasts-SD-WAN-Market-Reach-8-Billion" class="text-blue">International Data Corporation (IDC)</a>, the demand of sd-wan is increasing for varieties of reasons. Though we can not simply ignore MPLS, it will always remain in business, companies to see the advantages and disadvantages of both before choosing one.',
        ],
    };

    section_13: sdwan.ISection13 = {
        id: 'sd-wan-vs-vpn',
        title: 'SD-WAN vs VPN',
        text: `SD-WAN offers <span class=' text-bold  text-boldbold'>“self-healing”</span> property as well, as compared to <a routerLink="/solutions" class="text-blue">VPN. It automatically repairs any outages occurring across the network of data centers, cloud resources, and branch offices. SD-WAN converges networking and security infrastructure thus ensuring automatic alignment as network topology changes.`,
        imgURL: 'https://cdn.cosgrid.com/website/assets3/Resources/SD%20WAN%20Concepts/vpn%20features.png',
        texts: [
            'As elegantly explained by a reddit user:',
            '<span class=" text-bold fst-italic  text-boldbolder">“VPN is like a manual gearbox in your car. SD-WAN is like an automatic gearbox with radar-guided cruise control. Both achieve the same result (connectivity between 2 remote locations), but SD-WAN hides away from you lots of complexity with building redundant tunnels, monitoring the quality of the tunnels and failing over.”</span>',
            'Ultimately, SD-WAN is a collection of technologies, packaged together with a nice GUI that make managing VPN connectivity between remote locations MUCH easier.',
            'SD-WAN leverages multiple active WAN links / Paths and delivers secure & superior connectivity between branches/DC/Cloud through directly programmable data plane',
            '<span class="text-bold">What are broad set of SD-WAN features supported by vendors ?</span>',
        ],
        blocks: [
            {
                heading: 'Core SD-WAN Features :',
                points: [
                    'Dynamic Traffic Steering based on policy, application, availability, performance',
                    'Multi-path traffic forwarding / ECMP',
                    'Overlays / tunnels for segmentation',
                    'Network / Application visibility',
                ],
            },
            {
                heading: 'Extended SD-WAN Features :',
                points: [
                    'Stateful firewall & Security Technologies',
                    'Network Acceleration & Optimization',
                    'NFV & Service Chaining',
                    'Integrated Service Assurance',
                ],
            },
        ],
    };

    section_14: sdwan.ISection14 = {
        id: 'sd-wan-vs-dmvpn',
        title: 'SD-WAN vs DMVPN',
        blocks: [
            'Dynamic Multipoint VPN is an evolution of IPSec tunnels which is primarily site to site tunnels. For IPSec tunnels, both the client and the IPSec Gateway have to be individually configured for secure communications.',
            `This configuration has to be repeated with each IPSec client & at the Gateway added to the VPN. This is more time consuming, cumber-some and error-prone. Also, <span class=" text-bold">VPN tunnels don't scale.</span>`,
            `DMVPN leverages new features such as NHRP, mGRE along with IPSec to dynamically create mesh VPN to other spokes.`,
        ],
    };

    section_15: sdwan.ISection15 = {
        id: 'sd-wan-vs-wan-optimization',
        title: 'SD-WAN vs WAN Optimization',
        blocks: [
            'Deduplication, where the WAN substitutes a kind of shorthand for well-known data patterns to avoid having to send the actual data across the link. Basic quality-of-service (QoS) to prioritize traffic from some applications over others. Blocking to prevent unwanted traffic from consuming valuable bandwidth. Forward error correction (FEC) to account for packet loss.',
            'SD-WAN and wan optimization are <span class=" text-bold">two similar technologies</span> and we can see them as a successor and a legend in the same field. Both SD-WAN and wan are capable of doing wonders alone, and even better when, together.',
            'As the name suggests optimizing the wan involves effectively using the limited bandwidth available using deduplication, compression, reduced latency and many more. <span class=" text-bold">Think of wan optimization as customizing your car, not buying a new one.</span>',
            'Unlike wan optimization which does not delay sensitive traffic systems, sd-wan is pre inclined towards real-time networking, accounting for jitter, latency and packet loss to check traffic is at optimal conditions across your wan.',
            `It can surprisingly increase your organization's visibility into private or public (or both) applications, to provide you more control and ensure resilience and agility.`,
        ],
    };

    section_16: sdwan.ISection16 = {
        id: 'difference-between-lan-and-wan',
        title: 'Difference between LAN and WAN',
        blocks: [
            'Local area networks (LAN) establish a connection between groups of computer and network devices, mostly within the s ame branch/location. It facilitates high-speed interconnection with the inexpensive installation. LAN can be referred to as an ethernet or token ring system generally used by schools, laboratories, office buildings, etc. to share files like prints, documents, and games.',
            'Metropolitan area network (MAN), helps to establish connections between multiple buildings and offices within a small premise, city or town.',
            'Wide area network (WAN), helps to connect several LANs together over a large geographical area. It is mostly installed in organizations and enterprises.',
        ],
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/LAN vs WAN.png',
        t_header: {
            lan: 'LAN',
            wan: 'WAN',
        },
        t_body: [
            {
                lan: 'When it comes to connectivity technologies are limited, using ethernet and token systems',
                wan: 'X.25 and frame relays are used for connectivity over the large geography',
            },
            {
                lan: 'Higher data transfer rate',
                wan: 'Lower data transfer rate',
            },
            {
                lan: 'Private ownership and high speed',
                wan: 'Private or public ownership, and comparatively low speed',
            },
            {
                lan: 'Setup and operational cost are very low',
                wan: 'The setup cost is higher while connecting the remote locations',
            },
            {
                lan: 'Propagation delay is short',
                wan: 'Comparatively high compared to LAN',
            },
            {
                lan: 'Tolerance to any possible fault is low',
                wan: 'Less tolerant to a fault',
            },
            {
                lan: 'Design and maintenance is easy',
                wan: 'Design and maintenance is easy',
            },
        ],
    };

    section_17: sdwan.ISection17 = {
        id: 'why-does-your-company-need-sd-wan',
        title: 'Why does your company need SD-WAN ?',
        start: 'Switching to sd-wan (software-defined wide area network) <span>allows organizations to connect all their network supplies and visibilities</span>. This allows organizations to use their cloud-based technology, with no or minimal expensive hardware. Thus, it helps businesses to decrease CapEx and Opex.',
        blocks: {
            text: 'SD-WAN for organization provides :',
            points: [
                'Integrated security function',
                'Virtual overlay network',
                'Application and network activity monitoring',
                'Control and management infrastructure',
            ],
        },
        end: 'In addition to reduced cost, it provides flexibility and agility since now connectivity is through a cloud that allows organizations to have control over scaling up the bandwidth and operation.',
    };

    section_18: sdwan.ISection18 = {
        id: 'what-you-need-to-consider-before-choosing-your-sd-wan-vendor',
        title: 'What you need to consider before choosing your new SD-WAN Vendor?',
        start: 'There are some important questions that you should consider before selecting your vendor. You must ensure that the vendor can fulfill your current needs and future needs as well.',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/sd-wan/sd-wan_section-18.png',
        blocks: {
            text: 'These are the questions you should ask :',
            points: [
                'Does the vendor guarantee you predictable performance of your application platforms?',
                'Can your vendor support new and customized applications?',
                'Is the SD-WAN compatible with integrated wan optimization at various company branches?',
                'How will the connection interact with current routers, gateways, and firewalls?',
                'Does your vendor provide you with a centralized orchestration console, for your organizational use in managing application QoS and network security?',
            ],
        },
        quotes: '<em>Carefully analyze these questions and what the vendor offers.</em>',
    };

    section_19: sdwan.ISection19 = {
        id: 'what-are-sd-wan-key-takeaways',
        title: 'What are SD-WAN key Takeaways ?',
        points: [
            'SD-WAN is a wide area network with virtual connectivity, which uses software and minimal use of hardware',
            'Its architecture works cloud-based, which makes it highly suitable for scaling up',
            'Since hardware uses are minimum which led to low CapEx and high ROI',
            'Highly simplified network connectivity, resilient, and agile service',
        ],
        quotes: `<em>We are here with you to help what suits your organization talk to us <a href="${RouteConstants.CONTACTS}" class="text-blue">@CosgridSupport</a></em>`,
    };
    // FAQ
    faq: sdwan.IFAQ[] = [
        {
            question: '1. Is SD-WAN better than MPLS?',
            ans: [
                'SD-WAN has high scalability while keeping low CapEx and Opex since it uses multiple network channels to enhance the bandwidth when needed. On the other hand, MPLS is a more secure option for branch connectivity, scalability is low and high CapEx and Opex.',
                'So, if your organization is looking for expansion in the future and want to keep the CapEx low, It is better, and if you know your organization will stay the same and no new branches will be planned in future, then MPLS can be an option.',
            ],
        },
        {
            question: '2. Difference between WAN and SD-WAN?',
            ans: [
                'SD-WAN and WAN optimization are two similar technologies and we can see them as a successor and a legend in the same field. Both are capable of doing wonders alone, and even better when, together.',
            ],
        },
        {
            question: '3. What is SD-WAN used for?',
            ans: [
                'SD-WAN is used to connect multiple branch offices in large geography using multiple networking channels, like 4G LTE, Broadband, and MPLS.',
            ],
        },
        {
            question: '4. When to use SD-WAN?',
            ans: [
                'If your organization is planning to open multiple branches and your requirements are efficient, seamless, and highly scalable connectivity, then you should use sd-wan. Other perks of using it include low CapEx and OpEx.',
            ],
        },
        {
            question: '5. What is the difference between LAN and WAN?',
            ans: [
                'LAN helps to establish a connection between multiple computers and devices but mostly within the same branch, WAN helps to connect multiple branches together covering larger geography.',
            ],
        },
    ];
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
        @Inject(PLATFORM_ID) private platformId: object
    ) { }
    ngOnint() {
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (var i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['sd-wan'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['sd-wan'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['sd-wan'].attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['sd-wan'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['sd-wan'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['sd-wan'].attributes.keywords
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
