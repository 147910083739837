<!-- <div style="height: 10vh"></div> -->
<div class="container-fluid m-3 p-3 mt-5">
    <div class="row" style="padding-right: 30px">
        <div class="col-md-3 mt-5">
            <p class="clr-blue-dark fw-bold h3">List of Categories</p>
            <br />
            <div class="list-group text-strong">
                <button
                    (click)="display('Zero Trust Network Access')"
                    class="list-group-item fs-6 fw-bold list-group-item-action text-blue"
                    aria-current="true"
                >
                    Zero Trust Network Access
                </button>
            </div>
            <div class="list-group text-strong">
                <button
                    (click)="display('Secure SD-WAN')"
                    class="list-group-item fs-6 fw-bold list-group-item-action text-blue"
                    aria-current="true"
                >
                    Secure SD-WAN
                </button>
            </div>
            <div class="list-group text-strong">
                <button
                    (click)="display('Network Detection Response')"
                    class="list-group-item fs-6 fw-bold list-group-item-action text-blue"
                    aria-current="true"
                >
                    Network Detection Response
                </button>
            </div>
        </div>
        <div class="col-md-9">
            <div class="mt-5 pt-md-0 text-center text-md-left">
                <p class="fw-bold clr-blue-dark mb-3 h4">{{ selected_item.category_name }}</p>
                <hr />
                <p class="fw-bold clr-blue-dark mb-3 h5">{{ selected_item.title }}</p>
                <div *ngFor="let i of selected_item.faq" type="1" class="lh-lg ml-0 md-5">
                    <p *ngIf="i.heading" class="h4 fw-bold mt-4 mb-2">{{i.heading}}</p>
                    <p *ngIf="!i.heading" class="fw-bold clr-blue-dark text-left">{{ i.question }}</p>
                    <div *ngIf="!i.heading" class="mt-3 text-justify">
                        <p *ngFor="let p of i.ans" class="fs-6" [innerHTML]="p"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
