<div class="row mt-3 text-align-center">
    <div class="row">
        <div
            class="col-6 col-sm-4 tableSingleCell"
            [class]="tableSingleCellDynamicClass"
            [ngClass]="{
                'col-lg-4':xlGridsInARow==3,
                'col-lg-3':xlGridsInARow==4,
                'row2': totalRowCount == 2 && xlGridsInARow == 3,
                'row3': totalRowCount == 3
            }"
            *ngFor="let item of data; index as i"
        >
            <div class="d-flex flex-column justify-content-center align-items-center my-3 px-2 w-100">
                <img src="{{baseUrl}}{{ item.image }}.{{imageExtension}}" height="60px" loading="lazy" [alt]="item.image"/>
                <br *ngIf="spaceBtnTitleNImage" />
                <div *ngIf="!spaceBtnTitleNImage" style="height: 0.313rem"></div>
                <p
                    class="text-center"
                    [ngClass]="{
                    'fs-6':!item.hasOwnProperty('desc'),
                    'fs-5 fw-bold ':item.hasOwnProperty('desc')
                    
                }"
                    [innerHTML]="item?.title"
                ></p>
                <!-- <br *ngIf="item.hasOwnProperty('desc')" /> -->
                <p *ngIf="item.hasOwnProperty('desc')" class="fs-6 text-center" [innerHTML]="item.desc"></p>
            </div>
        </div>
    </div>
</div>
