import gql from 'graphql-tag';


const META_QUERY = gql`
    # STRAPI V3 - QUERY
    # query {
    #     newsInfos(sort: "eventDate:DESC") {
    #         title
    #         misc
    #         description
    #         title_image {
    #             url
    #         }
    #     }
    # }

    # V4 QUERY 
    query {
        metaTags(pagination : {limit : 50} ) {
          data {
            id
            attributes {
              title
              description
              keywords
              URL
              URL_ID
            }
          }
        }
}
    
`;

export default META_QUERY;
