import { MyEmitterService } from './../../app-emitter.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import IRouteLinkItem, { Col2, INavItem, ProductSolution, Resources } from 'src/app/core/models/route.model';
import { RouteConstants } from '../../config/route.constants';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
// import { CompanyprofilepopupComponent } from '../../pages/companyprofilepopup/companyprofilepopup.component';
// import { FlyerpopupComponent } from 'src/app/pages/flyerpopup/flyerpopup.component';
import { USECASES } from 'src/app/data/usecases';
import { NguCarouselConfig } from '@ngu/carousel';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'mobile-app-navbar',
    templateUrl: './mobilenav.component.html',
    styleUrls: ['./mobilenav.component.scss'],
})
export class MobileNavbarComponent {
    carouselSliderList = [
        '14 days Free Trail - <a href="https://cosgrid.net/auth/social-signup" target="_blank" class="nav-top-a" >Avail Now</a> - No Card Required',
        'Assisting your cybersecurity efforts- Talk to our team to understand your Cyber resiliency Standpoint',
        'Why Basic Soc2 /ISO Compliance and MFA not enough for cybersecurity - <a href="https://cdn.cosgrid.com/website/strapi/soc2%20mfa%20ZTNA%20report%20-COSGrid__b8929bdd-a7e3-4e8c-a946-8c1b7c0ee4e3.pdf" target="_blank" class="nav-top-a">Read the report</a>'
    ]
    carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1100,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 4000,
        },
        loop: true,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
    homeNavs: IRouteLinkItem[] = [
        {
            link: '',
            name: '',
        },
    ];
    image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';

    products = {
        products: [
            {
                title: 'Networking',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                        name: 'Secure Software Defined WAN - (SD-WAN)'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                        name: 'NFRXG Firewall- COSGrid Next Generation Firewall'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                        name: 'IoT Gateway - Secure IoT Connectivity with 5G'
                    }
                ]
            },
            {
                title: 'Threat Protection',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                        name: 'Zero Trust Network Access (ZTNA)'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'Cloud Firewall - Firewall As a Service (FWaas)',
                        fragment: 'FWaas'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'SIG - Secure Web Gateway (SWG)',
                        fragment: 'sig'
                    }
                ]
            },
            {
                title: 'SASE - Secure Access Service Edge',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'Z3 SASE - Secure, Fast Internet & Saas Access'
                    }
                ]
            },
            {
                title: 'Application & Data Protection',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                        name: 'Zero Trust Network Access (ZTNA 2.0)'
                    }
                ]
            },
            {
                title: 'Detection & Response',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                        name: 'NetShield NDR - AI/ML Big data NDR'
                    }
                ]
            }
        ],
        components: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}`,
                name: 'ReFlex Edge - Gateway hardware Device '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}`,
                name: 'COSGrid Guider - Management, Monitoring and orchestration '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}`,
                name: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}`,
                name: 'SAR- SDS - Security Analyzer and Responder- AI/ML Big data '
            },
            // {
            //     link: ``,
            //     name: 'MZA/SASE Client - Desktop App Client extends to endpoint client devices '
            // },
            // {
            //     link: ``,
            //     name: 'MZA Mediator - Optimized Access to cloud & Saas Apps '
            // }
        ],
        // platform: [
        //     {
        //         link: ``,
        //         name: 'Architecture '
        //     },
        //     {
        //         link: ``,
        //         name: 'Deployment '
        //     }
        // ],
    }

    solutions = [
        {
            title: 'SASE',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Secure Digital Transformation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Workforce Transformation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Improved Security Posture'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'User Experience Enhancement'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Multi-vendor Data integration'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Comprehensive Visibility & control'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Zero Trust SASE'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Attack Surface Reduction'
                },
            ]
        },
        {
            title: 'SD-WAN & Firewall',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Centralized Management'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Mpls Migration To SD-Wan'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Secure Branch Internet Access'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                    name: 'Intrusion Detection & Prevention'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Saas Acceleration'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Improved Bandwidth Utilization'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Digital Experience'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                    name: 'On Prem & Hybrid Security'
                },
            ]
        },
        {
            title: 'Zero Trust Access',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'VPN Alternative /Cloud VPN'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Secure Users & Data'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Secure Remote Access'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Cloud Workload Protection'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Third Party Access Control'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Compliance Management'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Zero Trust Access- IoT/OT'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}`,
                    name: 'Micro Segmentation'
                },
            ]
        },
        {
            title: 'Advanced Threat Protection',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Threat Detection and Mitigation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Network Traffic Analysis'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Endpoint Threat Analytics'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Insider Threat Detection'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Data Exfiltration Prevention'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Network Visibility & Threat Hunting'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Forensics Investigation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Ransomware Protection'
                },
            ]
        },
    ]

    industries = [
        {
            links: [
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}`,
                    name: 'Banking and Financial Services '
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}`,
                    name: 'Healthcare'
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}`,
                    name: 'Retail'
                },
                // {
                //     link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
                //     name: 'Hospitality'
                // },
            ]
        },
        {
            links: [
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.ITITES}`,
                    name: 'IT/ ITES '
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}`,
                    name: 'Manufacturing'
                },
                // {
                //     link: ``,
                //     name: 'Education'
                // },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.LOGISTICS}`,
                    name: 'Logistics'
                },
            ]
        }
    ]

    partners = [
        {
            links: [
                {
                    link: `${RouteConstants.PARTNERS}`,
                    name: 'Partner Program'
                },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}#becomePartner`,
                    name: 'Become a Partner'
                },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}#becomePartner`,
                    name: 'Find a Partner'
                },
            ],
        },
        {
            links: [
                // {
                //     link: ``,
                //     name: 'Partner Portal'
                // },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}#industry_partners`,
                    name: 'Industry Partners'
                },
            ]
        }
    ]

    resources = [
        {
            title: 'Resources',
            class: 'col-6',
            links: [
                {
                    link: `https://docs.cosgrid.com/`,
                    name: 'MicroZAccess ZTNA Documentation'
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
                    name: 'SD-WAN Concepts'
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
                    name: 'SD-WAN Usecase'
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.ZTNA}`,
                    name: 'Zero Trust Network Access Concepts'
                },
                // {
                //     link: ``,
                //     name: 'What is SASE ?'
                // },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.BLOGS}`,
                    name: 'Blogs'
                },
                {
                    link: `${RouteConstants.RESOURCES}/case-studies`,
                    name: 'Case Studies & Reports'
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.WHITE_PAPERS}`,
                    name: 'White Paper & Datasheets'
                },
                // {
                //     link: ``,
                //     name: 'ROI Calculator'
                // },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.FAQS}`,
                    name: 'Frequently Asked Questions'
                },
            ],
        },
        {
            title: 'Integrations',
            class: 'col-3',
            links: [
                {
                    link: `https://docs.cosgrid.com/identity-providers/azure-ad`,
                    name: 'Azure AD'
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/okta`,
                    name: 'Okta'
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/google-suite`,
                    name: 'Google Suite'
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/one-login`,
                    name: 'One Login'
                }
            ],
        },
        {
            title: 'Media',
            class: 'col-3',
            links: [
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.MOMENTS}`,
                    name: 'Gallery'
                },
                {
                    link: `${RouteConstants.RESOURCES}/news-${RouteConstants.PRESS_RELEASE}`,
                    name: 'News & Press Release'
                },
            ]
        }
    ]

    company = [
        {
            links: [
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.ABOUTUS}`,
                    name: 'About us'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.AWARDS}`,
                    name: 'Awards'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
                    name: 'Events'
                },
                // {
                //     link: ``,
                //     name: 'Our Customers'
                // },
            ],
        },
        {
            links: [
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
                    name: 'Contact us'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.CAREERS}`,
                    name: 'Careers'
                },
                // {
                //     link: ``,
                //     name: 'Current Openings'
                // },
            ]
        }
    ]
    // PRODUCTS DROPDOWN NAVS

    // productsNavs: INavItem[] = [
    //     {
    //         heading: 'Secure SD-WAN',
    //         children: [
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECURESDWAN_REFLEX_HYBWAN}`,
    //                 name: 'RefleX Hyb-WAN',
    //             },
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECURESDWAN_REFLEX_INWAN}`,
    //                 name: 'RefleX In-WAN',
    //             },
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SDINTERNETEDGE}`,
    //                 name: 'SD-Internet Edge',
    //             },
    //         ],
    //     },
    //     {
    //         heading: 'VPN',
    //         children: [
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECUREACCESS}`,
    //                 name: 'COSGrid RefleX RPN',
    //             },
    //         ],
    //     },
    //     {
    //         heading: 'NDR',
    //         children: [
    //             {
    //                 link: RouteConstants.NETSHIELD}`,
    //                 name: 'COSGrid NetShield',
    //             },
    //         ],
    //     },
    // ];
    productNav: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/.`,
            name: 'Products',
        },
    ];
    products1Navs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
            name: 'MicroZAccess',
        },
        {
            link: `/products/sase#sig`,
            name: 'Secure Internet Gateway(SIG)',
        },
        {
            link: `${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
            name: 'Virtual Private Network',
        },
    ];
    products2Navs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
            name: 'COSGrid RefleX RPN',
        },
    ];
    products3Navs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
            name: 'ReFlex SD-WAN - Secure Connectivity with 4G/5G',
        },
        // {
        //     link: `https://cdn.cosgrid.com/website/strapi/CG%20NFRXG%20%20datasheet_57c64fbc-cbea-4f8d-bdad-3145a66718d3.pdf`,
        //     name: 'COSGrid NFRG - Next Generation Firewall',
        // },
        // {
        //     name: 'COSGrid  IG4XG - IoT Gateway',
        //     link: `https://cdn.cosgrid.com/website/strapi/IG4xG%20Datasheet_9193f609-f484-4cbb-8cd7-5c6e7c2d3a16.pdf`,
        // },
    ];
    firewallNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/firewall/`,
            name: 'Next Generation Firewall (NGFW)',
        }
    ]
    ZTNANavs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            name: 'COSGrid SASE - Secure, Fast Internet & Saas Access',
        },
    ];

    componentsNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
            name: 'NetShield - Network Detection & Response (NDR)',
        },
    ];

    component1Navs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
            name: 'ReFlex Edge - SD-WAN Gateway hardware Device',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
            name: 'COSGrid Guider - Management, Monitoring and orchestration',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
            name: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}/.`,
            name: 'SAR- SDS - Security Analyser and Responder',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
            name: 'Secure connect - Desktop VPN Client & extends to client devices',
        },
    ];

    sdWanNav: IRouteLinkItem = {
        link: `${RouteConstants.SD_WAN}/.`,
        name: 'What is SD-WAN?',
    };

    RouteRedirectToIndustries() {
        this.router.navigate([this.INDUSTRIESLINK]);
    }
    RouteRedirectToUsecaseNavs() {
        this.router.navigate([this.USECASESLINK]);
    }

    // SOLUTIONS DROPDOWN NAVS
    // tslint:disable-next-line: member-ordering

    USECASESLINK = `${RouteConstants.USECASES}/.`;

    INDUSTRIESLINK = `${RouteConstants.INDUSTRIES}/.`;
    usecasesNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.USECASES}#eap`,
            name: 'App Performance',
        },

        {
            link: `${RouteConstants.USECASES}#ssit`,
            name: 'Secure Branch Internet Access',
        },
        {
            link: `${RouteConstants.USECASES}#wa`,
            name: 'SaaS Acceleration',
        },
        {
            link: `${RouteConstants.USECASES}#cm`,
            name: 'Centralised Management',
        },
        {
            link: `${RouteConstants.USECASES}#sra`,
            name: 'Secure Remote Access',
        },
        {
            link: `${RouteConstants.USECASES}#vavob`,
            name: 'Zero-glitch Voice and Video',
        },
        {
            link: `${RouteConstants.USECASES}#mmsw`,
            name: 'MPLS Migration To SD-WAN',
        },
    ];
    industryNav: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.INDUSTRIES}/.`,
            name: 'Industries',
        },
    ];
    solutionsNavs: IRouteLinkItem[] = [
        {
            name: 'Secure & Reliable Connectivity',
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
        },
        // {
        //     name: 'On Prem Connectivity',
        //     link: 'https://cdn.cosgrid.com/website/strapi/Re_Fle_X_SD_WAN_Product_Brief_a5bd7e785f.pdf',
        // },
        {
            name: 'Zero Trust Network Access',
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
        },
        // {
        //     name: 'On Prem Security/Firewall',
        //     link: 'https://cdn.cosgrid.com/website/strapi/CG%20NFRXG%20%20datasheet_57c64fbc-cbea-4f8d-bdad-3145a66718d3.pdf',
        // },
        {
            name: 'VPN Alternative/Cloud VPN',
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
        },
        // {
        //     name: 'Secure IoT Connectivity',
        //     link: 'https://cdn.cosgrid.com/website/strapi/IG4xG%20Datasheet_9193f609-f484-4cbb-8cd7-5c6e7c2d3a16.pdf',
        // },
        {
            name: 'Cloud Security',
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
        },
        {
            name: 'IOT / OT Security',
            link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
        },
    ];
    industriesNavs: IRouteLinkItem[] = [
        {
            name: 'Financial Services/BFSI',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
        },
        {
            name: 'Restaurants/Hospitality',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
        },
        {
            name: 'Healthcare',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
        },
        {
            name: 'Retail',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
        },
        {
            name: 'Manufacturing',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
        },
        {
            name: 'IT/ITES',
            link: `${RouteConstants.ITITES}/.`,
        },
        // {
        //     name: 'Telecommunications',
        //     link: `${RouteConstants.CONTACTS}/.`,
        // },
        // {
        //     name: 'Logistics',
        //     link: `${RouteConstants.CONTACTS}/.`,
        // },
    ];

    // PARTNERS
    partnersNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PARTNERS}#partnersProgram`,
            name: "Partner's Portal",
        },
        {
            link: `${RouteConstants.PARTNERS}#becomePartner`,
            name: 'Become a partner',
        },
    ];

    // RESOURCES
    // resourceNavs: IRouteLinkItem[] = [
    //     {
    //         link: 'https://docs.cosgrid.com/',
    //         name: 'MicroZAccess Documentation',
    //     },
    //     {
    //         link: `${RouteConstants.RESOURCES}/case-studies/.`,
    //         name: 'Case Studies & Reports',
    //     },

    //     {
    //         link: `resources/${RouteConstants.SD_WAN}/.`,
    //         name: 'SD-WAN Concepts',
    //     },

    //     {
    //         link: `${RouteConstants.BLOGS}/.`,
    //         name: 'Blogs',
    //     },
    //     {
    //         link: `resources/${RouteConstants.ZTNA}`,
    //         name: 'Zero Trust Network Access Concepts',
    //     },

    //     {
    //         link: `${RouteConstants.FAQS}/.`,
    //         name: 'Frequently Asked Questions',
    //     },
    //     {
    //         link: `/${RouteConstants.RESOURCES}/library/.`,
    //         name: 'Whitepaper and Datasheets',
    //     },

    //     {
    //         link: `resources/${RouteConstants.GALLERY}/.`,
    //         name: 'Gallery',
    //     },
    // ];
    resourcesNavs: IRouteLinkItem[] = [
        {
            link: 'https://docs.cosgrid.com/',
            name: 'MicroZAccess Documentation',
        },
        {
            link: `resources/${RouteConstants.SD_WAN}/.`,
            name: 'SD-WAN Concepts',
        },
        {
            link: `resources/${RouteConstants.ZTNA}/${RouteConstants.MICROZACCESS}.`,
            name: 'Zero Trust Network Access Concepts',
        },
        {
            link: `${RouteConstants.RESOURCES}/case-studies/.`,
            name: 'Case Studies & Reports',
        },

        {
            link: `/${RouteConstants.RESOURCES}/library/.`,
            name: 'Whitepaper and Datasheets',
        },

        {
            link: `${RouteConstants.BLOGS}/.`,
            name: 'Blogs',
        },

        {
            link: `${RouteConstants.FAQS}/.`,
            name: 'Frequently Asked Questions',
        },
    ];

    integrationNavs: IRouteLinkItem[] = [
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.AZURE_AD}`,
            name: "Azure AD"
        },
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.OKTA}`,
            name: "OKta"
        },
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.GOOGLE_SUITE}`,
            name: "Google Suite"
        },
        {
            link: `https://docs.cosgrid.com /${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.ONE_LOGIN}`,
            name: "One Login"
        }
    ]
    //MEDIA
    mediaNavs: IRouteLinkItem[] = [
        {
            link: `resources/${RouteConstants.GALLERY}/.`,
            name: 'Gallery',
        },
    ]

    // COMPANY
    companyNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.ABOUTUS}/.`,
            name: 'About us',
        },
        {
            link: `${RouteConstants.PRESS_RELEASE}/.`,
            name: 'Press Release',
        },
        {
            link: `${RouteConstants.CONTACTS}/.`,
            name: 'Contact us',
        },
        {
            link: `${RouteConstants.CAREERS}/.`,
            name: 'Careers',
        },
    ];

    newsNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.AWARDS}/.`,
            name: 'Awards',
        },
        {
            link: `${RouteConstants.EVENTS}/.`,
            name: 'Events',
        },
    ];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private emitter: MyEmitterService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        // Subscribe to the NavigationStart event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationStart) {
        //         console.log('Navigation Start:', event);
        //     }
        // });
        // // Subscribe to the NavigationEnd event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         console.log('Navigation End:', event);
        //     }
        // });
        // // Subscribe to the NavigationError event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationError) {
        //         console.log('Navigation Error:', event);
        //     }
        // });
    }

    mediaquery: MediaQueryList;
    menutoggle: any;

    navigate(id) {
        //console.log(id);
        this.router.navigateByUrl(id);
    }
    productClick(item) {
        this.togglefn(item.link);
        //console.log(item);
        this.emitter.emitproductEvent(item);
    }

    componentClick(link) {
        this.togglefn(link);
        this.emitter.emitcomponentEvent(link);
    }

    industriesClick(link) {
        //console.log(link);
        this.togglefn(link);
        this.emitter.emitIndustriesEvent(link);
        // console.log(link);
    }

    isScrolled = false;

    @HostListener('window:scroll')
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId)) {
            this.image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';
            window.pageYOffset >= 80 ? (this.isScrolled = true) : (this.isScrolled = false);
        }
    }

    togglefn(link: string) {
        if (link.includes('https://')) {
            window.open(link, '_blank');
        } else {
            if (this.menutoggle == 'menu') { this.openedTab = ''; this.menutoggle = 'close'; }
        }
    }

    openedTab = ''
    openLinks(tab: string) {
        this.openedTab == tab ? this.openedTab = '' : this.openedTab = tab;
    }
}
