<footer class="footer mt-5">
    <div class="container-fluid">
        <div class="row justify-content-around">
            <div class="col-sm-6 col-md-5">
                <div class="w-60 mx-auto h-100 d-flex flex-column justify-content-between" id="footer-logo-container">
                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div *ngFor="let icon of socialMediaItems">
                            <img
                                (click)="openLink(icon.link)"
                                role="button"
                                [src]="
                                    'https://cdn.cosgrid.com/website/assets/social media icons/' + icon.image + '.png'
                                "
                                height="36px"
                                loading="lazy"
                                [alt]="icon.image"
                            />
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <img
                            src="https://cdn.cosgrid.com/website/assets/logo.png"
                            class="img-fluid w-100"
                            loading="lazy"
                            alt="COSGrid Networks"
                        />
                        <div style="height: 10px"></div>
                        <h3 class="fs-6 fw-bold text-center tracking-wide">
                            Cyber resiliency simplified with<br />Smart SASE NetSecOps
                        </h3>
                    </div>
                    <h3 class="fs-6 w-100 text-center">Copyright ©2024 - All Rights Reserved</h3>
                </div>
            </div>
            <div class="col-sm-6 col-md-7 gap-3">
                <div class="row">
                   
                    <div class="col-5">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Products</h6>
                            <li *ngFor="let item of productLinks" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Components</h6>
                            <li *ngFor="let item of componentsLinks" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link" (click)="componentClick(item.link)">{{
                                    item.name
                                }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-3">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Company</h6>
                            <li *ngFor="let item of companyItem" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-5">
                        <ul class="list-group mt-5">
                            <h6 class="m-0 fw-bold">Contact Us</h6>
                            <li *ngFor="let item of contactsItem" class="m-0">
                                <a class="footer-link m-0" [href]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="list-group mt-5">
                            <h6 class="m-0 fw-bold">Locations</h6>
                            <li *ngFor="let item of locationItem" class="m-0">
                                <a class="footer-link m-0" [href]="item.link" target="_blank" [innerHTML]="item.name"></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-3">

                        <ul class="list-group" style="margin-top: 64px !important;">
                            <li *ngFor="let item of partnersItem" class="m-0">
                                 <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- <footer class="footer">
    <div class="containerfluid p-4">
        <div class="row">
            <div class="col"></div>

            <div class="col-3 py-4">
                <div class="h-100 w-100 d-flex flex-column justify-content-between">
                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div *ngFor="let icon of socialMediaItems">
                            <img
                                (click)="openLink(icon.link)"
                                role="button"
                                [src]="
                                    'https://cdn.cosgrid.com/website/assets/social media icons/' + icon.image + '.png'
                                "
                                height="30px"
                                loading="lazy"
                                [alt]="icon.image"
                            />
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <img
                            src="https://cdn.cosgrid.com/website/assets/logo.png"
                            class="img-fluid w-80"
                            loading="lazy"
                            alt="COSGrid Networks"
                        />
                        <div style="height: 10px"></div>
                        <h3 class="fs-5 fw-bold text-center">
                            Cyber resiliency simplified with<br />Smart SASE NetSecOps
                        </h3>
                    </div>
                    <h3 class="fs-6 w-100 text-center">Copyright ©2024 - All Rights Reserved</h3>
                </div>
            </div>
            <div class="col-3">
                <ul class="list-group">
                    <h6 class="fs-5 fw-bold">Products</h6>
                    <li *ngFor="let item of productLinks">
                        <a class="footer-link fs-6" [routerLink]="item.link">{{ item.name }}</a>
                    </li>
                </ul>
                <ul class="list-group mt-5">
                    <h6 class="fs-5 fw-bold">Components</h6>
                    <li *ngFor="let item of componentsLinks">
                        <a class="footer-link fs-6" [routerLink]="item.link" (click)="componentClick(item.link)">{{
                            item.name
                        }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-2">
                <ul class="list-group">
                    <h6 class="fs-5 fw-bold">Company</h6>
                    <li *ngFor="let item of companyItem">
                        <a class="footer-link fs-6" [routerLink]="item.link">{{ item.name }}</a>
                    </li>
                </ul>
                <ul class="list-group mt-5">
                    <h6 class="fs-5 fw-bold">Contact Us</h6>
                    <li *ngFor="let item of contactsItem">
                        <a class="footer-link fs-6" [href]="item.link">{{ item.name }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-3">
                <ul class="list-group">
                    <li *ngFor="let item of partnersItem">
                        <a class="fs-5 fw-bold fs-5" [routerLink]="item.link">{{ item.name }}</a>
                         <a class="footer-link" [routerLink]="item.link">{{ item.name }}</a> -->
                    <!-- </li>
                </ul>
                <ul class="list-group mt-5">
                    <h6 class="fs-5 fw-bold">Locations</h6>
                    <li *ngFor="let item of locationItem">
                        <a class="footer-link fs-6" [href]="item.link" target="_blank" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="col"></div>
        </div>
    </div>
</footer> --> 

<!-- <footer class="footer">
    <div class="containerfluid mb-2">
        <div class="row" style="position: relative; height: 350px">
            <div class="col-sm-3">
                <img
                    class="img-fluid mr-1"
                    src="https://cdn.cosgrid.com/website/assets/blog_images/1300w/COSgrid%20copy_2.png"
                    alt="Logo"
                />
            </div>
            <div class="col-sm-3"></div>
            <div class="col-sm-6">
                <img
                    class="img-fluid overlap-image"
                    src="https://cdn.cosgrid.com/website/assets/MZA/group_31.webp"
                    alt="Placeholder"
                />
            </div>
        </div>
        <div class="row" id="footer-bottom-row">
            <div class="col-sm-1">
                <nav style="margin-left: 10px; font-size: 23px" class="overlay-twitter">
                    <ul>
                        <li class="mt-3 mb-3">
                            <a href="tel:+918610144212"><i class="fab fa-lg fa-whatsapp"></i></a>
                        </li>
                        <br />
                        <li class="mt-3 mb-3">
                            <a href="https://instagram.com/cosgridnetworks?utm_medium=copy_link" target="_blank"
                                ><i class="fab fa-lg fa-instagram"></i
                            ></a>
                        </li>
                        <br />
                        <li class="mt-3 mb-3">
                            <a href="https://www.reddit.com/user/COSGrid_Networks/" target="_blank"
                                ><i class="fab fa-lg fa-reddit"></i
                            ></a>
                        </li>
                        <br />
                        <li class="mt-3 mb-3">
                            <a href="https://www.facebook.com/COSGrid-Networks-106028358695422/" target="_blank"
                                ><i class="fab fa-lg fa-facebook"></i
                            ></a>
                        </li>
                        <br />
                        <li class="mt-3 mb-3">
                            <a href="https://www.linkedin.com/company/cosgrid-networks/" target="_blank"
                                ><i class="fab fa-lg fa-linkedin-in"></i
                            ></a>
                        </li>
                        <br />
                    </ul>
                </nav>
            </div>
            <div class="col-sm-6">
               <a class="twitter-timeline overlay-twitter" href="https://twitter.com/CosgridNetworks?ref_src=twsrc%5Etfw">Tweets by @CosgridNetworks</a> 
                <div class="groupCon">
                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong" style="font-weight: bold">Company</h6>
                            <li *ngFor="let item of companyItem">
                                <a class="footer-link" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                             <li *ngFor="let item of newsItem">
                                <a class="footer-link" [routerLink]="item.link">{{ item.name }}</a>
                            </li> 
                            <h6 class="text-strong part" style="font-weight: bold">Partners</h6>
                            <li *ngFor="let item of partnersItem">
                                <a class="footer-link" [href]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong" style="font-weight: bold">Products</h6>
                            <li>
                                <a class="footer-link" [routerLink]="SASLinks[0].link">Security Service Edge</a>
                            </li>

                            <li><a class="footer-link" [routerLink]="SSELinks[0].link">Secure SD-WAN</a></li>

                            <li>
                                <a class="footer-link" [routerLink]="SDLinks[0].link" (click)="routeToPage(SDLinks[0])"
                                    >Secure Access service Edge</a
                                >
                            </li>

                            <li>
                                <a
                                    class="footer-link"
                                    [routerLink]="NDRLinks[0].link"
                                    (click)="routeToComponent(NDRLinks[0].link)"
                                    >Advanced Threat protection</a
                                >
                            </li>

                            <h6 class="text-strong comp" style="font-weight: bold">Components</h6>
                            <li *ngFor="let item of componentsLinks">
                                <a class="footer-link" [routerLink]="item.link" (click)="routeToComponent(item.link)">{{
                                    item.name
                                }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong" style="font-weight: bold">Resources</h6>
                            <li *ngFor="let item of resourceLinks">
                                <a
                                    class="footer-link"
                                    [routerLink]="item.link.includes('https://') ? null : item.link"
                                    (click)="industriesClick(item.link)"
                                    >{{ item.name }}</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="groupContainer">
                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong" style="font-weight: bold">Resources</h6>
                            <li>
                                <a class="footer-link" [href]="DOCLinks[0].link" target="_blank">Documentation</a>
                            </li>

                            <li><a class="footer-link" [routerLink]="UseLink[0].link">Use Cases</a></li>
                            <li><a class="footer-link" [routerLink]="LibraryLinks[0].link">Library</a></li>

                            <li><a class="footer-link" routerLink="/blogs/.">Blogs</a></li>
                            <li><a class="footer-link" [routerLink]="MediLink[0].link">Media</a></li>

                            <li><a class="footer-link" [routerLink]="FAQLink[0].link">FAQ</a></li>
                        </ul>
                    </div>
                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong" style="font-weight: bold">Contact us</h6>
                            <li *ngFor="let item of contactsItem">
                                <a target="_top" [href]="item.link" class="footer-link">{{ item.name }}</a>
                            </li>
                        </ul>
                        <ul class="list-group pt-2">
                            <li style="font-weight: bold; cursor: pointer" [routerLink]="PRIVACY_POLICY">
                                Privacy Policy
                            </li>
                            <li style="font-weight: bold; cursor: pointer" [routerLink]="Support">Support</li>
                            <li style="font-weight: bold; cursor: pointer" routerLink="/terms-of-use/.">
                                Terms of Service
                            </li>
                            <li style="font-weight: bold; cursor: pointer" [routerLink]="SITEMAP">Sitemap</li>
                        </ul>
                    </div>

                    <div class="groupx">
                        <ul class="list-group">
                            <h6 class="text-strong"><i class="fa-solid fa-location-pin"></i>&nbsp;HQ</h6>
                            <li class="mb-2">Velachery, Chennai - 600042, Tamil Nadu, India</li>
                        </ul>

                        <ul class="list-group">
                            <h6><i class="fa-solid fa-globe"></i></h6>
                            <li class="mb-2">Delaware, New Castle, US, 19808</li>
                        </ul>
                        <ul class="list-group">
                            <h6><i class="fa fa-phone"></i></h6>
                            <li>+91 90227 64534</li>
                            <li class="margin-top:-10px">+91 86101 44212</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->

<!-- <footer class="footer">
    <div class="container-fluid">
        <div class="row row-cols-1  row-cols-lg-6 pl-2 pr-12 pt-3">
            <div class="col-md text-centertext-md-center">
                <ul class="list-group">
                    <h6 class="text-strong"><strong>Company</strong></h6>
                    <li *ngFor="let item of companyItem">
                        <a class="footer-link" [routerLink]="item.link">{{ item.name }}</a>
                    </li>
                    <li *ngFor="let item of newsItem">
                        <a class="footer-link" [href]="item.link">{{ item.name }}</a>
                    </li>
                </ul>
            </div>

            <div class="col-md col-row-1 text-left text-md-center">
                <ul class="list-group">
                    <a routerLink="/products/overview">
                        <h6 class="text-strong"><strong>Products</strong></h6>
                    </a>
                    <li><a class="footer-link">Secure SD-WAN</a></li>
                    <li *ngFor="let item of productsItem">
                        <a class="footer-link" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="col-sm pt-5 pt-md-0 text-center pb-0 pb-md-5 pb-lg-5 text-md-left">
                <ul class="list-group">
                    <h6 class="text-strong"><strong>Partners</strong></h6>
                    <li *ngFor="let item of partnersItem">
                        <a class="footer-link" [href]="item.link">{{ item.name }}</a>
                    </li>
                </ul>
                <ul class="list-group pt-4">
                    <h6 class="text-strong"><strong>Contact us</strong></h6>
                    <li *ngFor="let item of contactsItem">
                        <a
                            *ngIf="item.name == 'info@cosgrid.com'"
                            target="_top"
                            [href]="item.link"
                            class="footer-link"
                            >{{ item.name }}</a
                        >
                        <a *ngIf="item.name != 'info@cosgrid.com'" [href]="item.link" class="footer-link">{{
                            item.name
                        }}</a>
                    </li>
                </ul>
            </div>
            <div class="col-xl pt-5 pt-lg-0 ">
                <div class="row">
                    <div class="col-11 text-center">
                        <h6 class="text-strong"><strong>Locations</strong></h6>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-7">
                                <ul class="list-group text-center mb-2 pr-3" >
                                    INDIA
                                </ul>
                        <div class="row text-left">
                                    <div class="col pl-xl-4">
                                        <ul class="list-group">
                                            <li *ngFor="let item of registeredOffice" class="mb-3">
                                                <a
                                                    class="footer-link"
                                                    [href]="item.link"
                                                    target="_new"
                                                    [innerHTML]="item.name"
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                   
                                
                                    <div class="col pl-xl-4">
                                        <ul class="list-group">
                                            <li *ngFor="let item of locationItem" class="mb-3" >
                                                <a
                                                    class="footer-link"
                                                    [href]="item.link"
                                                    target="_new"
                                                    [innerHTML]="item.name"
                                                ></a>
                                            </li>
                                        </ul>
                                   
                                </div></div></div>
                            
                       
                                <div class="col ml-1">
                                    <div class="col pl-2">
                                                <ul class="list-group text-center mb-2 pl-3" >
                                                    USA
                                                </ul>
                          
                        <div class="row ml-2">
                            <div class="col-lg-12 pl-xl-3">
                                <ul class="list-group">
                                    <li *ngFor="let item of USlocation" class="mb-3" >
                                        <a
                                            class="footer-link"
                                            [href]="item.link"
                                            target="_new"
                                            [innerHTML]="item.name"
                                        ></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md">
                <p class="copyright-text text-center text-md-left mt-3">
                    Copyright 2023 © COSGrid Systems Pvt. Ltd., All Rights Reserved.
                </p>
                <p class="text-md-left text-center">
                    <a
                        *ngFor="let item of socialMediaItem"
                        class="footer-link fs-4 mr-2"
                        [href]="item.link"
                        target="_blank"
                        [innerHTML]="item.name"
                    ></a>
                </p>
                <p class="text-center text-md-left pt-2">
                    <a class="footer-link" [routerLink]="PRIVACY_POLICY"> Privacy Policy &nbsp; </a>
                    |
                    <a class="footer-link" [routerLink]="SITEMAP"> &nbsp; Sitemap </a>
                </p>
            </div>
        </div>
    </div>
</footer> -->

<!-- <div class="container-fluid">
        <div class="row">
            <div class="col-md">
                <p class="copyright-text text-center text-md-left mt-3">
                    Copyright 2023 © COSGrid Systems Pvt. Ltd., All Rights Reserved.
                </p>
                <p class="text-md-left text-center">
                    <a
                        *ngFor="let item of socialMediaItem"
                        class="footer-link fs-4 mr-2"
                        [href]="item.link"
                        target="_blank"
                        [innerHTML]="item.name"
                    ></a>
                </p>
                <p class="text-center text-md-left pt-2">
                    <a class="footer-link" [routerLink]="PRIVACY_POLICY"> Privacy Policy &nbsp; </a>
                    |
                    <a class="footer-link" [routerLink]="SITEMAP"> &nbsp; Sitemap </a>
                </p>
            </div>
        </div>
    </div> -->

<!-- <div class="col-xl pt-5 pt-lg-0">
                        <div class="row">
                            <div class="col text-center">
                                <h6 class="text-strong"><strong>Locations</strong></h6>
                            </div>
                        </div>
                        <ng-container class="d">
                            <div class="row pl-3">
                                <div class="col-sm">
                                    <p class="list-group text-center mb-2 pr-3">
                                        INDIA
                                    </p>
                                </div>
                            </div>
                            <div class="row text-left pl-3">
                                <div class="col-sm-6">
                                    <div class="d-flex justify-content-center">
                                        <ul class="list-group">
                                            <li *ngFor="let item of registeredOffice" class="mb-3">
                                                <a
                                                    class="footer-link"
                                                    [href]="item.link"
                                                    target="_new"
                                                    [innerHTML]="item.name"
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="d-flex justify-content-center">
                                        <ul class="list-group">
                                            <li *ngFor="let item of locationItem" class="mb-3">
                                                <a
                                                    class="footer-link"
                                                    [href]="item.link"
                                                    target="_new"
                                                    [innerHTML]="item.name"
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="row pl-3">
                                <div class="col-sm">
                                    <p class="list-group text-center mb-2 pr-3">
                                        USA
                                    </p>
                                </div>
                            </div>
                            <div class="row text-left pl-3">
                                <div class="col-sm">
                                    <div class="d-flex justify-content-center">
                                        <ul class="list-group" style="margin-left: 20px;">
                                            <li *ngFor="let item of USlocation" class="mb-3">
                                                <a
                                                    class="footer-link"
                                                    [href]="item.link"
                                                    target="_new"
                                                    [innerHTML]="item.name"
                                                ></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div> -->
