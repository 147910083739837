import { Component, OnDestroy, OnInit } from '@angular/core';
import BLOG_QUERY from 'src/app/queries/blogs/blogs.service';
import { pipe, Subject, Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MyEmitterService } from '../../../../app-emitter.service';
// import { Meta } from '@angular/platform-browser';
import { KlentyService } from 'src/app/klenty.service';
import * as marked from 'marked';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import BLOG_QUERY2 from 'src/app/queries/blogs/redirectBlogs.service'
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-cosgridblogs',
    templateUrl: './cosgridblogs.component.html',
    styleUrls: ['./cosgridblogs.component.scss'],
})
export class CosgridblogsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    blogs: any[];
    redirectBlogs: any[];
    blog;
    loading = false;
    errors: any;
    content: any;
    safeHtml: SafeHtml;

    constructor(
        public sanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        public router: Router,
        private apollo: Apollo,
        private emitter: MyEmitterService,
        private meta: Meta, private titleService: Title,
        private klentyService: KlentyService,
        private location: Location
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle('Insightful Blogs | COSGrid Networks');
        this.meta.updateTag({ name: 'description', content: '' });
        this.meta.updateTag({ name: 'keywords', content: '' });
        // this.klentyService.load();
        this.loading = true;
        this.apollo
            .watchQuery({
                query: BLOG_QUERY2,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.redirectBlogs = (result.data as any).blog2S.data.map((blog) => ({
                    ...blog,
                    image: blog?.attributes?.Title_image?.data[0]?.attributes?.url,
                }));
            })

        this.apollo
            .watchQuery({
                query: BLOG_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.blogs = (result.data as any).blogs.data.map((blog) => ({
                    ...blog,
                    image: blog?.attributes?.Title_image?.data?.attributes?.url,
                }));
                const a = this.activatedRoute.snapshot.params;
                const b = this.blogs.filter((item) => item.attributes.idUrl === a.id);
                this.blog = b[0];
                this.content = this.blog?.attributes?.Content;
                let content1 = this.content;
                //Markdown to HTML
                // if (this.blog?.id > 10) {
                //     content1 = this.markdownToHtml(this.content);
                // }
                this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(content1);
                this.blogs = this.blogs.filter((item) => item.attributes.idUrl !== a.id);
                this.errors = result.errors;
                this.loading = false;
                this.titleService.setTitle(this.blog.attributes.Title);
                this.meta.updateTag({ name: 'description', content: this.blog.attributes.Description });
                this.meta.updateTag({ property: 'og:type', content: 'article' });
                this.meta.updateTag({ property: 'og:title', content: this.blog.attributes.Title });
                this.meta.updateTag({ property: 'og:image', content: this.blog.image });
                // console.log("In Blog", this.blog);
                if (this.blog?.attributes?.idUrl != 'zerotrust_security') {
                    // console.log("Blog Meta Tag")
                    this.meta.updateTag({
                        property: 'og:url',
                        content: 'https://www.cosgrid.com/resources/blogs/' + this.blog.attributes.idUrl,
                    });
                }

            });
        const blog = window.history?.state?.blog;
        const redirectblogs = window.history?.state?.redirectBlogs;
        this.redirectBlogs = redirectblogs;
        this.blog = blog;
        // console.log("In history");
        this.content = this.blog?.attributes?.Content;
        let content1 = this.content;
        //Markdown to HTML 
        // if (this.blog.id > 10) {
        //     content1 = this.markdownToHtml(this.content);
        // }
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(content1);
        this.emitter.getBlogsEvent().pipe(takeUntil(this.destroy$)).subscribe((data) => {
            window.scroll(0, 0);
            this.blog = data;
            // console.log("In Emitter");
            this.content = this.blog.attributes.Content;
            let content1 = this.content;

            // if (this.blog.id > 10) {
            //     content1 = this.markdownToHtml(this.content);
            // }
            this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(content1);

            this.apollo
                .watchQuery({
                    query: BLOG_QUERY2,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    this.redirectBlogs = (result.data as any).blog2S.data.map((blog) => ({
                        ...blog,
                        image: blog?.attributes?.Title_image?.data[0]?.attributes?.url,
                    }));
                })

            // this.apollo
            //     .watchQuery({
            //         query: BLOG_QUERY2,
            //     })
            //     .valueChanges.subscribe((result) => {
            //         this.redirectBlogs = (result.data as any).blog2S.data.map((blog) => ({
            //             ...blog,
            //             image: blog?.attributes?.Title_image?.data?.attributes?.url,
            //         }));
            //     })

            this.apollo
                .watchQuery({
                    query: BLOG_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    this.blogs = (result.data as any).blogs.data.map((blog) => ({
                        ...blog,
                        image: blog?.attributes?.Title_image?.data?.attributes?.url,
                    }));
                    const a = this.activatedRoute.snapshot.params;
                    this.blogs = this.blogs.filter((item) => item.attributes.idUrl !== a.id);
                    this.loading = false;
                });
            // console.log(this.blog);
            this.titleService.setTitle(this.blog.attributes.Title);
            this.meta.updateTag({ name: 'description', content: this.blog.attributes.Description });
            this.meta.updateTag({ property: 'og:type', content: 'article' });
            this.meta.updateTag({ property: 'og:title', content: this.blog.attributes.Title });
            this.meta.updateTag({ property: 'og:image', content: this.blog.image });
            if (this.blog?.attributes?.idUrl != 'zerotrust_security') {
                // console.log("Blog Meta Tag")
                this.meta.updateTag({
                    property: 'og:url',
                    content: 'https://www.cosgrid.com/blogs/' + this.blog.attributes.idUrl,
                });
            }
        });
    }

    routeToblog(blog) {
        this.router.navigateByUrl(`/resources/blogs/${blog?.attributes?.idUrl}`, {
            state: {
                blog,
                redirectBlogs: this.redirectBlogs,
            },
        });

        this.emitter.emitBlogsEvent(blog);
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    blogData = [
        {
            id: 1,
            title: 'Title 01',
            description:
                'Description of the Blog. Description of the Blog. Description of the Blog. Description of the Blog. vDescription of the Blog. Description of the Blog. Description of the Blog. ',
            url: 'Url of the blog',
            published_at: 'March 30, 2022',
            image: 'https://pbs.twimg.com/media/EHOq6PiUUAA6VH3?format=jpg&name=4096x4096',
        },
        {
            id: 2,
            title: 'Title 02',
            description:
                'Description of the Blog. Description of the Blog. Description of the Blog. Description of the Blog. vDescription of the Blog. Description of the Blog. Description of the Blog. ',
            url: 'Url of the blog',
            published_at: 'March 30, 2022',
            image: 'https://pbs.twimg.com/media/EHOq6PiUUAA6VH3?format=jpg&name=4096x4096',
        },
        {
            id: 3,
            title: 'Title 03',
            description:
                'Description of the Blog. Description of the Blog. Description of the Blog. Description of the Blog. vDescription of the Blog. Description of the Blog. Description of the Blog. ',
            url: 'Url of the blog',
            published_at: 'March 30, 2022',
            image: 'https://pbs.twimg.com/media/EHOq6PiUUAA6VH3?format=jpg&name=4096x4096',
        },
    ];

    // markdownToHtml(markdown: any): any {
    //     const renderer = new marked.Renderer();
    //     renderer.image = (href: string, title: string, text: string) => {
    //         return `<img src="${href}" alt="cosgrid Logo" title="${title}" loading="lazy"/>`;
    //     };
    //     var md = marked.setOptions({
    //         renderer: renderer
    //     });
    //     return md.parse(markdown);
    // }
}
