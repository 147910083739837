<div class="container-fluid shadow rounded p-3" style="background-color: rgb(38, 132, 255)">
    <div class="row">
        <div class="col d-flex justify-content-center flex-column align-items-center rounded m-2" >
            <p class="fw-bold text-center mt-4 clr-white h2">{{title}}</p>
            <p class="text-center w-100 mt-2 px-2 clr-white fs-6" [innerHTML]="desc"></p>
        </div>
    </div>
    <div>
        <image-list
            class="d-flex flex-column justify-content-center align-items-center"
            [data]="data"
            [alignCenter]="true"
            [titleCenter]="true"
            xlSingleLineGrids="3"
        ></image-list>
    </div>
</div>