import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResourcesRoutingModule } from './resources-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SdWanComponent } from './resources/sd-wan/sd-wan.component';
import { ZtnaComponent } from './resources/ztna/ztna.component';
import { BlogsComponent } from './resources/blogs/blogs.component';
import { CaseStudiesComponent } from './resources/case-studies/casestudies.component';
import { FaqsComponent } from './resources/faqs/faqs.component';
import { MomentsComponent } from './media/moments/moments.component';
import { PressReleaseComponent } from './media/press-release/press-release.component';
import { WhitepapersComponent } from './resources/whitepapers/whitepapers.component';
import { CosgridblogsComponent } from './resources/cosgridblogs/cosgridblogs.component';
import { UsecasesComponent } from './resources/usecases/usecases.component';



@NgModule({
  declarations: [
    SdWanComponent,
    ZtnaComponent,
    BlogsComponent,
    CaseStudiesComponent,
    FaqsComponent,
    MomentsComponent,
    PressReleaseComponent,
    WhitepapersComponent,
    CosgridblogsComponent,
    UsecasesComponent
  ],
  imports: [
    CommonModule,
    ResourcesRoutingModule,
    SharedModule
  ]
})
export class ResourcesModule { }
