<section id="press-release" class="px-md-5 px-2 mx-md-5 pt-3">

    <p class="mt-5 fw-bold heading h2">
        COSGrid Unveiling its Z3 SASE PoP & Instance in Dubai for seamless access and local Data protection
    </p>
    <p class="mt-3 fw-bold h5">Strengthen Data Sovereignty in the Middle East Region</p>
    <p class="mt-4">Dubai, United Arab Emirates , August 21, 2024</p>


    <div>
        <p>COSGrid, a leading company in cybersecurity and networking products, has unveiled a new point-of-presence
            (PoP) & Instance in Dubai, United Arab Emirates. This initiative aligns with the company's objective to
            enhance the accessibility and distribution of its unified secure access service edge (SASE) cloud
            architecture model throughout the Middle East.</p>
    </div>


    <div class="d-flex justify-content-center my-4">
        <img src="assets/images/press-release/dubai-press-release.png" alt="" class="w-50" loading="lazy">
    </div>

    <p>
        COSGrid Z3 SASE provides secure access and <span class="fw-bold">protection to users, data, applications</span>
        , and <span class="fw-bold">workloads</span> across offices,
        clouds, remote sites, and WFH users anywhere. As the demand for secure and efficient networking solutions rises
        in the Middle East, COSGrid's new POP & Instance in the region are strategically established to address this
        requirement.
        By placing services nearer to end-users, these new Local PoPs provide a smooth and secure networking experience.
        Additionally, having a local facility for data storage and processing helps companies adhere to the data
        protection
        laws and compliance regulations in the Middle East.
    </p>

    <p>
        In short, With this SASE POP & instance in Dubai, the Middle East Customers have,
    </p>

    <ul>
        <li>Data sovereignty & Data Protection</li>
        <li>Low latency connectivity</li>
        <li>Seamless & Secure User experience</li>
        <li>Improved security posture</li>
    </ul>

    <p class="fw-bold"> COSGrid Z3 SASE</p>

    <p>Z3 SASE - Zero Trust Security | Zero Glitch Connectivity | Zero Touch Operations Secure Access Service Edge</p>

    <p> <span class="fw-bold">COSGrid Z3 SASE</span> is a cutting edge cloud-delivered SASE solution that combines the
        functions of networking and security into a single, unified platform. It provides secure, simplified, and
        optimized connectivity for distributed enterprise.</p>

    <p class="fw-bold"> Key Highlights</p>

    <ul>
        <li><span class="fw-bold">Secure Access Service Edge (SASE):</span> Integrates network security functions into a single, cloud-delivered
            service. </li>
        <li><span class="fw-bold">Zero Trust Architecture:</span> Employs a "never trust, always verify" approach to security.</li>
        <li><span class="fw-bold">SD-WAN:</span> Optimizes network performance and reliability through intelligent path selection. </li>
        <li><span class="fw-bold">Advanced Threat Protection:</span> Protects against a wide range of cyber threats, including malware, ransomware,
            and phishing.</li>
        <li><span class="fw-bold">Cloud Security:</span> Ensures secure access to cloud applications and data.</li>
        <li><span class="fw-bold">Centralized Management:</span> Simplifies network management and policy enforcement. </li>
        <li><span class="fw-bold">Scalability:</span> Easily adapts to changing business needs and network growth</li>

    </ul>

    <p>
        By expanding our SASE footprint with a new POP & Instance  in Dubai, United Arab Emirates  we are committed to providing our customers with the highest level of security and performance
    </p>

    
    <p class="h4 fw-bold mt-4">About COSGrid</p>

    <p class=" mt-4">COSGrid Networks is a leading <span class="fw-bold">networking and cybersecurity products</span>
        company that delivers secure access and <span class="fw-bold">protection to users, data, applications, and
            workloads</span> across offices, clouds, remote sites, and WFH users anywhere. As one of the leading unified
        SASE companies globally, COSGrid enables organizations to achieve "Cyber-resilience Simplified" through our
        advanced threat detection, zero trust architecture capabilities, and patented adaptive SD-WAN-based traffic
        steering. With our <span class="fw-bold">AI/Big data ML-based Cybersecurity solutions</span> , organizations can
        stay one step ahead of Attackers and safeguard their digital assets effectively.</p>

    <a routerLink="/" target="_blank" rel="noopener noreferrer"></a>

    <p class=" my-4">For More, Engage with us <a href="https://www.linkedin.com/company/cosgrid-networks/"
            target="_blank" rel="noopener noreferrer">Linkedin</a>, <a href="https://twitter.com/cosgridnetworks"
            target="_blank" rel="noopener noreferrer">X</a>, <a href="https://www.instagram.com/cosgridnetworks"
            target="_blank" rel="noopener noreferrer">Instagram</a></p>
</section>