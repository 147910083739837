<!-- <section id="openingPosition">
    <div class="container pt-5">
        <div class="row text-center mt-5 mb-5">
            <div class="col-sm-12 pb-5">
                <h1 class="fw-bold">Sitemap</h1>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 mt-3 text-center text-sm-left mt-5 pb-5 mt-sm-0">
                <div class="col-md pt-5 pt-md-0 text-center text-md-left">
                    <ul class="x1">
                        <h5 class="text-strong"><strong>Products</strong></h5>
                        <li *ngFor="let item of productsLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>

                <div class="col-md pt-5 pt-md-0 text-center pb-0 pb-md-5 pb-lg-5 text-md-left">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Components</strong></h5>
                        <li *ngFor="let item of componentsLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>
                <div class="col-md pt-5 pt-md-0 pb-0 pb-lg-5 text-center text-md-left">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Resources</strong></h5>
                        <li *ngFor="let item of usecasesLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>

                <div class="col-md text-center text-md-left pb-5">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Industries</strong></h5>
                        <li *ngFor="let item of industriesLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>

                <div class="col-md text-center text-md-left pb-5">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Company</strong></h5>
                        <li *ngFor="let item of companyLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>

                <div class="col-md pt-5 pt-lg-0 text-center text-md-left">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Partners</strong></h5>
                        <li *ngFor="let item of partnersLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>

                <div class="col-md text-center text-md-left pb-5">
                    <ul class="list-group">
                        <h5 class="text-strong"><strong>Careers</strong></h5>
                        <li *ngFor="let item of careersLinks">
                            <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="container">
    <div style="height: 100px"></div>
    <h1 class="text-center fs-3 fw-bold">COSGrid Networks</h1>
    <h2 class="fs-3 fw-bold mt-5 text-center text-lg-left">Products</h2>
    <div class="row mt-3 d-flex justify-content-center align-items-center">
        <div class="col-10 col-sm-5 col-lg-3" *ngFor="let item of productLinks">
            <div class="text-white p-3 rounded-3 my-2" style="background-color: #59a9fb"
                [style]="'height:' + item.height + 'px'">
                <h3 class="fs-5 fw-bold">{{ item.title }}</h3>
                <ul style="list-style-type: disc; padding-left: 1rem; margin-bottom: 0rem" class="mt-3 text-white">
                    <li *ngFor="let link of item.child">
                        <a [routerLink]="link.link" [fragment]="link.fragment" class="text-white">{{ link.title }}</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="item.title == 'SD-WAN'" class="text-white p-3 rounded-3 mt-3" style="background-color: #59a9fb">
                <h3 class="fs-5 fw-bold">ATP</h3>
                <ul style="list-style-type: disc; padding-left: 1rem; margin-bottom: 0rem" class="mt-3 text-white">
                    <li>
                        <a [routerLink]="additionalLinks[0]" class="text-white">NDR</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="item.title == 'SASE'" class="text-white p-3 rounded-3 mt-3" style="background-color: #59a9fb">
                <h3 class="fs-5 fw-bold">Firewall</h3>
                <ul style="list-style-type: disc; padding-left: 1rem; margin-bottom: 0rem" class="mt-3 text-white">
                    <li>
                        <a [routerLink]="additionalLinks[1]" class="text-white">COSGrid Firewall</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <h1 class="mt-4 fs-3 fw-bold mt-5 text-center text-lg-left">Resources By Sections</h1>
    <div class="row mt-3 d-flex justify-content-center align-items-center">
        <div class="col-10 col-sm-5 col-lg-3" *ngFor="let item of resourcesBySections">
            <div class="text-white p-3 rounded-3 my-2" style="background-color: #59a9fb"
                [style]="'height:' + item.height + 'px'">
                <h3 class="fs-5 fw-bold">{{ item.title }}</h3>
                <ul style="list-style-type: disc; padding-left: 1rem; margin-bottom: 0rem" class="mt-3 text-white">
                    <li *ngFor="let link of item.child">
                        <a style="cursor: pointer" [routerLink]="link.link.includes('http') ? null : link.link"
                            [fragment]="link.fragment" (click)="navigationClicked(link.link)" class="text-white">{{
                            link.title }}</a>
                    </li>
                </ul>
            </div>
            <div *ngIf="item.title == 'Industries'" class="text-white p-3 rounded-3 mt-3"
                style="background-color: #59a9fb">
                <h3 class="fs-5 fw-bold">Partners</h3>
                <ul style="list-style-type: disc; padding-left: 1rem; margin-bottom: 0rem" class="mt-3 text-white">
                    <li>
                        <a class="text-white" [routerLink]="[partnersLinks[1].link]" fragment="becomePartner">Become a partner</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- <section id="openingPosition">
    <div class="container">
        <div class="mainTitle">
            <h1 class="text-center pt-2">Sitemap</h1>
        </div>
        <div class="sectitle">
            <h3 class="text-center text-blue pt-2">COSGrid Networks</h3>
        </div>
        <div class="cardContainer">
            <div class="card">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Products</strong></h5>
                    <li class="text-blue">Secure Access Service Edge</li>
                    <li *ngFor="let item of SASLinks">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">Security Service Edge</li>
                    <li *ngFor="let item of SSELinks">
                        <a
                            class="text-blue"
                            [routerLink]="item.link"
                            [fragment]="item?.frag"
                            [innerHTML]="item.name"
                        ></a>
                    </li>
                    <li class="text-blue">Software Defined WAN</li>
                    <li *ngFor="let item of SDLinks">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">Advanced Threat Protection</li>
                    <li *ngFor="let item of NDRLinks">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="card">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Components</strong></h5>
                    <li *ngFor="let item of componentsLinks">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="card">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Resources</strong></h5>
                    <li class="text-blue">Documentation</li>
                    <li *ngFor="let item of DOCLinks">
                        <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">Use Cases</li>
                    <li *ngFor="let item of UseLink">
                        <a
                            class="text-blue"
                            [routerLink]="item.link"
                            [fragment]="item?.frag"
                            [innerHTML]="item.name"
                        ></a>
                    </li>
                    <li class="text-blue">Library</li>
                    <li *ngFor="let item of LibraryLinks">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">Blogs</li>
                    <li *ngFor="let item of WhiteLink">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">Meida</li>
                    <li *ngFor="let item of MediLink">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li class="text-blue">FAQ'S</li>
                    <li *ngFor="let item of FAQLink">
                        <a class="text-blue" [routerLink]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="card" style="height: 210px">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Industries</strong></h5>
                    <li *ngFor="let item of industriesLinks">
                        <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="card" style="height: 210px">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Company</strong></h5>
                    <li *ngFor="let item of companyLinks">
                        <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                    <li *ngFor="let item of careersLinks">
                        <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
            <div class="card" style="height: 210px">
                <ul class="x1">
                    <h5 class="text-strong text-center pb-3 pt-3"><strong>Partners</strong></h5>
                    <li *ngFor="let item of partnersLinks">
                        <a class="text-blue" [href]="item.link" [innerHTML]="item.name"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> -->