import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselListComponent } from './carousel-list/carousel-list';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { FeaturesListComponent } from './features-list/features-list.component';
import { FooterComponent } from './footer/footer.component';
import { ImageList } from './image-list/image-list';
import { ImagePoints } from './image-points/image-points.component';
import { MobilefooterComponent } from './mobilefooter/mobilefooter.component';
import { MobileNavbarComponent } from './mobilenav/mobilenav.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProblemListComponent } from './problem-list/problem-list.component';
import { NguCarouselModule } from '@ngu/carousel';
import { RouterModule } from '@angular/router';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { SliderListComponent } from './slider-list/slider-list';
import { TableList } from './table-list/table-list';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule } from '@angular/forms';
import { AssociationComponent } from './association/association.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CarouselComponent } from './carousel/carousel.component';




@NgModule({
  declarations: [
    CarouselListComponent,
    CookieConsentComponent,
    FeaturesListComponent,
    FooterComponent,
    ImageList,
    ImagePoints,
    MobilefooterComponent,
    MobileNavbarComponent,
    NavbarComponent,
    ProblemListComponent,
    ResourceListComponent,
    SliderListComponent,
    TableList,
    AssociationComponent,
    TestimonialsComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    NguCarouselModule,
    RouterModule,
    MatTabsModule,
    FormsModule
  ],
  exports: [
    CarouselListComponent,
    CookieConsentComponent,
    FeaturesListComponent,
    FooterComponent,
    ImageList,
    ImagePoints,
    MobilefooterComponent,
    MobileNavbarComponent,
    NavbarComponent,
    ProblemListComponent,
    ResourceListComponent,
    SliderListComponent,
    TableList,
    AssociationComponent,
    CommonModule,
    NguCarouselModule,
    RouterModule,
    MatTabsModule,
    FormsModule,
    TestimonialsComponent,
    CarouselComponent
  ]
})
export class SharedModule { }
