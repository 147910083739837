import { MyEmitterService } from './../../app-emitter.service';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import IRouteLinkItem, {
    INavItem,
    NavProductList,
    NavProductDetails,
    NavIndustryList,
    NavResourceDetails,
    ProductSolution,
    Col2,
    Resources,
} from 'src/app/core/models/route.model';
import { RouteConstants } from '../../config/route.constants';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HostListener } from '@angular/core';
// import { CompanyprofilepopupComponent } from '../../pages/companyprofilepopup/companyprofilepopup.component';
// import { FlyerpopupComponent } from 'src/app/pages/flyerpopup/flyerpopup.component';
import { USECASES } from 'src/app/data/usecases';
import { NguCarouselConfig } from '@ngu/carousel';
import { link } from 'fs';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    sigFragment: any = '#sig';
    carouselSliderList = [
        '14 days Free Trail - <a href="https://cosgrid.net/auth/social-signup" target="_blank" class="nav-top-a" >Avail Now</a> - No Card Required',
        'Assisting your cybersecurity efforts- <a href="/company/contacts">Talk to our team</a> to understand your Cyber resiliency Standpoint',
        'Why Basic SOC2 /ISO Compliance and MFA not enough for cybersecurity - <a href="https://cdn.cosgrid.com/website/strapi/soc2%20mfa%20ZTNA%20report%20-COSGrid__b8929bdd-a7e3-4e8c-a946-8c1b7c0ee4e3.pdf" target="_blank" class="nav-top-a">Read the report</a>'
    ]
    carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1100,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 4000,
        },
        loop: true,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
    @HostListener('window:scroll', ['$event'])
    isHovered1: any = 0;
    isHovered2: any = 0;
    isHovered3: any = 0;
    isHovered4: any = 0;
    isHovered5: any = 0;
    titleProduct: string = '';
    homeNavs: IRouteLinkItem[] = [
        {
            link: '',
            name: '',
        },
    ];
    image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';

    products = {
        products: [
            {
                title: 'Networking',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                        name: 'Secure Software Defined WAN - (SD-WAN)'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                        name: 'NFRXG Firewall- COSGrid Next Generation Firewall'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                        name: 'IoT Gateway - Secure IoT Connectivity with 5G'
                    }
                ]
            },
            {
                title: 'Threat Protection',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                        name: 'Zero Trust Network Access (ZTNA)'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'Cloud Firewall - Firewall As a Service (FWaas)',
                        fragment: 'FWaas'
                    },
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'SIG - Secure Web Gateway (SWG)',
                        fragment: 'sig'
                    }
                ]
            },
            {
                title: 'SASE - Secure Access Service Edge',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                        name: 'Z3 SASE - Secure, Fast Internet & Saas Access'
                    }
                ]
            },
            {
                title: 'Application & Data Protection',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                        name: 'Zero Trust Network Access (ZTNA 2.0)'
                    }
                ]
            },
            {
                title: 'Detection & Response',
                links: [
                    {
                        link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                        name: 'NetShield NDR - AI/ML Big data NDR'
                    }
                ]
            }
        ],
        components: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}`,
                name: 'ReFlex Edge - Gateway hardware Device '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}`,
                name: 'COSGrid Guider - Management, Monitoring and orchestration '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}`,
                name: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}`,
                name: 'SAR- SDS - Security Analyzer and Responder- AI/ML Big data '
            },
            // {
            //     link: ``,
            //     name: 'MZA/SASE Client - Desktop App Client extends to endpoint client devices '
            // },
            // {
            //     link: ``,
            //     name: 'MZA Mediator - Optimized Access to cloud & Saas Apps '
            // }
        ],
        // platform: [
        //     {
        //         link: ``,
        //         name: 'Architecture '
        //     },
        //     {
        //         link: ``,
        //         name: 'Deployment '
        //     }
        // ],
    }

    solutions = [
        {
            title: 'SASE',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Secure Digital Transformation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Workforce Transformation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Improved Security Posture'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'User Experience Enhancement'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Multi-vendor Data integration'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Comprehensive Visibility & control'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Zero Trust SASE'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Attack Surface Reduction'
                },
            ]
        },
        {
            title: 'SD-WAN & Firewall',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Centralized Management'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Mpls Migration To SD-Wan'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Secure Branch Internet Access'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                    name: 'Intrusion Detection & Prevention'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Saas Acceleration'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Improved Bandwidth Utilization'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Digital Experience'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                    name: 'On Prem & Hybrid Security'
                },
            ]
        },
        {
            title: 'Zero Trust Access',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'VPN Alternative /Cloud VPN'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Secure Users & Data'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Secure Remote Access'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Cloud Workload Protection'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Third Party Access Control'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Compliance Management'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Zero Trust Access- IoT/OT'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Micro Segmentation'
                },
            ]
        },
        {
            title: 'Advanced Threat Protection',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Threat Detection and Mitigation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Network Traffic Analysis'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Endpoint Threat Analytics'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Insider Threat Detection'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Data Exfiltration Prevention'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Network Visibility & Threat Hunting'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Forensics Investigation'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'Ransomware Protection'
                },
            ]
        },
    ]

    industries = [
        {
            links: [
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}`,
                    name: 'Banking and Financial Services '
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}`,
                    name: 'Healthcare'
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}`,
                    name: 'Retail'
                },
                // {
                //     link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
                //     name: 'Hospitality'
                // },
            ]
        },
        {
            links: [
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.ITITES}`,
                    name: 'IT/ ITES '
                },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}`,
                    name: 'Manufacturing'
                },
                // {
                //     link: ``,
                //     name: 'Education'
                // },
                {
                    link: `${RouteConstants.INDUSTRIES}/${RouteConstants.LOGISTICS}`,
                    name: 'Logistics'
                },
            ]
        }
    ]

    partners = [
        {
            links: [
                {
                    link: `${RouteConstants.PARTNERS}`,
                    name: 'Partner Program'
                },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                    name: 'Become a Partner',
                    fragment: 'becomePartner'
                },

            ],
        },
        {
            links: [
                // {
                //     link: ``,
                //     name: 'Partner Portal'
                // },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                    name: 'Industry Partners',
                    fragment: 'industry_partners'
                },
                {
                    link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                    name: 'Find a Partner',
                    fragment: 'becomePartner'
                },
            ]
        }
    ]

    resources = [
        {
            title: 'Resources',
            class: 'col-5',
            links: [
                {
                    link: `https://docs.cosgrid.com/`,
                    name: 'MicroZAccess ZTNA Documentation',
                    external: true
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
                    name: 'SD-WAN Concepts',
                    external: false
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
                    name: 'Usecase',
                    external: false
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.ZTNA}`,
                    name: 'Zero Trust Network Access Concepts'
                },
                // {
                //     link: ``,
                //     name: 'What is SASE ?'
                // },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.BLOGS}`,
                    name: 'Blogs',
                    external: false
                },
                {
                    link: `${RouteConstants.RESOURCES}/case-studies`,
                    name: 'Case Studies & Reports',
                    external: false
                },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.WHITE_PAPERS}`,
                    name: 'White Paper & Datasheets',
                    external: false
                },
                // {
                //     link: ``,
                //     name: 'ROI Calculator'
                // },
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.FAQS}`,
                    name: 'Frequently Asked Questions',
                    external: false
                },
            ],
        },
        {
            title: 'Integrations',
            class: 'col-3',
            links: [
                {
                    link: `https://docs.cosgrid.com/identity-providers/azure-ad`,
                    name: 'Azure AD',
                    external: true
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/okta`,
                    name: 'Okta',
                    external: true
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/google-suite`,
                    name: 'Google Suite',
                    external: true
                },
                {
                    link: `https://docs.cosgrid.com/identity-providers/one-login`,
                    name: 'One Login',
                    external: true
                }
            ],
        },
        {
            title: 'Media',
            class: 'col-4',
            links: [
                {
                    link: `${RouteConstants.RESOURCES}/${RouteConstants.MOMENTS}`,
                    name: 'Gallery'
                },
                {
                    link: `${RouteConstants.RESOURCES}/news-${RouteConstants.PRESS_RELEASE}`,
                    name: 'News & Press Release'
                },
            ]
        }
    ]

    company = [
        {
            links: [
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.ABOUTUS}`,
                    name: 'About us'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.AWARDS}`,
                    name: 'Awards'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
                    name: 'Events'
                },
                // {
                //     link: ``,
                //     name: 'Our Customers'
                // },
            ],
        },
        {
            links: [
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
                    name: 'Contact us'
                },
                {
                    link: `${RouteConstants.COMPANY}/${RouteConstants.CAREERS}`,
                    name: 'Careers'
                },
                // {
                //     link: ``,
                //     name: 'Current Openings'
                // },
            ]
        }
    ]











    productAndSolutions: ProductSolution = {
        products: [
            {
                link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
                name: 'ReFlex Z3 SD-WAN - Secure Connectivity with 4G/5G '
            },
            {
                link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
                name: 'MicroZAccess - Zero Trust Network Access (ZTNA) '
            },
            {
                link: `/${RouteConstants.PRODUCTS}/firewall/.`,
                name: 'NFRXG Firewall- COSGrid Next Generation Firewall '
            },
            {
                link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
                name: 'NetShield NDR - Network Detection Response '
            },
            {
                link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}#`,
                name: 'COSGrid SWG/SIG - Secure Web Gateway ',
                fragment: 'sig'
            },
            {
                link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'COSGrid Z3 SASE - Secure, Fast Internet & Saas Access '
            }
        ],
        solutions: {
            left: [
                {
                    link: ``,
                    name: 'Cloud Workload Protection '
                },
                {
                    link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
                    name: 'VPN Alternative /Cloud VPN '
                },
                {
                    link: ``,
                    name: 'Secure Users & Data '
                },
                {
                    link: ``,
                    name: 'Secure Remote Access '
                },
            ],
            right: [
                {
                    link: ``,
                    name: 'Advanced Threat Protection '
                },
                {

                    link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
                    name: 'OT/IoT Security '
                },
                {
                    link: `${RouteConstants.PRODUCTS}/firewall`,
                    name: 'On Prem Security '
                },
                {
                    link: `${RouteConstants.PRODUCTS}/firewall`,
                    name: 'Digital Experience '
                },
            ]
        },
        platform: [
            {
                link: ``,
                name: 'Architecture '
            },
            {
                link: ``,
                name: 'Deployment '
            }
        ],
        components: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
                name: 'ReFlex Edge - Gateway hardware Device '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
                name: 'COSGrid Guider - Management, Monitoring and orchestration '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
                name: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps '
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}/.`,
                name: 'SAR- SDS - Security Analyzer and Responder- AI/ML Big data '
            },
            {
                link: ``,
                name: 'MZA/SASE Client - Desktop App Client extends to endpoint client devices '
            },
            {
                link: ``,
                name: 'MZA Mediator - Optimized Access to cloud & Saas Apps '
            }
        ]
    }




    // PRODUCTS DROPDOWN NAVS

    // productsNavs: INavItem[] = [
    //     {
    //         heading: 'Secure SD-WAN',
    //         children: [
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECURESDWAN_REFLEX_HYBWAN}`,
    //                 name: 'RefleX Hyb-WAN',
    //             },
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECURESDWAN_REFLEX_INWAN}`,
    //                 name: 'RefleX In-WAN',
    //             },
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SDINTERNETEDGE}`,
    //                 name: 'SD-Internet Edge',
    //             },
    //         ],
    //     },
    //     {
    //         heading: 'VPN',
    //         children: [
    //             {
    //                 link: `${RouteConstants.PRODUCTS}/${RouteConstants.SECUREACCESS}`,
    //                 name: 'COSGrid RefleX RPN',
    //             },
    //         ],
    //     },
    //     {
    //         heading: 'NDR',
    //         children: [
    //             {
    //                 link: RouteConstants.NETSHIELD}`,
    //                 name: 'COSGrid NetShield',
    //             },
    //         ],
    //     },
    // ];
    productNavList: NavProductList[] = [
        {
            name: 'Security Service Edge (SSE)',
            id: 2,
        },
        {
            name: 'Secure SD-WAN',
            id: 3,
        },
        {
            name: 'Firewall',
            id: 6
        },
        {
            name: 'Secure Access Service Edge',
            id: 1,
        },
        {
            name: 'Advanced Threat Protection',
            id: 4,
        },
        {
            name: 'Components',
            id: 5,
        },
    ];
    navProductDetails1: NavProductDetails[] = [
        {
            id: 1,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/cosgrid_SASE.webp',
            // desc: "COSGrid SASE defines safe, fast internet and SaaS access with the industry's most comprehensive zero trust platform.",
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            desc: '',
            title: 'COSGrid SASE - Secure, Fast Internet & Saas Access',
        },
    ];
    navProductDetails6: NavProductDetails[] = [
        {
            id: 6,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/ReFlex_WAN.webp',
            // desc: 'ReFleX-WAN is a Cloud Managed Software Defined platform designed to transform WAN into a cloud-centric world. Built to deliver the simplicity, flexibility and cost-effective WANs to Branch offices, DC locations & Cloud.',
            link: `/${RouteConstants.PRODUCTS}/firewall/.`,
            exec: '',
            desc: '',
            title: 'COSGrid Next Generation Firewall (NGFW)',
        }
    ]
    navProductDetail3: NavProductDetails[] = [
        {
            id: 3,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/ReFlex_WAN.webp',
            // desc: 'ReFleX-WAN is a Cloud Managed Software Defined platform designed to transform WAN into a cloud-centric world. Built to deliver the simplicity, flexibility and cost-effective WANs to Branch offices, DC locations & Cloud.',
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
            exec: '',
            desc: '',
            title: 'ReFlex Z3 SD-WAN - Secure  Connectivity with 4G/5G',
        },
        // {
        //     id: 3,
        //     image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/ReFleX_WAN_5G.webp',
        //     // desc: 'ReFleX Z3 5G SD WAN is an advanced upgrade of COSGrid RefleX WAN Powered with 5G with enhanced zero trust security and Zero Glitch connectivity along with Zero Touch deployment capabilities',
        //     link: `https://cdn.cosgrid.com/website/strapi/CG%20NFRXG%20%20datasheet_57c64fbc-cbea-4f8d-bdad-3145a66718d3.pdf`,
        //     exec: '',
        //     desc: '',
        //     title: 'COSGrid NFRG - Next Generation Firewall',
        // },

        // {
        //     id: 3,
        //     image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/ReFleX_WAN_5G.webp',
        //     // desc: 'ReFleX Z3 5G SD WAN is an advanced upgrade of COSGrid RefleX WAN Powered with 5G with enhanced zero trust security and Zero Glitch connectivity along with Zero Touch deployment capabilities',
        //     link: `https://cdn.cosgrid.com/website/strapi/IG4xG%20Datasheet_9193f609-f484-4cbb-8cd7-5c6e7c2d3a16.pdf`,
        //     exec: '',
        //     desc: '',
        //     title: 'COSGrid  IG4XG - IoT Gateway',
        // },
    ];
    navProductDetail2: NavProductDetails[] = [
        {
            id: 2,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/MZA.webp',
            // desc: 'MicroZAccess enables least privileged access to resources through a mutually authenticated peer-to-peer encrypted Overlay network.',
            desc: '',
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
            title: 'MicroZAccess - Secure Private Access (ZTNA)',
        },
        {
            id: 2,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/SIG.webp',
            // desc: 'SecureGateway is a Secure Internet Gateway (SIG) solution that provides Firewall as a Service (FWaaS). It offers URL and content-based policies, granular app controls, and advanced visibility and reporting features.',
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            // title: 'Secure Internet Gateway (SIG)',
            title: 'Secure Gateway - Secure Internet Gateway (SIG)',
            desc: '',
            frag: 'sig',
        },
        {
            id: 2,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/Secure_Connect.webp',
            // desc: 'Secure Connect is a desktop application that creates a secure and encrypted connection from remote devices to the distributed systems with application segmentation.',
            link: `/${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
            // title: 'Virtual Private Network(VPN)',
            title: 'Secure Connect - Virtual Private Network(VPN)',
            desc: '',
            exec: `${this.productClick(
                '/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECUREACCESS}/.',
            )}`,
        },
    ];
    navProductDetail4: NavProductDetails[] = [
        {
            id: 4,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/NetShield_NDR.webp',
            // desc: 'NetShield NDR plays a crucial role in cybersecurity measures by actively monitoring real-time traffic flows and comparing them against historical network connection metadata.',
            link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
            exec: `${this.componentClick('/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.')}`,
            title: 'NetShield - Network Detection & Response (NDR)',
            desc: '',
        },
    ];
    navProductDetails5: NavProductDetails[] = [
        {
            id: 5,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/reflexedge.png',
            // desc: 'COSGrid RefleX Edge is an intelligent SD-WAN gateway Deployed at Branch, cloud and data center that delivers superior application performance and Edge security.',
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
            title: 'ReFlex Edge - SD-WAN Gateway hardware Device',
            desc: '',
        },
        {
            id: 5,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/guider.png',
            // desc: 'COSGrid Guider provides centralized management, monitoring and orchestration capabilities needed to deliver software-defined network and security services from a single console.',
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
            title: 'COSGrid Guider - Management, Monitoring and orchestration',
            desc: '',
        },
        {
            id: 5,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/cloud_nf.png',
            // desc: 'COSGrid CloudNF Hub is deployed at tier 1 network at Central location or on the cloud offering required scalability, redundancy, and on-demand flexibility. COSGrid CloudNF Hub delivers optimized access to cloud and Business SaaS applications.',
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
            title: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps',
            desc: '',
        },
        {
            id: 5,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/sar.png',
            // desc: 'COSGrid SAR provides Software Defined Security (SDS) through a big-data & ML security analytics platform hosted in the Cloud and on-prem that provides real-time detection of zero-day attacks and anomaly detection through baselined behaviors of endpoints, users and IoT devices.',
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}/.`,
            title: 'SAR- SDS - Security Analyser and Responder',
            desc: '',
        },
        {
            id: 5,
            image: 'https://cdn.cosgrid.com/website/assets3/Header/navbar/secure_connect.png',
            // desc: 'COSGrid Secure Connect is a desktop application (Windows, macOS, and Linux devices) that runs on and extends COSGrid Secure Connect to client devices (i.e., Windows devices, MacOS devices, iOS devices, Linux devices, and smartphones)',
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
            title: 'Secure connect - Desktop VPN Client & extends to client devices',
            desc: '',
        },
    ];
    InitialNavProductDetails: NavProductDetails[] = this.navProductDetail2;

    navResourceList: NavProductList[] = [
        {
            name: 'Library',
            id: 6,
        },
        {
            name: 'Documentation',
            id: 1,
        },
        {
            name: 'Use-cases',
            id: 3,
        },

        {
            name: 'Blogs',
            id: 5,
        },
        {
            name: 'Media',
            id: 4,
        },
        {
            name: 'FAQs',
            id: 2,
        },
    ];
    navResourceDetail1: NavResourceDetails[] = [
        {
            name: 'MicroZAccess',
            desc: 'Least privileged access to resources through a mutually authenticated peer-to-peer encrypted Overlay network.',
            id: 1,
            link: 'https://docs.cosgrid.com/',
        },
    ];
    navResourcsDetail2: NavResourceDetails[] = [
        {
            name: 'Frequently Asked Questions',
            desc: 'Additional Information on the Products and services offered by COSGrid Networks',
            id: 2,
            link: `${RouteConstants.FAQS}/.`,
        },
    ];
    navResouceDetails3: NavResourceDetails[] = [
        {
            name: 'ReFleX SD-WAN',
            desc: 'Explore the Use Cases of ReFleX SD-WAN',
            id: 3,
            link: `${RouteConstants.USECASES}`,
        },
        {
            name: 'MicroZAccess',
            desc: 'Explore the Use Cases of MicroZAccess',
            id: 3,
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}/.`,
            frag: 'usecases',
        },
    ];
    navResourceDetails4: NavResourceDetails[] = [
        {
            name: 'Event Photographs',
            desc: 'A gallery of photos from recent events, product launches, and industry conferences.',
            id: 4,
            link: `${RouteConstants.EVENTS}/.`,
        },
        {
            name: 'Moments',
            desc: 'A collection of recent moments of COSGrid Networks',
            id: 4,
            link: `${RouteConstants.RESOURCES}/${RouteConstants.GALLERY}/.`,
        },
    ];
    navResouceDetails5: NavResourceDetails[] = [
        {
            name: 'Blogs',
            desc: 'A regularly updated section of news, insights, and thought leadership on a variety of topics.',
            id: 5,
            link: `${RouteConstants.BLOGS}/.`,
        },
    ];

    navResourcsDetail6: NavResourceDetails[] = [
        {
            name: 'SD-WAN Concepts',
            desc: 'A complete overview of SD-WAN, its need, Industry standards, benefits, comparison matrixes, Vendor selections and key takeaways',
            link: `/${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
            id: 6,
        },
        {
            name: 'Whitepaper and Datasheets',
            desc: 'ReFleX SD-WAN|NetShield NDR|MicroZAccess|COSGrid SASE',
            link: `/${RouteConstants.RESOURCES}/whitepaper-datasheets/.`,
            id: 6,
        },
    ];

    IntialNavResouceDetails: NavResourceDetails[] = this.navResourceDetail1;

    navIndustryLists1: NavIndustryList[] = [
        {
            title: 'Financial Services',
            desc: 'Financial Services are a prime target for ransomware and other cyber attacks. Learn how MicroZAccess can help.',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
        },
        {
            title: 'Healthcare',
            desc: 'Protect patient data and prevent any disruptions that could affect patient care with ReFleX SD-WAN.',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
        },
        {
            title: 'Manufacturing',
            desc: 'Explore how MicroZAccess provides secured network access that prevents cyber attack disruptions.',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
        },
    ];
    navIndustryLists2: NavIndustryList[] = [
        {
            title: 'Restaurants/Hospitality',
            desc: 'Protect your customers and services from advanced cyber attacks. Learn how NetShield can help you.',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
        },
        {
            title: 'Retail',
            desc: 'Protect your brand and ERP from cyber threats. Learn how COSGrid SASE can help you.',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
        },
    ];

    productNav: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/.`,
            name: 'Products',
        },
    ];
    products1Navs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SECURESDWAN_REFLEX_HYBWAN}/.`,
            name: 'RefleX Hyb-WAN',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SECURESDWAN_REFLEX_INWAN}/.`,
            name: 'RefleX In-WAN',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.FIVEG_SDWAN}/.`,
            name: 'Z3 5G SD-WAN',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SDINTERNETEDGE}/.`,
            name: 'SD-Internet Edge',
        },
    ];
    products2Navs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
            name: 'COSGrid RefleX RPN',
        },
    ];
    products3Navs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
            name: 'COSGrid NetShield',
        },
    ];
    ZTNANavs: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}/.`,
            name: 'COSGrid MicroZAccess',
        },
    ];

    componentsNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
            name: 'COSGrid RefleX-Edge',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
            name: 'COSGrid Guider',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
            name: 'COSGrid CloudNF Hub',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
            name: 'COSGrid Secure Connect',
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}/.`,
            name: 'COSGrid SAR',
        },
    ];

    sdWanNav: IRouteLinkItem = {
        link: `${RouteConstants.SD_WAN}/.`,
        name: 'What is SD-WAN?',
    };

    RouteRedirectToIndustries() {
        this.router.navigate([this.INDUSTRIESLINK]);
    }
    RouteRedirectToUsecaseNavs() {
        this.router.navigate([this.USECASESLINK]);
    }

    // SOLUTIONS DROPDOWN NAVS
    // tslint:disable-next-line: member-ordering

    USECASESLINK = `${RouteConstants.USECASES}/.`;

    INDUSTRIESLINK = `${RouteConstants.INDUSTRIES}/.`;
    usecasesNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.USECASES}#eap`,
            name: 'App Performance',
        },

        {
            link: `${RouteConstants.USECASES}#ssit`,
            name: 'Secure Branch Internet Access',
        },
        {
            link: `${RouteConstants.USECASES}#wa`,
            name: 'SaaS Acceleration',
        },
        {
            link: `${RouteConstants.USECASES}#cm`,
            name: 'Centralised Management',
        },
        {
            link: `${RouteConstants.USECASES}#sra`,
            name: 'Secure Remote Access',
        },
        {
            link: `${RouteConstants.USECASES}#vavob`,
            name: 'Zero-glitch Voice and Video',
        },
        {
            link: `${RouteConstants.USECASES}#mmsw`,
            name: 'MPLS Migration To SD-WAN',
        },
    ];
    industryNav: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.INDUSTRIES}/.`,
            name: 'Industries',
        },
    ];

    // PARTNERS
    partnersNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PARTNERS}#partnersProgram`,
            name: "Partner's Portal",
        },
        {
            link: `${RouteConstants.PARTNERS}#becomePartner`,
            name: 'Become a partner',
        },
    ];

    // RESOURCES
    resourceNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.SD_WAN}/.`,
            name: 'SD-WAN Concepts',
        },
        {
            link: `${RouteConstants.BLOGS}/.`,
            name: 'Blogs',
        },
        {
            link: `${RouteConstants.COMPANYPROFILE}/.`,
            name: 'Company Profile',
        },
        {
            link: `#`,
            name: 'Product Features',
        },
        {
            link: `#`,
            name: 'Company Brochure',
        },
        {
            link: `#`,
            name: 'Datasheets',
        },
        {
            link: `${RouteConstants.GALLERY}/.`,
            name: 'Gallery',
        },
        {
            link: `${RouteConstants.FAQS}/.`,
            name: 'FAQ',
        },
    ];

    // industriesNavs: IRouteLinkItem[] = [
    //     {
    //         link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
    //         name: 'Financial Services',
    //     },
    //     {
    //         link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
    //         name: 'Healthcare',
    //     },
    //     {
    //         link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
    //         name: 'Manufacturing',
    //     },
    //     {
    //         link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
    //         name: 'Restaurants/Hospitality',
    //     },
    //     {
    //         link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
    //         name: 'Retail',
    //     },
    // ];

    // navSolutionLists1: NavIndustryList[] = [
    //     {
    //         title: 'Secure & Reliable Connectivity (SD-WAN)',
    //         desc: '',
    //         link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
    //     },
    //     {
    //         title: 'Zero Trust Network Access',
    //         desc: '',
    //         link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
    //     },
    //     {
    //         title: 'VPN Alternative',
    //         desc: '',
    //         link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
    //     },
    //     {
    //         title: 'Cloud Security (WAF)',
    //         desc: '',
    //         link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
    //     },
    //     {
    //         title: 'Cloud Secure Web gateway ',
    //         desc: '',
    //         link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
    //     },
    // ];

    solutionNavs: IRouteLinkItem[] = [
        {
            name: 'Secure & Reliable Connectivity',
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
        },
        // {
        //     name: 'On Prem Connectivity',
        //     link: 'https://cdn.cosgrid.com/website/strapi/Re_Fle_X_SD_WAN_Product_Brief_a5bd7e785f.pdf',
        // },
        {
            name: 'Zero Trust Network Access',
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
        },
        // {
        //     name: 'On Prem Security/Firewall',
        //     link: 'https://cdn.cosgrid.com/website/strapi/CG%20NFRXG%20%20datasheet_57c64fbc-cbea-4f8d-bdad-3145a66718d3.pdf',
        // },
        {
            name: 'VPN Alternative/Cloud VPN',
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
        },
        // {
        //     name: 'Secure IoT Connectivity',
        //     link: 'https://cdn.cosgrid.com/website/strapi/IG4xG%20Datasheet_9193f609-f484-4cbb-8cd7-5c6e7c2d3a16.pdf',
        // },
        {
            name: 'Cloud Security',
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
        },
        {
            name: 'On Prem Security',
            link: `${RouteConstants.PRODUCTS}/firewall`
        },
        {
            name: 'Web Filtering & IPS',
            link: `${RouteConstants.PRODUCTS}/firewall`
        },
        {
            name: 'IOT / OT Security',
            link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
        },
    ];
    industriesNavs: IRouteLinkItem[] = [
        {
            name: 'Financial Services/BFSI',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
        },
        {
            name: 'Restaurants/Hospitality',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
        },
        {
            name: 'Healthcare',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
        },
        {
            name: 'Retail',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
        },
        {
            name: 'Manufacturing',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
        },
        {
            name: 'IT/ITES',
            link: `${RouteConstants.INDUSTRIES}/${RouteConstants.ITITES}/.`,
        },
        // {
        //     name: 'Telecommunications',
        //     link: `${RouteConstants.CONTACTS}/.`,
        // },
        // {
        //     name: 'Logistics',
        //     link: `${RouteConstants.CONTACTS}/.`,
        // },
    ];
    resourcesNavs: IRouteLinkItem[] = [
        {
            link: 'https://docs.cosgrid.com/',
            name: 'MicroZAccess Documentation',
        },
        {
            link: `resources/${RouteConstants.SD_WAN}/.`,
            name: 'SD-WAN Concepts',
        },
        {
            link: `resources/${RouteConstants.ZTNA}/microzaccess.`,
            name: 'Zero Trust Network Access Concepts',
        },
        {
            link: `${RouteConstants.RESOURCES}/case-studies/.`,
            name: 'Case Studies & Reports',
        },

        {
            link: `/${RouteConstants.RESOURCES}/whitepaper-datasheets/.`,
            name: 'Whitepaper and Datasheets',
        },

        {
            link: `${RouteConstants.BLOGS}/.`,
            name: 'Blogs',
        },

        {
            link: `${RouteConstants.FAQS}/.`,
            name: 'Frequently Asked Questions',
        },
    ];
    //INTEGRATIONS
    integrationNavs: IRouteLinkItem[] = [
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.AZURE_AD}`,
            name: "Azure AD"
        },
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.OKTA}`,
            name: "OKta"
        },
        {
            link: `https://docs.cosgrid.com/${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.GOOGLE_SUITE}`,
            name: "Google Suite"
        },
        {
            link: `https://docs.cosgrid.com /${RouteConstants.IDENTITY_PROVIDERS}/${RouteConstants.ONE_LOGIN}`,
            name: "One Login"
        }
    ]

    //MEDIA
    mediaNavs: IRouteLinkItem[] = [
        {
            link: `resources/${RouteConstants.GALLERY}/.`,
            name: 'Gallery',
        },
    ]
    // COMPANY
    companyNavs: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.ABOUTUS}/.`,
            name: 'About us',
        },
        {
            link: `${RouteConstants.PRESS_RELEASE}/.`,
            name: 'Press Release',
        },
        {
            link: `${RouteConstants.CONTACTS}/.`,
            name: 'Contact us',
        },
        {
            link: `${RouteConstants.CAREERS}/.`,
            name: 'Careers',
        },
        {
            link: `${RouteConstants.AWARDS}/.`,
            name: 'Awards',
        },
        {
            name: 'Events',
            link: `${RouteConstants.EVENTS}/.`,
        },
    ];

    // newsNavs: IRouteLinkItem[] = [
    //     {
    //         link: `${RouteConstants.AWARDS}/.`,
    //         name: 'Awards',
    //     },
    // ];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private emitter: MyEmitterService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        // Subscribe to the NavigationStart event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationStart) {
        //         console.log('Navigation Start:', event);
        //     }
        // });
        // // Subscribe to the NavigationEnd event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         console.log('Navigation End:', event);
        //     }
        // });
        // // Subscribe to the NavigationError event
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationError) {
        //         console.log('Navigation Error:', event);
        //     }
        // });
    }

    mediaquery: MediaQueryList;
    menutoggle: any;
    // open company profile dialogue
    // openCPDialog() {
    //     this.dialog.open(CompanyprofilepopupComponent);
    // }
    // // open flyer profile download
    // openFlyerDialog() {
    //     this.dialog.open(FlyerpopupComponent);
    // }

    ngOnInit(): void {
        this.sigFragment = encodeURIComponent('#sig');
    }
    navigate(id) {
        //console.log(id);
        this.router.navigateByUrl(id);
    }
    productClick(item) {
        this.togglefn('');
        //console.log(item);
        this.emitter.emitproductEvent(item);
    }

    componentClick(link) {
        this.togglefn('');
        this.emitter.emitcomponentEvent(link);
    }

    industriesClick(link) {
        if (link.includes('https://')) {
            window.open(link, '_blank');
        } else {
            this.togglefn('');
            this.emitter.emitIndustriesEvent(link);
        }
        // console.log(link);
    }

    isScrolled = false;

    @HostListener('window:scroll')
    onWindowScroll() {
        if (isPlatformBrowser(this.platformId)) {
            // console.log(window.pageYOffset);
            // window.pageYOffset >= 80
            //     ? (this.image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg')
            //     : (this.image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg');
            this.image = 'https://cdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';
            window.pageYOffset >= 80 ? (this.isScrolled = true) : (this.isScrolled = false);
        }
    }

    togglefn(link) {
        if (link.includes('https://')) {
            window.open(link, '_blank');
        } else {
            if (this.menutoggle == 'menu') this.menutoggle = 'close';
        }
    }
    productClickedFn(item: any) {
        if (item.link.includes('https://')) {
            window.open(item.link, '_blank');
        } else {
            if (item.id == 1) {
            } else if (item.id == 2) {
                // item?.exec;
            } else if (item.id == 3) {
                this.productClick(item);
            } else if (item.id == 4) {
                this.componentClick(item.link);
            } else {
                this.componentClick(item.link);
            }
        }
    }
    UserHoverProducts(id: number) {
        // console.log(id)
        if (id == 1) {
            this.titleProduct = 'Secure Access Service Edge';
            this.InitialNavProductDetails = this.navProductDetails1;
        } else if (id == 2) {
            this.titleProduct = '';
            this.InitialNavProductDetails = this.navProductDetail2;
        } else if (id == 3) {
            this.titleProduct = 'Software Defined WAN';
            this.InitialNavProductDetails = this.navProductDetail3;
        } else if (id == 4) {
            this.titleProduct = 'Network Detection & Response (NDR)';
            this.InitialNavProductDetails = this.navProductDetail4;
        } else if (id == 5) {
            this.titleProduct = 'Components';
            this.InitialNavProductDetails = this.navProductDetails5;
        }
        else {
            this.titleProduct = 'Firewall';
            this.InitialNavProductDetails = this.navProductDetails6
        }
    }
    UserHoverResource(data: number) {
        if (data == 1) {
            this.IntialNavResouceDetails = this.navResourceDetail1;
        } else if (data == 2) {
            this.IntialNavResouceDetails = this.navResourcsDetail2;
        } else if (data == 3) {
            this.IntialNavResouceDetails = this.navResouceDetails3;
        } else if (data == 4) {
            this.IntialNavResouceDetails = this.navResourceDetails4;
        } else if (data == 5) {
            this.IntialNavResouceDetails = this.navResouceDetails5;
        } else if (data == 6) {
            this.IntialNavResouceDetails = this.navResourcsDetail6;
        }
    }
    hoverNav(isHovered: any) {
        isHovered = 1;
    }
    leaveNav(isHovered: any) {
        isHovered = 0;
    }
}
