import gql from 'graphql-tag';

export const REPORTS_QUERY = gql`
    # STRAPI V3 - QUERY
    # query {
    #     reports {
    #         title
    #         id
    #         description
    #         thumbnail {
    #             url
    #         }
    #         idUrl
    #     }
    # }

    # STRAPI V4 - QUERY
    query {
        reports(pagination: { limit: 50 }) {
            data {
                id
                attributes {
                    Title
                    Description
                    Thumbnail {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    Document {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    idUrl
                }
            }
        }
    }
`;

export const GetReports = gql`
    query GetReports($id: ID!) {
        reports(id: $id) {
            title
            id
            description
            thumbnail {
                url
            }
        }
    }
`;
