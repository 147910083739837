import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from 'src/app/config/route.constants';
import { SdWanComponent } from './resources/sd-wan/sd-wan.component';
import { ZtnaComponent } from './resources/ztna/ztna.component';
import { BlogsComponent } from './resources/blogs/blogs.component';
import { CaseStudiesComponent } from './resources/case-studies/casestudies.component';
import { FaqsComponent } from './resources/faqs/faqs.component';
import { MomentsComponent } from './media/moments/moments.component';
import { PressReleaseComponent } from './media/press-release/press-release.component';
import { WhitepapersComponent } from './resources/whitepapers/whitepapers.component';
import { CosgridblogsComponent } from './resources/cosgridblogs/cosgridblogs.component';
import { UsecasesComponent } from './resources/usecases/usecases.component';
import { DebugRouteGuard } from 'src/app/debug-route.guard';
import { ProductLaunchComponent } from './media/press-release/product-launch/product-launch.component';
import { PressReleaseOtherComponent } from './media/press-release/press-release-other/press-release-other.component';


const routes: Routes = [
  { path: '', redirectTo: RouteConstants.SD_WAN, pathMatch: 'full' },
  { path: RouteConstants.SD_WAN, component: SdWanComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.ZTNA, component: ZtnaComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.BLOGS, component: BlogsComponent, canActivate: [DebugRouteGuard] },
  { path: `${RouteConstants.BLOGS}/:id`, component: CosgridblogsComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.WHITE_PAPERS, component: WhitepapersComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.CASE_STUDIES, component: CaseStudiesComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.FAQS, component: FaqsComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.MOMENTS, component: MomentsComponent, canActivate: [DebugRouteGuard] },
  { path: `news-${RouteConstants.PRESS_RELEASE}`, component: PressReleaseComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.USECASE, component: UsecasesComponent, canActivate: [DebugRouteGuard] },
  { path: `ndr-product-launch`, component: ProductLaunchComponent, canActivate: [DebugRouteGuard]},
  { path: `dubai-middle-east-sase`, component: PressReleaseOtherComponent, canActivate: [DebugRouteGuard]}
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }
