

<div class="row mx-2 shadow-sm">
    <!-- Swappable Div 1 -->
    <div *ngIf="headDirection === 'left'; else div2" class="col-12 col-lg-4 d-flex align-items-center justify-content-center mb-4 mb-lg-0 p-lg-5">
        <p class="fw-bold clr-blue-dark text-center h2 ">{{title}}</p>
    </div>

    <!-- Swappable Div 2 -->
    <ng-template #div2>
        <div class="col-12 col-lg-8 shadow-sm p-3">
            <div class="row d-flex justify-content-center">
                <div class='col-12 col-md-6 col-lg-4 d-flex flex-column align-items-center p-4 my-2 justify-content-between my-2 ' *ngFor="let item of data; index as i">
                    <img [src]="item.imgURL" [class]="item.width" [alt]="item?.title" loading="lazy" />
                    <p *ngIf="item.title" class="fs-6 mt-2 text-center" [innerHTML]="item.title"></p>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Swappable Div 1 -->
    <div *ngIf="headDirection === 'right'; else div2" class="col-12 col-lg-4 d-flex align-items-center justify-content-center mb-4 mb-lg-0 p-lg-5">
        <p class="fw-bold clr-blue-dark text-center h2">{{title}}</p>
    </div>
</div>
