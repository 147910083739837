<!-- <div style="height: 13vh"></div> -->
<section id="reports" *ngIf="!loading" class="pt-3">
    <p class="fw-bold clr-blue-dark text-center h2">Whitepaper and Datasheets</p>
    <app-resource-list [data]="reports" colsForRows="3" title="whitepapers"></app-resource-list>
</section>

<div
    *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee"
>
    <div style="margin: auto">
        <img
            style="display: block"
            class="logo-loading"
            src="assets/images/navbar/fLogo.svg"
            alt="Logo loading.."
            loading="lazy"
        />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    >
</div>
