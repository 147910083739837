import gql from 'graphql-tag';

const MOMENTS_QUERY = gql`
  query {
    moments(sort: "createdAt:DESC" pagination: { limit: 50 }) {
    data {
      attributes {
        momentimages {
        data {
          attributes {
            url
          }
        }
      }
    }
      
    }
    }
  }
`;

export default MOMENTS_QUERY;
