import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';

import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service'


import META_QUERY from '../../../../queries/Meta/meta.service';
import { REPORTS_QUERY } from '../../../../queries/reports/reports.service';
import { takeUntil } from 'rxjs/operators';

interface MetaTag {
    attributes: {
        URL_ID: string;
        title: string;
        description: string;
        keywords: string;
    };
}

interface MetaTagsResult {
    metaTags: {
        data: MetaTag[];
    };
}

@Component({
    selector: 'case-studies',
    templateUrl: './casestudies.component.html',
    styleUrls: ['./case-studies.component.scss'],
})


export class CaseStudiesComponent implements OnInit, OnDestroy {

    reports: any[] = [];
    loading: boolean;
    private destroy$ = new Subject<void>();
    constructor(
        private apollo: Apollo,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
    ) { }

    ngOnInit(): void {
        if (this.fetchMetaService.getData() == false) {
            let metatags: MetaTag[] = [];
            this.apollo
                .watchQuery<MetaTagsResult>({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta: MetaTag[] = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => ({
                        ...metaTag,
                    }));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['library'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData['library'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['library'].attributes.keywords,
                    });
                });
        } else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['library'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data['library'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['library'].attributes.keywords,
            });
        }

        this.loading = true;
        this.apollo
            .watchQuery({
                query: REPORTS_QUERY,
            })
            .valueChanges
            .pipe
            (takeUntil(this.destroy$))
            .subscribe((result) => {
                this.reports = (result.data as any).reports.data.map((report) => ({
                    ...report,
                    image: report?.attributes?.Thumbnail?.data[0]?.attributes?.url,
                    pdf: report?.attributes?.Document.data?.attributes?.url,
                }));
                // console.log(this.reports);
                this.reports = this.reports.filter(report => ['9', '11', '12', '15', '16', '17', '18', '19', '21'].includes(report.id))
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
