import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    localStorage: any;
    fullyLoaded = false;
    screenWidthDetermined: boolean = false;
    screenWidth: number;
    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.screenWidth = window.innerWidth;
        }
    }
    title = 'COSGrid Networks';
    router = '';

    ngOnInit() {
        this.fullyLoaded = false
        this.updateScreenWidth();
        // window.addEventListener("load", (event) => {
        //     this.fullyLoaded = true;
        //   });
        // setInterval(() => {
        //     if ((performance as any).memory) {
        //       const memory = (performance as any).memory;
        //       const jsHeapSizeLimitMB = (memory.jsHeapSizeLimit / (1024 * 1024)).toFixed(2);
        //       const totalJSHeapSizeMB = (memory.totalJSHeapSize / (1024 * 1024)).toFixed(2);
        //       const usedJSHeapSizeMB = (memory.usedJSHeapSize / (1024 * 1024)).toFixed(2);

        //       console.log(`JS Heap Size Limit: ${jsHeapSizeLimitMB} MB`);
        //       console.log(`Total JS Heap Size: ${totalJSHeapSizeMB} MB`);
        //       console.log(`Used JS Heap Size: ${usedJSHeapSizeMB} MB`);
        //     }
        //   }, 10000); // Logs every 10 seconds

    }


    @HostListener('window:load', ['$event'])
    onLoad(event: Event) {
        if (isPlatformBrowser(this.platformId))
            this.fullyLoaded = true;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (isPlatformBrowser(this.platformId))
            this.updateScreenWidth();
    }

    private updateScreenWidth() {
        if (isPlatformBrowser(this.platformId)) {
            this.screenWidth = window.innerWidth;
            this.screenWidthDetermined = true;
        }
    }
    onActivate(event) {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
            console.log(window.location.pathname);
            this.router = window.location.pathname;
        }
    }
}
