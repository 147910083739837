<div class="banner bg-light pt-5">
    <p class="text-center h2 fw-bold clr-blue-dark">Testimonials</p>
    <input *ngFor="let item of testimonials" type="radio" name="radio-btn" id="radio{{ item.id }}" />
    <div *ngFor="let item of testimonials">
        <div *ngIf="current_id == item.id" class="row p-3 p-sm-4 p-md-3">
            <div class="col-md"></div>
            <div class="col-md-7">
                <div class="container text-center">
                    <div class="col-sm text-center">
                        <!-- <img [src]="item.personImgURL" alt=".." srcset="" width="120" height="120" class="mb-3" /> -->
                        <img
                            [src]="item.companyImg"
                            width="150"
                            height="150"
                            class="img-fluid mb-3"
                            alt=""
                            loading="lazy"
                        />
                    </div>
                    <p class="fs-3">{{ item.name }}</p>
                    <p class="fs-6" [innerHTML]="item.companyname"></p>
                </div>
                <p class="text-center mb-5 pb-5 fs-6">
                    <i class="fas fa-quote-left"></i>&nbsp;&nbsp;&nbsp;<span [innerHTML]="item.text"></span
                    >&nbsp;&nbsp;&nbsp;<i class="fas fa-quote-right"></i>
                </p>
            </div>
            <div class="col-md"></div>
        </div>
    </div>

    <div class="navigation-manual">
        <label
            *ngFor="let item of testimonials"
            [for]="'radio' + item.id"
            (click)="carouselChange(item.id)"
            class="manual-btn"
            [ngClass]="{ active: carouselCheckActive(item.id) }"
        ></label>
    </div>
</div>
