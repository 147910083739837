<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-lg-6 d-flex flex-column justify-content-center">
            <p class="fw-bold h2 text-center text-md-left clr-blue-dark">{{ main?.intro?.name }}</p>
            <p class="pe-lg-4 fs-6">{{ main?.intro?.product_line }}</p>
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-center justify-content-center">
            <img [src]="main?.intro?.imgURL" [alt]="main?.intro?.name" class="w-90 animate-bob" loading="lazy" />
        </div>
    </div>
</section>

<!-- overview -->
<section class="px-md-5 my-5 px-2 overview">
    <div class="d-flex flex-column align-items-center p-md-5 p-2">
        <p [innerHTML]="main?.intro?.text" class="fw-semibold mb-4 clr-white mb-3"
            [ngClass]="{'h4 mb-4': main?.intro?.points.length>0, 'fs-6': main?.intro?.points.length == 0}"></p>
        <div>
            <p *ngFor="let item of main?.intro?.points" class="d-flex clr-white fs-6">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="20px"
                        height="20px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#fff"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                </span>
                <span class="ms-2">{{item}}</span>
            </p>
        </div>
    </div>
</section>

<!-- features -->
<section class="px-md-5 my-5 px-2">
    <div>
        <p class=" clr-blue-dark text-center fw-bolder mb-5 h2">Features</p>
        <div class="row mt-3 pb-2 m-lg-5">
            <div *ngFor="let item of main?.advantages" class="col-md d-flex align-items-stretch">
                <div class="card-1 text-left">
                    <img [src]="item.imgURL" [attr.height]="item.height" [attr.width]="item.width"
                        class="img-fluid mb-2 pl-4" [alt]="item?.name" loading="lazy" />
                    <p class="font-semibold text-center fs-4 fw-bold">{{ item.name }}</p>
                    <p *ngIf="item.text != ''" class="small" [innerHTML]="item.text"></p>
                    <div *ngIf="item.points">
                        <div class="d-flex" *ngFor="let point of item.points">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                                viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                <path fill="#006d8f"
                                    d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                            </svg>
                            <p class="ms-2 fs-6" [innerHTML]="point"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--functions-->
<section class="px-md-5 my-5 px-2">
    <div>
        <p class="clr-blue-dark text-center fw-bolder mb-5 h2">Functions</p>
        <div>
            <div *ngIf="main?.functions[0]">
                <div class="row">
                    <div class="col-4">
                        <div class="d-flex flex-column justify-content-around align-items-center">
                            <div *ngFor="let item of main.functions[0].images">
                                <img [src]="item.imgUrl" alt="" class="w-100">
                            </div>
                        </div>
                    </div>
                    <div class="col-8 d-flex flex-column justify-content-around">

                        <div *ngFor="let item of main.functions[0].content">
                            <div>
                                <p class="text-center fw-bold h2"><span><img src="" alt=""></span><span class="fw-bold"
                                        [innerHTML]="item.title"></span></p>
                                <p [innerHTML]="item.description"></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="main?.functions[1]">
                <div class="row">
                    <div class="col-8 d-flex flex-column justify-content-around">

                        <div *ngFor="let item of main.functions[1].content">
                            <div>
                                <p class="fw-bold h2 text-center"><span><img src="" alt=""></span><span class="fw-bold"
                                        [innerHTML]="item.title"></span></p>
                                <p [innerHTML]="item.description"></p>
                            </div>
                        </div>

                    </div>
                    <div class="col-4">
                        <div class="d-flex flex-column justify-content-around align-items-center">
                            <div *ngFor="let item of main.functions[1].images">
                                <img [src]="item.imgUrl" alt="" class="w-100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- advantages -->
<section class="px-md-5 my-5 px-2">
    <p class=" text-center fw-bold clr-blue-dark h2">Advantages</p>
    <div class="d-flex justify-content-center">
        <img id="advantage_img" [src]="main?.features_imgURL" alt="Advantage Image" loading="lazy" />
    </div>
</section>

<!-- product reference -->
<section class="px-md-5 my-5 px-2" *ngIf="main?.productReference.length > 0">
    <div class="py-1 product-reference">
        <p class="fw-bold clr-white m-0 mb-2 h2 p-3">Related Product</p>
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1">
        <div class="col-md-3 col-8 image-box d-flex justify-content-center align-items-center hover-popup"
            *ngFor="let item of main?.productReference">
            <a [routerLink]="item.link" class="d-flex justify-content-center w-90 h-90 p-3 image-anchor"><img
                    [src]="item?.imgURL" [alt]="item?.name" loading="lazy" class="img-fluid5" style="cursor: pointer"
                    style="max-height: 80px; object-fit: contain" [attr.width]="item.width" />
            </a>
        </div>
    </div>
</section>

<!-- how works -->
<section class="px-md-5 my-5 px-2">
    <div style="background-color: #ededed;">
        <div id="intro" class="container-fluid">
            <div class="d-flex flex-column justify-content-center align-items-center p-3">
                <p class="text-center text-md-left fw-bold h2">How Does It Work?</p>
                <p class="fs-6">See how the product eases out to solution.</p>
                <a class="btn btn-primary clr-white bg-clr-blue-dark fs-6 learn-more"
                    [routerLink]="main?.learnmore">Learn more</a>
            </div>
        </div>
    </div>
</section>