<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <p class="fw-bold pb-2 h2 text-center text-md-left">{{ intro.title }}</p>
            <img [src]="intro.logoUrl" class="w-70" [alt]="intro.title" loading="lazy" />
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center justify-content-center">
            <img [src]="intro.imgUrl" alt="Banner Image" class="img-fluid animate-bob" loading="lazy" />
        </div>
    </div>
</section>

<!-- overview -->
<section class="p-md-5 my-5 p-2 overview">
    <div class="row p-md-5 rounded" style="background-color: #f8f9fa">
        <div class="col-12 col-lg-6 d-flex flex-column">
            <p class="fw-bold clr-blue-dark h2">Overview</p>
            <p class=" text-justify fs-6">
                {{ overview.desc }}
            </p>
            <a class="btn btn-primary hover-popup fs-6 rounded-pill fw-bold"
                href="https://cdn.cosgrid.com/website/strapi/Re_Fle_X_SD_WAN_Product_Brief_a5bd7e785f.pdf"
                target="_blank" style="width: 50%">Download Whitepaper</a>
        </div>
        <div class="col-12 col-lg-6 mt-4 mt-lg-0">
            <carousel-list [data]="overview.products" baseUrl="https://cdn.cosgrid.com/website/assets/sdwan/Overview/"
                [singleCol]="true" [imageWidth]="90"></carousel-list>
        </div>
    </div>
</section>

<!-- challenges -->
<section class="px-md-5 my-5 px-2">
    <app-problem-list title="Challenges" desc="Cloud, IoT and WFH disrupting Enterprise WAN and also the Current Enterprises WAN architectures
        based on MPLS not suitable for the enhancing attacks and Connectivity problems"
        [data]="challenges"></app-problem-list>
</section>

<!-- key features -->
<section class="px-md-5 my-5 px-2">
    <app-features-list [title]="features.how_helps_text" subtitle="Key Features at a glance"
        [data]="features.how_helps"></app-features-list>
</section>

<!-- Relfex edge applianes -->
<section class="px-md-5 my-5 px-2">
    <div class="mt-4 reflexapplications py-1 d-flex flex-column align-items-center justify-content-center">
        <div class="py-3 px-4">
            <div class="row">
                <div class="col-12 w-75 d-flex flex-column justify-content-center align-items-center">
                    <p class="text-center text-md-left px-3 fs-6">
                        {{ applianes.helps_text }}
                    </p>
                </div>
            </div>
            <div class="row mt-2 d-flex justify-content-center align-items-center pt-2">
                <div class="col d-flex justify-content-center">
                    <img [src]="applianes.helps_img" class="img-fluid w-75" loading="lazy" alt="How it Helps Image" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- key differentiate -->
<section class="px-md-5 my-5 px-2">
    <app-features-list title="Advantages" subtitle="That sets us apart from others"
        [data]="key_differ"></app-features-list>
</section>

<!-- use cases -->
<section class="px-md-5 my-5 px-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col text-md-left">
                <p class="fw-bold text-center pt-4 clr-blue-dark h2">
                    {{ useCases.title }}
                </p>
                <p class="fw-bold text-center pt-2 fs-5">
                    {{ useCases.subtext }}
                </p>
            </div>
        </div>
        <div class="w-100 px-0 px-md-5">
            <table-list [data]="useCases.usecases" class="container"
                baseUrl="https://cdn.cosgrid.com/website/assets/sdwan/Use cases/"></table-list>
        </div>
    </div>
</section>

<!-- benifits -->
<section class="px-md-5 my-5 px-2">
    <image-points [image]="benefits.img" [title]="benefits.text" [subtitle]="benefits.subtext"
        [data]="benefits.benifits_points" leftContentXLCol="7"></image-points>
</section>

<!-- contact us -->
<section class="px-md-5 my-5 px-2">
    <div style="background-color: #ededed !important; margin-top: 3em">
        <div class="container-fluid d-flex flex-column align-items-center justify-content-center py-3">
            <p class="text-center text-md-left fw-bold h2">Interested in this product?</p>
            <div class="col-md-6 col-12 d-flex justify-content-center align-items-center flex-column">
                <p class="fs-6">Click the button below & submit your details - we will call you back!</p>
                <a class="btn btn-primary hover-popup fs-6" href="/company/contacts">Contact
                    Us</a>
            </div>
        </div>
        <!-- <div id="intro" class="container-fluid">
                <div class="row pt-2 pb-2 text-align-center" style="display: flex; justify-content: space-around">
                    <div class="col-md-6 col-12">
                    </div>
                    
                </div>
            </div> -->
    </div>
</section>