import { RouteConstants } from 'src/app/config/route.constants';

export const USECASES = [
    {
        id: 'eap',
        homeLink: `${RouteConstants.USECASE}#eap`,
        link: `/${RouteConstants.USECASES}`,
        title: 'App Performance',
        name: 'App Performance',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-03.png',
        desc: [
            'Many Cloud / SaaS apps suffer from Limited bandwidth availability per application per user at the offices and Poor Network experience. COSGrid ReFlex-WAN works around these limitations and delivers a leap in performance even in remote locations. COSGrid RefleX-Edge that enables local break-out of traffic headed to Internet/Cloud.',
        ],
        border:'',
    },
    {
        id: 'ssit',
        homeLink: `${RouteConstants.USECASE}#ssit`,
        link: `/${RouteConstants.USECASES}`,
        title: 'Secure Branch Internet Access',
        name: 'Secure Branch Internet Access',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-02.png',
        desc: [
            "COSGrid provides a secure SD-WAN and SASE by connecting all branch locations to COSGrid RefleX. There is no need to backhaul Internet traffic to a data center or procure stand-alone cloud security solutions. All Internet-bound traffic and WAN are entirely protected by COS Grid's cloud-based security.",
        ],
        border:''
    },
    {
        id: 'wa',
        homeLink: `${RouteConstants.USECASE}#wa`,
        link: `/${RouteConstants.USECASES}`,
        title: 'SaaS Acceleration',
        name: 'SaaS Acceleration',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-04.png',
        desc: [
            'Virtual Desktop Apps such as Citrix and RDP are sensitive to delay (latency) and packet loss. They are even more sensitive during (client sessions) link fluctuations. COSGrid ReFlex-WAN delivers a leap in user experience without impacting user sessions and accelerating performance.',
        ],
        border:''
    },
    {
        id: 'cm',
        homeLink: `${RouteConstants.USECASE}#cm`,
        link: `/${RouteConstants.USECASES}`,
        title: 'Centralised Management',
        name: 'Centralised Management',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/centralised-management.png',
        desc: [
            'Miscommunications and costly, ineffective sackings can be avoided with centralised management. With one security solution, it is easier for a single admin or a small team to keep track of alerts, events, and the progress of mitigation efforts, requiring the least management effort.',
        ],
        border:''
    },
    {
        id: 'sra',
        homeLink: `${RouteConstants.USECASE}#sra`,
        link: `/${RouteConstants.USECASES}`,
        title: 'Secure Remote Access',
        name: 'Secure Remote Access',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-06.png',
        desc: [
            "COSGrid provides a secure SD-WAN and SASE solution by connecting all branch locations to COSGrid RefleX COSGrid CloudNF Hub. All Internet-bound traffic and WAN are entirely protected by COSGrid's cloud-based security. (no need to backhaul Internet traffic to a data center)"
        ],
        border:''
    },
    {
        id: 'vavob',
        homeLink: `${RouteConstants.USECASE}#vavob`,
        link: `/${RouteConstants.USECASES}`,
        title: 'Zero-glitch Voice and Video',
        name: 'Zero-glitch Voice and Video',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-05.png',
        desc: [
            'COSGrid ReFlex-WAN delivers a leap in user experience without dropped VoIP calls or poor video session quality, even in remote locations. The right QoS policies for business-critical traffic aggregation improve the user experience and manageability.',
        ],
        border:''
    },
    {
        id: 'mmsw',
        homeLink: `${RouteConstants.USECASE}#mmsw`,
        link: `/${RouteConstants.USECASES}`,
        title: 'MPLS migration to SD-WAN',
        name: ' migratiMPLSon to SD-WAN',
        imgURL: 'https://cdn.cosgrid.com/website/assets/images/solutions/solution-usecase-01.png',
        desc: [
            "Organisation can shift from MPLS networks to a combination of Internet lines, whether broadband or 4G/5G links, with the help of COSGrid. Enterprises may increase usable capacity and strengthen resilience using COSGrid appliances while paying less per megabit."
        ],
        border:''
    },
];
//USECASES,SUSECASES,USECASE,SUSECASE,AppPerformance,SecureBranchInternetAccess,SaaSAcceleration,CentralisedManagement,SecureRemoteAccess,ZeroglitchVoiceandVideo,MPLSmigrationtoSDWAN,