<div class="text-center h-100 w-20 position-fixed z-1 align-items-center top-0 start-0 overflow-y-auto d-none d-md-block"
    style="background-color: white;">
    <div style="height: 110px"></div>
    <div class="list-group text-strong">
        <p class="clr-blue-dark fw-bold fs-5">ZTNA - Overview</p>
        <a *ngFor="let item of section1to2" fragment="{{ item.id }}" routerLink="/resources/ztna"
            class="list-group-item fs-6 text-left list-group-item-action" aria-current="true">{{ item.nav }}</a>
        <p class="clr-blue-dark fw-bold fs-5">Framework & Architechture</p>
        <a *ngFor="let item of section3to6" href="/resources/ztna#{{ item.id }}"
            class="list-group-item fs-6 text-left list-group-item-action" aria-current="true">{{ item.nav }}</a>
        <p class="clr-blue-dark fw-bold fs-5">ZTNA - Working & Use cases</p>
        <a *ngFor="let item of section7to8" href="/resources/ztna#{{ item.id }}"
            class="list-group-item fs-6 text-left list-group-item-action" aria-current="true">{{ item.nav }}</a>
        <p class="clr-blue-dark fw-bold fs-5">VPN vs ZTNA</p>
        <a *ngFor="let item of section9to11" href="/resources/ztna#{{ item.id }}"
            class="list-group-item fs-6 text-left list-group-item-action" aria-current="true">{{ item.nav }}</a>
        <p class="clr-blue-dark fw-bold fs-5">Benefits of ZTNA</p>
        <a href="/resources/ztna#{{ section12.id }}" class="list-group-item fs-6 text-left list-group-item-action"
            aria-current="true">{{ section12.nav }}</a>
        <p class="clr-blue-dark fw-bold fs-5">ZTNA -Way forward</p>
        <a *ngFor="let item of section13to14" href="/resources/ztna#{{ item.id }}"
            class="list-group-item fs-6 text-left list-group-item-action" aria-current="true">{{ item.nav }}</a>
    </div>
</div>

<div class="main mb-4 py-1">
    <p class="fw-bold clr-blue-dark h2 text-center mt-3">Zero Trust Network Access - ZTNA</p>
    <div class="row mx-1">
        <section *ngFor="let item of section1to2">
            <div class="xl pt-2" id="{{ item.id }}">
                <div class="text-normal">
                    <p class="fw-bold text-center text-md-left clr-blue-dark mb-3 h5">
                        {{ item.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of item.text" class="list-unstyled lh-lg">
                                <li class="fs-6" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="fs-6" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section *ngFor="let item of section3to6">
            <div class="xl pt-2" id="{{ item.id }}">
                <div class="text-normal">
                    <p class="fw-bold h5 text-center text-md-left clr-blue-dark mb-3">
                        {{ item.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of item.text" class="list-unstyled lh-lg">
                                <li class="fs-6" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="fs-6" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section *ngFor="let item of section7to8">
            <div class="xl pt-2" id="{{ item.id }}">
                <div class="text-normal">
                    <p class="fw-bold h5 text-center text-md-left clr-blue-dark mb-3">
                        {{ item.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of item.text" class="list-unstyled lh-lg">
                                <li class="fs-6" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="fs-6" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section *ngFor="let item of section9to11">
            <div class="xl pt-2" id="{{ item.id }}">
                <div class="text-normal">
                    <p class="fw-bold h5 text-center text-md-left clr-blue-dark mb-3">
                        {{ item.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of item.text" class="list-unstyled lh-lg">
                                <li class="fs-6" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="fs-6" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="xl pt-2" id="{{ section12.id }}">
                <div class="text-normal">
                    <p class="fw-bold h5 text-center text-md-left clr-blue-dark mb-3">
                        {{ section12.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of section12.text" class="list-unstyled lh-lg">
                                <li class="text-normal" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="text-normal" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section *ngFor="let item of section13to14">
            <div class="xl pt-2" id="{{ item.id }}">
                <div class="text-normal">
                    <p class="fw-bold h5 text-center text-md-left clr-blue-dark mb-3">
                        {{ item.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let text of item.text" class="list-unstyled lh-lg">
                                <li class="text-normal" [innerHTML]="text.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of text.points" class="text-normal" [innerHtml]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <table *ngIf="item.table">
                                <tr *ngFor="let table of item.table">
                                    <td class="tableBorder p-1" [textContent]="table.col1"></td>
                                    <td *ngIf="table.col2" class="tableBorder p-1" [textContent]="table.col2"></td>
                                    <td *ngIf="table.colList" class="p-1 tableBorder">
                                        <ul *ngFor="let list of table.colList" class="mt-3 text-left">
                                            <li class="text-normal" [innerHTML]="list"></li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>