import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'carousel-list',
    templateUrl: 'carousel-list.html',
    styleUrls: ['./carousel-list.scss'],
})
export class CarouselListComponent implements OnInit {
    screenwidth = 0;
    ngOnInit(): void {
        for (let i = 0; i < this.data.length; i += 2) {
            this.datahalflength.push(i);
        }
        
        if (isPlatformBrowser(this.platformId)) {
            this.screenwidth = window.innerWidth;
        }
    }
    
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (isPlatformBrowser(this.platformId)) {
            this.screenwidth = window.innerWidth;
        }
    }

    datahalflength = [];
    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {};
    @Input() whiteColor: boolean = false;
    @Input() data: any;
    @Input() baseUrl: string;
    @Input() descOnly: boolean = false;
    @Input() imageWidth: number = 130;
    @Input() singleCol: boolean = false;
    @Input() imageType: string = '';
    @Input() inputs: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1100,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 2500,
        },
        loop: true,
        touch: true,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
}
