<div class="row cosgridfeatures d-flex justify-content-center w-100" *ngIf="!halfWidth">
    <div *ngFor="let item of data.data;index as i" class="col-12 col-md-3 my-2" [ngClass]="{
'col-lg-3': xlSingleLineGrids=='4',
'col-lg-4': xlSingleLineGrids=='3',
'col-lg-6': xlSingleLineGrids=='2'
    }">
        <!-- <div class="d-flex"> -->
            <div class="px-1 py-3 rounded-2 shadowcardgrid d-flex flex-column" [ngStyle]="{
                    'background-color': shadowColor
                }" [ngClass]="{
                    'shadow':showShadow
            }">
                <div [ngClass]="gridClass">
                    <div *ngIf="imagePosition=='2'" class="d-flex flex-row justify-content-around">
                        <img *ngIf="item.image"
                            [src]="item.image.includes('https://') ? item.image : data.imageRoute + item.image + '.png'"
                            class="w-50" style="object-fit: fill" loading="lazy"
                            [alt]="item.title" />
                        <div class="w-100 mx-3">
                            <p class="fs-5 fw-bold" [ngClass]="{
               'text-center': titleCenter,
               'text-start':!titleCenter
            }" [innerHTML]="item.title??''"></p>
                            <div style="height: 3px"></div>
                            <span [ngClass]="
                            {
                                'text-center':descCenter
                            }" *ngIf="item.desc" class="fs-6 mt-2" [innerHTML]="item.desc"></span>
                        </div>
                    </div>
                    <div [ngClass]="{
                            'd-flex flex-column justify-content-center align-items-center':innerWidth>1000 && innerWidth<1300 && item.title=='Micro-Segmented Networks across Multi-Cloud'
                        }" style="height: -webkit-fill-available">
                        <div *ngIf="imagePosition=='1'" class="d-flex flex-column" [ngClass]="
                {
                    'align-items-center justify-content-center':alignCenter
                }">
                            <img *ngIf="item.image"
                                [src]="item.image.includes('https://') ? item.image : data.imageRoute + item.image + '.png'"
                                height="75px" width="75px" class="px-1" style="object-fit: contain; align-self: center;" loading="lazy" />
                            <div style="height: 6px"></div>
                            <p class="fs-5 px-1 fw-bold" [ngClass]="{
               'text-center': titleCenter,
               'text-start':!titleCenter
            }" [innerHTML]="item.title??''"></p>
                            <div style="height: 5px"></div>
                            <span [ngClass]="
                        {
                            'text-center':descCenter
                        }" *ngIf="item.desc" class="fs-6 px-3" [innerHTML]="item.desc"></span>
                            <div class="d-xl-block ps-2" *ngIf="item.points && item.points.length">
                                <div *ngFor="let point of item.points" class="fs-6 d-flex w-100 justify-content-start">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                                            viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                            <path fill="#006d8f"
                                                d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                        </svg>
                                    <p [innerHTML]="point" class="ms-1 fs-6"></p>
                                </div>
                            </div>
                        </div>
                        <div class="d-none d-xl-none pl-3" *ngIf="item.points && item.points.length">
                            <p *ngFor="let point of item.points" class="fs-6">
                                <span>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"
                                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <path fill="#006d8f"
                                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                    </svg>
                                </span>
                                <span [innerHTML]="point" class="ms-1 fs-6"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </div>
</div>




<div class="row cosgridfeatures d-flex justify-content-center w-100" *ngIf="halfWidth">
    <div *ngFor="let item of data.data;index as i" class="col-12 col-lg-6 my-2">
        <div>
            <div class="px-1 py-3 rounded-2 shadowcardgrid d-flex flex-column justify-content-center" [ngStyle]="{
                    'background-color': shadowColor
                }" [ngClass]="{
                    'shadow':showShadow
            }">
                <div [ngClass]="gridClass">
                    <div *ngIf="imagePosition=='2'" class="d-flex flex-row justify-content-around">
                        <img *ngIf="item.image"
                            [src]="item.image.includes('https://') ? item.image : data.imageRoute + item.image + '.png'"
                            class="w-50" style="object-fit: fill" loading="lazy"
                            [alt]="item.title" />
                        <div class="w-100 mx-3">
                            <p class="fs-5 fw-bold" [ngClass]="{
                                    'text-center': titleCenter,
                                    'text-start':!titleCenter,
                                    'clr-white': whiteColor
                                }" [innerHTML]="item.title??''"></p>
                            <div style="height: 3px"></div>
                            <span [ngClass]=" {'text-center':descCenter, 'clr-white': whiteColor}" *ngIf="item.desc"
                                class="fs-6" [innerHTML]="item.desc"></span>
                        </div>
                    </div>
                    <div [ngClass]="{
                            'd-flex flex-column justify-content-center align-items-center':innerWidth>1000 && innerWidth<1300 && item.title=='Micro-Segmented Networks across Multi-Cloud'
                        }" style="height: -webkit-fill-available">
                        <div *ngIf="imagePosition=='1'" class="d-flex flex-column" [ngClass]="
                {
                    'align-items-center justify-content-center':alignCenter,
                'align-items-start justify-content-start':!alignCenter
                }">
                            <img *ngIf="item.image"
                                [src]="item.image.includes('https://') ? item.image : data.imageRoute + item.image + '.png'"
                                height="75px" width="75px" class="px-1" style="object-fit: contain" loading="lazy" />
                            <div style="height: 6px"></div>
                            <p class="fs-5 px-1 fw-bold" [ngClass]="{
               'text-center': titleCenter,
               'text-start':!titleCenter,
               'clr-white': whiteColor
            }" [innerHTML]="item.title??''"></p>
                            <div style="height: 5px"></div>
                            <span [ngClass]="
                        {
                            'text-center':descCenter,
                            'clr-white': whiteColor
                        }" *ngIf="item.desc" class="fs-6 px-1" [innerHTML]="item.desc"></span>
                            <div class="d-xl-block mt-3" *ngIf="item.points && item.points.length">
                                <div *ngFor="let point of item.points" class="fs-6 d-flex" style="margin-bottom: 5px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="18px" class="mt-1"
                                        height="18px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                        <path fill="#006d8f"
                                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                                    </svg>
                                    <p style="margin-left: 2px;" [innerHTML]="point"
                                        [ngClass]="{'clr-white': whiteColor}" class="fs-6"></p>
                                </div>

                            </div>
                        </div>
                        <div class="d-none d-xl-none pl-3">
                            <ul *ngIf="item.points && item.points.length">
                                <li style="margin-top: 10px" *ngFor="let point of item.points" class="fs-6"
                                    [ngClass]="{'clr-white': whiteColor}" [innerHTML]="point"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>