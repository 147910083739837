import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';

export interface IAssocicationItem {
    title?: string;
    className?: string;
    imgURL: string;
}
export interface IAwardItem {
    title?: string;
    className?: string;
    imgURL: string;
    width: string;
}
export interface IndustryPartnerItem {
    title?: string;
    class?: string;
    imgURL: string;
}
@Component({
    selector: 'app-association',
    templateUrl: './association.component.html',
    styleUrls: ['./association.component.scss'],
})
export class AssociationComponent {
    @Input() title = '';
    @Input() data: any = [];
    @Input() headDirection: 'left' | 'right' = 'right'
    public innerWidth: any;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (isPlatformBrowser(this.platformId))
            this.innerWidth = window.innerWidth;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: object
    ) { }

}
